import React, { useContext, useMemo } from 'react';
import { Card, CardContent, Grid, IconButton } from '@material-ui/core';
import { aggregateEvents } from '@onward-delivery/core';
import zipcode_to_timezone from 'zipcode-to-timezone';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { toNational } from '@/utilities/formatPhoneNumber';
import { addHours } from 'date-fns';
import { colors } from '@/styles';
import { EDIT_ORDER_TABS } from '../constants';
import { GreySubheader, Body, GreyBody, GrayBgCard, PrimaryButton } from '../../../styles/blocks';
import { PriceText } from '@/components/ShipmentForm/blocks';
import { DROPOFF_TYPES } from '@/components/ShipmentForm/constants/dropoffOptions';
import dateFns from '@/utilities/dateFns';
import { asDateInTZ } from '@/utilities/convertToISO';
import { useSwappedAttributes } from '../hooks';
import { useOrderShipperPricing, useClientUser } from '@/hooks';
import BidIcon from '@/components/misc/BidIcon';
import { BIDDING_DISABLED } from '@/constants/featureFlags';
import EditIcon from '@material-ui/icons/Edit';
import onwardLogo from '@/assets/onwardlogotransparent.webp';
import { startCase } from 'lodash';
import { Option, Label, ErrorContainer } from './blocks';

const dollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});

const dateNumeric = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
});

const timeFormat = new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short',
});

const GreyBox = styled.div`
    display: inline-block;
    background: #e4e4e4;
    border-radius: 4px;
    padding: 0.5rem;
    width: ${(props) => (props.fullWidth ? '100%' : '')};
    cursor: ${(props) => (props.pointer ? 'pointer' : '')};
`;

const Mask = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    z-index: 999;
`;

const SubCardTitle = styled((props) => <Body {...props} />)`
    font-weight: 600;
`;

const Icon = styled.div`
    width: 24px;
    height: 24px;
    margin-right: 16px;

    & svg {
        vertical-align: super;
    }
`;

function OrderCard({ accordion = false, order, overridesById, orderNumber, listing, validation, callbacks }) {
    const { location_type, location, zip } = useSwappedAttributes(order);
    const isSaved = !!order.order_id;
    const isLoading = order.order_status === 'processing';
    const override_attr = order.oms ? 'onward_rate_override_id' : 'shipper_rate_override_id';
    const { circles, payment_type, shipping_partners, user_id, service_levels } = useClientUser();

    const sorted = useMemo(() => {
        const clone = [...(order?.pricing_options || [])];
        return clone.sort((l, r) => {
            const lc =
                overridesById[l?._meta?.override_id]?.partner_client ||
                overridesById[l?._meta?.override_id]?.assign ||
                {};
            const rc =
                overridesById[r?._meta?.override_id]?.partner_client ||
                overridesById[r?._meta?.override_id]?.assign ||
                {};

            return (rc?.business_name || 'Onward').localeCompare(lc?.business_name || 'Onward');
        });
    }, [order.pricing_options]);

    const isInternal = !!order.oms;

    let icon;
    if (isSaved) {
        switch (order.order_status) {
            case 'processing':
                icon = null;
                break;
            case 'pending_confirmation':
                icon = (
                    <Icon
                        css={css`
                            color: ${colors.greens.primary};
                        `}
                    >
                        <CheckCircleIcon />
                    </Icon>
                );
                break;
            case 'incomplete':
                icon = (
                    <Icon
                        css={css`
                            color: #d23e3e;
                        `}
                    >
                        <ErrorIcon />
                    </Icon>
                );
                break;

            default:
                if (validation.hasError()) {
                    icon = (
                        <Icon
                            css={css`
                                color: #d23e3e;
                            `}
                        >
                            <ErrorIcon />
                        </Icon>
                    );
                } else {
                    icon = (
                        <Icon
                            css={css`
                                color: ${colors.greens.primary};
                            `}
                        >
                            <CheckCircleIcon />
                        </Icon>
                    );
                }
                break;
        }
    }

    const items = useMemo(() => {
        return order?.itemsByOrderId?.filter((item) => !item.is_return);
    }, [order]);
    const returns = useMemo(() => {
        return order?.itemsByOrderId?.filter((item) => item.is_return);
    }, [order]);
    const orderTZ = useMemo(() => {
        return (order[zip] && zipcode_to_timezone.lookup(order[zip])) || 'America/New_York';
    }, [order, zip]);

    const shipper = useMemo(() => {
        return shipping_partners.find((sp) => sp.shipper_id === order.shipper_id);
    }, [order, shipping_partners]);

    const serviceLevel = useMemo(() => {
        return service_levels.find((s) => s.service_level_id === order.service_level_id);
    }, [order, service_levels]);

    const getLabel = (type, value, pickupOrDropoff) => {
        switch (type) {
            case 'Residence':
                return (
                    DROPOFF_TYPES[type].find((dropoff) => dropoff.value === value)?.label(pickupOrDropoff) ||
                    value ||
                    ''
                );
            case 'Business':
                let label =
                    DROPOFF_TYPES[type].find((dropoff) => dropoff.value === value || dropoff.label === value)?.label ||
                    value ||
                    '';
                return typeof label === 'function' ? label(pickupOrDropoff) : label;
            default:
                return value;
        }
    };

    const orderLabel = useMemo(() => {
        switch (order.job_type) {
            case 'WILL_CALL':
                return 'Will Call';
            case 'PICKUP_AND_WILL_CALL':
                return 'Return Pickup & Will Call';
            case 'INVENTORY':
                return 'Inventory';
            case 'SERVICE':
                return 'Service';
            case 'SHIPMENT':
            default:
                return startCase(order.order_type || 'delivery');
        }
    }, [order]);

    const content = (
        <>
            <Grid
                container
                css={css`
                    margin-bottom: 1rem;
                `}
            >
                <Grid
                    container
                    item
                    xs={6}
                    css={css`
                        align-items: center;
                    `}
                >
                    <GreySubheader
                        css={css`
                            margin: 0;
                        `}
                    >
                        {`Order ${order.order_number || orderNumber || ''} - ${orderLabel}`}
                    </GreySubheader>
                </Grid>
                <Grid
                    container
                    item
                    xs={6}
                    css={css`
                        align-items: center;
                        justify-content: flex-end;
                    `}
                >
                    {icon}

                    <IconButton
                        css={css`
                            padding: 4px;
                        `}
                    >
                        <CloseIcon
                            onClick={() => {
                                callbacks.deleteOrder(order);
                            }}
                            css={css`
                                color: black;
                            `}
                        />
                    </IconButton>
                </Grid>
            </Grid>

            <Grid
                container
                spacing={2}
                css={css`
                    margin-bottom: 1rem;
                `}
            >
                <Grid item xs={3}>
                    <Grid item xs={12}>
                        <SubCardTitle>Customer</SubCardTitle>
                    </Grid>
                    <GrayBgCard
                        pointer="true"
                        onClick={() => !callbacks.openEditModal(order, EDIT_ORDER_TABS.CUSTOMER)}
                        validationError={validation.hasErrorTab(EDIT_ORDER_TABS.CUSTOMER)}
                    >
                        {order.dropoff_name && <Body>{order.dropoff_name}</Body>}
                        {order.pickup_street_address && order.dropoff_street && (
                            <Body>
                                {order.order_type === 'return' ? order.pickup_street_address : order.dropoff_street}
                            </Body>
                        )}
                        {order.dropoff_phone ? <Body>{toNational(order.dropoff_phone)}</Body> : null}
                    </GrayBgCard>
                </Grid>
                <Grid item xs={3}>
                    <Grid item xs={12}>
                        <SubCardTitle>Delivery Type</SubCardTitle>
                    </Grid>
                    <GrayBgCard
                        fullWidth
                        pointer="true"
                        onClick={() => callbacks.openEditModal(order, EDIT_ORDER_TABS.CUSTOMER)}
                        validationError={validation.hasErrorTab(EDIT_ORDER_TABS.CUSTOMER)}
                    >
                        <Body>Location Type: {startCase(order[location]) || 'TBD'}</Body>
                        <Body>
                            Service Type:{' '}
                            {serviceLevel?.service_level
                                ? serviceLevel.service_level
                                : getLabel(
                                      order[location],
                                      order[location_type],
                                      order.order_type === 'return' ? 'pickUp' : 'dropOff'
                                  ) || 'TBD'}
                        </Body>
                    </GrayBgCard>
                </Grid>
                <Grid item xs={3}>
                    <Grid item xs={12}>
                        <SubCardTitle>Items</SubCardTitle>
                    </Grid>
                    <GrayBgCard
                        fullWidth
                        pointer="true"
                        onClick={() => callbacks.openEditModal(order, EDIT_ORDER_TABS.ITEMS)}
                        validationError={validation.hasErrorTab(EDIT_ORDER_TABS.ITEMS)}
                    >
                        {order.itemsByOrderId.length > 0 ? (
                            <>
                                <Body>
                                    {!!items.length && `${items.reduce((acc, item) => acc + item.quantity, 0)} item(s)`}
                                </Body>
                                {!!items.length && !!returns.length && <br />}
                                <Body>
                                    {!!returns.length &&
                                        `${returns.reduce((acc, item) => acc + item.quantity, 0)} return(s)`}
                                </Body>
                            </>
                        ) : (
                            <Body>None</Body>
                        )}
                    </GrayBgCard>
                </Grid>
                <Grid item xs={3}>
                    <Grid item xs={12}>
                        <SubCardTitle>Add haul-away</SubCardTitle>
                    </Grid>
                    <GrayBgCard
                        pointer
                        onClick={() => callbacks.openEditModal(order, EDIT_ORDER_TABS.HAUL_AWAY)}
                        css={css`
                            justify-content: center;
                        `}
                    >
                        <Body>
                            {order?.haulaway_items?.length ? order?.haulaway_items?.length : 'None'}
                            {order?.haulaway_items?.length > 0 ? ` Item(s)` : ''}
                        </Body>
                        <GreyBody>(Optional)</GreyBody>
                    </GrayBgCard>
                </Grid>
            </Grid>
            <Grid
                container
                spacing={2}
                css={css`
                    margin-bottom: 24px;
                `}
            >
                <Grid
                    container
                    item
                    xs={3}
                    css={css`
                        align-items: center;
                    `}
                >
                    <SubCardTitle
                        css={css`
                            margin: 0;
                            margin-right: 12px;
                        `}
                    >
                        Delivery Date{' '}
                    </SubCardTitle>
                    <GreyBox>
                        <GreyBody>
                            {dateFns.formatDate(asDateInTZ(order.delivery_date, orderTZ), 'yyyy-MM-dd') || 'TBD'}
                        </GreyBody>
                    </GreyBox>
                </Grid>
                <Grid
                    container
                    item
                    xs={3}
                    css={css`
                        align-items: center;
                    `}
                >
                    <SubCardTitle
                        css={css`
                            margin: 0;
                            margin-right: 12px;
                        `}
                    >
                        PO #
                    </SubCardTitle>
                    <GrayBgCard
                        pointer="true"
                        onClick={() => callbacks.openEditModal(order, EDIT_ORDER_TABS.ORDER_DETAILS)}
                        validationError={validation.hasErrorTab(EDIT_ORDER_TABS.ORDER_DETAILS)}
                        css={css`
                            padding: 8px;
                            height: auto;
                        `}
                    >
                        <GreyBody
                            css={css`
                                margin: 0;
                            `}
                        >
                            {order.po_number ? order.po_number : 'TBD'}
                        </GreyBody>
                    </GrayBgCard>
                </Grid>
                <Grid item conatiner xs={3} />
                {order.oms ? (
                    <Grid
                        container
                        item
                        xs={3}
                        css={css`
                            align-items: center;
                        `}
                    >
                        <SubCardTitle
                            css={css`
                                margin: 0;
                                margin-right: 12px;
                            `}
                        >
                            Revenue
                        </SubCardTitle>
                        <GrayBgCard
                            pointer="true"
                            onClick={() => callbacks.openEditModal(order, EDIT_ORDER_TABS.ORDER_DETAILS)}
                            validationError={validation.hasErrorTab(EDIT_ORDER_TABS.ORDER_DETAILS)}
                            css={css`
                                padding: 8px;
                                height: auto;
                            `}
                        >
                            <GreyBody
                                css={css`
                                    margin: 0;
                                `}
                            >
                                {order.order_revenue ? `$${order.order_revenue.toFixed(2)}` : '-'}
                            </GreyBody>
                        </GrayBgCard>
                    </Grid>
                ) : null}
            </Grid>
            {isSaved && !order.oms && order?.pricing_options?.length > 0 ? (
                <Grid container direction="row">
                    <Grid
                        container
                        direction="column"
                        css={css`
                            width: 100%;
                        `}
                    >
                        {!order[override_attr] ? (
                            <Grid
                                container
                                direction="row"
                                css={css`
                                    margin-bottom: 8px;
                                `}
                            >
                                <Label
                                    css={css`
                                        color: #d23e3e;
                                    `}
                                >
                                    Must select carrier
                                </Label>
                            </Grid>
                        ) : null}

                        <Grid container direction="row">
                            <ErrorContainer error={!order[override_attr]}>
                                {sorted.map((breakdown) => {
                                    const client =
                                        overridesById[breakdown?._meta?.override_id]?.partner_client ||
                                        overridesById[breakdown?._meta?.override_id]?.assign ||
                                        {};

                                    let label = client?.business_name || 'Onward';
                                    let subtext = [
                                        ...(order.delivery_date
                                            ? [
                                                  `Delivery ${dateNumeric.format(
                                                      asDateInTZ(order.delivery_date, orderTZ)
                                                  )}`,
                                              ]
                                            : ['Delivery in 3-5 days']),
                                        ...(order.pickup_window_end
                                            ? [
                                                  `(${[1, 4]
                                                      .map((hours) =>
                                                          timeFormat.format(
                                                              addHours(new Date(order.pickup_window_end), hours)
                                                          )
                                                      )
                                                      .join(' - ')})`,
                                              ]
                                            : []),
                                    ].join(' ');
                                    switch (breakdown?._meta.type) {
                                        case 'BUNGII': {
                                            if (order.pickup_window_end) {
                                                subtext = `Standard (~${timeFormat.format(
                                                    addHours(new Date(order.pickup_window_end), 2)
                                                )})`;
                                            } else {
                                                subtext = 'Standard (2 hours)';
                                            }
                                            break;
                                        }
                                        case 'FRAYT': {
                                            switch (breakdown?._meta?.contract) {
                                                case 'onward_delivery_same_day': {
                                                    if (order.pickup_window_end) {
                                                        const range = [4, 8].map((hours) =>
                                                            timeFormat.format(
                                                                addHours(new Date(order.pickup_window_end), hours)
                                                            )
                                                        );
                                                        subtext = `Same Day (${range.join(' - ')})`;
                                                    } else {
                                                        subtext = 'Same Day (4-8 hours)';
                                                    }

                                                    break;
                                                }
                                                case 'onward_delivery_rush': {
                                                    if (order.pickup_window_end) {
                                                        subtext = `Rush (~${timeFormat.format(
                                                            addHours(new Date(order.pickup_window_end), 2)
                                                        )})`;
                                                    } else {
                                                        subtext = 'Rush (2 hours)';
                                                    }

                                                    break;
                                                }
                                                case 'onward_delivery_flex': {
                                                    if (order.pickup_window_end) {
                                                        const range = [3, 5].map((hours) =>
                                                            timeFormat.format(
                                                                addHours(new Date(order.pickup_window_end), hours)
                                                            )
                                                        );
                                                        subtext = `Standard (${range.join(' - ')})`;
                                                    } else {
                                                        subtext = 'Standard (3-5 hours)';
                                                    }

                                                    break;
                                                }
                                            }

                                            break;
                                        }
                                    }

                                    const drivers =
                                        breakdown?._meta.type === 'FRAYT'
                                            ? Object.values(
                                                  (client?.carried_orders || []).reduce((acc, order) => {
                                                      const aggregated = aggregateEvents(order.wh_events);

                                                      if (aggregated.driver_email) {
                                                          const driver = aggregated.driver_email
                                                              ? {
                                                                    name: aggregated.driver_name,
                                                                    id: aggregated.driver_email,
                                                                }
                                                              : {};

                                                          acc[aggregated.driver_email] = driver;
                                                      }

                                                      return acc;
                                                  }, {})
                                              )
                                            : [];

                                    return (
                                        <Option
                                            order={order}
                                            label={label}
                                            breakdown={breakdown}
                                            subtext={subtext}
                                            logo={client?.profile_img || onwardLogo}
                                            isSelected={order[override_attr] === breakdown?._meta?.override_id}
                                            price={
                                                breakdown.total_charge ? dollar.format(breakdown.total_charge) : '--'
                                            }
                                            drivers={drivers}
                                            callbacks={{
                                                onClick: () => {
                                                    callbacks.submitOrder({
                                                        ...order,
                                                        [override_attr]: breakdown?._meta?.override_id,
                                                    });
                                                },
                                                onDriverSelect: (val) => {
                                                    callbacks.submitOrder({
                                                        ...order,
                                                        preferred_driver_id: val === 'DEFAULT' ? null : val,
                                                    });
                                                },
                                            }}
                                        />
                                    );
                                })}
                            </ErrorContainer>
                        </Grid>
                    </Grid>
                </Grid>
            ) : null}
        </>
    );

    return accordion ? (
        content
    ) : (
        <Card
            css={css`
                position: relative;
                margin-bottom: 1rem;
                opacity: ${isLoading ? 0.3 : 1};
            `}
        >
            {isLoading ? <Mask /> : null}
            <CardContent>{content}</CardContent>
        </Card>
    );
}

export default OrderCard;
