import React, { useState, useMemo } from 'react';
import { css } from '@emotion/react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useSubscription, useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { compose } from 'recompose';

import { ROUTE_SUBSCRIPTION, COMPLETE_ROUTE } from './graphql';
import { QUERY_BY_ROUTE } from '../../graphql/queries/ratings';
import withFeatureFlag from '../Auth/withFeatureFlag';
import { SAAS_V1 } from '../../constants/featureFlags';
import { useClientUser } from '../../hooks';
import * as colors from '../../styles/colors';

import { Grid, Box, CircularProgress, Tooltip, Button } from '@material-ui/core';
import { OnwardBreadcrumbActive, OnwardBreadcrumbInactive, OnwardBreadcrumbSpacer } from '../Breadcrumbs';
import { OnwardTabContainer, OnwardTab } from '../Tabs';

import _ from 'lodash';
import RouteMap from './RouteMap';
import OrderTable from './OrderTable';
import RouteInfo from './RouteInfo';
import RouteStops from './RouteStops';
import { H1, H3, PrimaryButton, fragments } from './blocks';
import StartRouteModal from './StartRouteModal';
import Snackbar from '../Snackbar';

import CarrierReviewModal from '../CarrierReviewModal';
import { asDateInTZ } from '@/utilities/convertToISO';
import zipcode_to_timezone from 'zipcode-to-timezone';
import dateFnsFormat from 'date-fns/format';
import { formatInTimeZone } from 'date-fns-tz';
import ExceptionModal from '../OrderDetailsPage/modals/Exceptions';
import ExceptionResolutionModal from '@/components/admin/AdminExceptions/modals/ExceptionResolutionModal';
import { GET_JOB_INFO, RESOLVE_EXCEPTION } from '@/components/admin/AdminExceptions/graphql';
import { ORDER_BY_ID } from '@/components/OrderDetailsPage/queries';

import { EXCEPTION_TYPES } from '@onward-delivery/core';
import { INSERT_EXCEPTION, INSERT_EXCEPTION_EVENT, UPDATE_EXCEPTION } from '../OrderDetailsPage/graphql/mutations';
import { captureException } from '@sentry/react';
import { useExceptionCallbacks } from '../OrderDetailsPage/hooks';
import useAction from '@/utilities/useQuery';
import { createOrderJob } from '@/components/ShipmentForm/queries/createOrderJob';
import { recalculateRoute } from '@/utilities/recalculateRoute';
import { isOrderCancelled } from './isOrderCancelled';
import { UPDATE_ORDER_BY_ID } from '@/graphql/mutations/orders';

function RouteDetails() {
    const [tabIndex, setTabIndex] = useState(0);
    const [startRouteModal, setStartRouteModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [editingException, editException] = useState(null);
    const [resolveExceptionParams, setResolveExceptionParams] = useState({});
    const [order_id, setOrderId] = useState(null);

    const { routeUid } = useParams();
    const navigate = useNavigate();

    const {
        user,
        user_id,
        username,
        default_end_location,
        preferences_start_estimate,
        preferences_end_estimate,
        preferences_warehouse_return_estimate,
        ...clientUser
    } = useClientUser();

    const location = useLocation();

    const source = location?.state?.source || null;

    const [carrierReviewOpen, setCarrierReviewOpen] = useState(false);

    const { loading: routeLoading, data: routeData } = useSubscription(ROUTE_SUBSCRIPTION, {
        variables: { route_id: routeUid },
    });

    const route = useMemo(() => {
        const route = routeData?.routes_by_pk;
        if (route) {
            const zip = route?.orders?.[0]?.order?.dropoff_zip;
            const tz = zipcode_to_timezone.lookup(zip) || Intl.DateTimeFormat().resolvedOptions().timeZone;
            return {
                ...route,
                scheduled_delivery_formatted: formatInTimeZone(
                    asDateInTZ(route.scheduled_delivery, tz),
                    tz,
                    'EEE, MMM d, yyyy'
                ),
            };
        }

        return {};
    }, [routeData]);

    const [insertExceptionEvent, { loading: insertExceptionEventLoading }] = useMutation(INSERT_EXCEPTION_EVENT, {
        refetchQueries: [ROUTE_SUBSCRIPTION],
        awaitRefetchQueries: true,
        onError: (err) => {
            captureException(err);
            console.error(err);
            setErrorMessage('Failed to report exception');
        },
    });

    const [insertException, { loading: insertExceptionLoading }] = useMutation(INSERT_EXCEPTION, {
        onError: (err) => {
            captureException(err);
            console.error(err);
            setErrorMessage('Failed to report exception');
        },
    });

    const [updateException, { loading: updateExceptionLoading }] = useMutation(UPDATE_EXCEPTION, {
        onError: (err) => {
            captureException(err);
            console.error(err);
            setErrorMessage('Failed to update exception');
        },
    });

    const { createException } = useExceptionCallbacks(
        { order: editingException?.order || {}, exceptionParams: editingException, route_id: routeUid, user_id },
        { insertExceptionEvent, insertException, updateException }
    );

    const [resolve, { loading: resolveInflight }] = useMutation(RESOLVE_EXCEPTION, {
        update(cache, { data: { order, exception, items_removed } }) {
            cache.updateQuery(
                {
                    query: ORDER_BY_ID,
                    variables: {
                        order_id: order_id,
                    },
                },
                (data) => {
                    if (!order) {
                        return data;
                    }
                    const [updated] = order.returning;
                    const clone = {
                        ...data.orders[0],
                        ...updated,
                    };

                    return {
                        ...data,
                        orders: [clone],
                    };
                }
            );
        },
        onError: (err) => {
            captureException(err);
        },
    });

    const [submitOrders, { loading: submitInflight }] = useAction(createOrderJob, {
        onError: (err) => {
            captureException(err);
        },
    });

    const [markRouteComplete, { loading: routeCompleteLoading }] = useMutation(COMPLETE_ROUTE);

    const [getJobInfo] = useLazyQuery(GET_JOB_INFO, {
        onError: (err) => {
            captureException(err);
        },
    });

    const [updateOrder] = useMutation(UPDATE_ORDER_BY_ID, {
        onError: (err) => {
            captureException(err);
        },
    });

    const saveAssociatedReturnOrder = async (order_id, job_id) => {
        await getJobInfo({
            variables: {
                job_id: job_id,
            },
            onCompleted: (jobData) => {
                updateOrder({
                    variables: {
                        order_id: order_id,
                        update: {
                            return_order_id: jobData.jobs_by_pk.orders[0].order_id,
                        },
                    },
                });
            },
        });
    };

    const ratings = useMemo(() => {
        return (route.orders || []).reduce((acc, mapping) => {
            const orderRatings = mapping.order.ratings || [];
            return [...acc, ...orderRatings];
        }, []);
    }, [route]);

    const handleTabChange = (event, newValue) => setTabIndex(newValue);

    const carrierReviewFlag = route?.carrier_id && user_id !== route?.carrier_id;

    const startRouteDisplay = useMemo(() => {
        if (!route?.shipper_id) return false;
        return (
            route.status === 'active' &&
            ((!route.carrier_id && route.shipper_id === user_id) ||
                (!!route.carrier_id && route.carrier_id === user_id))
        );
    }, [route]);

    const completeRouteDisplay = useMemo(() => {
        if (!route?.shipper_id) return false;
        return (
            route.status === 'inProgress' &&
            ((!route.carrier_id && route.shipper_id === user_id) ||
                (!!route.carrier_id && route.carrier_id === user_id))
        );
    }, [route]);

    const stopOrderMappings = useMemo(() => {
        return (route?.stopsByRouteId || []).map((stop) => {
            const associatedOrders = route.orders
                .filter((order) => (stop.orders || []).includes(order.order_id))
                .map((o) => o.order);
            return {
                stop,
                orders: associatedOrders,
            };
        });
    }, [route]);

    const exceptionStops = useMemo(() => {
        return stopOrderMappings.map(({ stop, orders }) => {
            const hasException = orders.some((order) => {
                return order.exceptions.some((exception) => exception.exception.route_id === route.route_id);
            });
            return {
                stop,
                hasException,
            };
        });
    }, [stopOrderMappings, route]);

    const cancelledStops = useMemo(() => {
        return stopOrderMappings.map(({ stop, orders }) => {
            const cancelled = orders.every((order) => isOrderCancelled(order));
            return {
                stop,
                cancelled,
            };
        });
    }, [stopOrderMappings, route]);

    return (
        <Grid
            container
            direction="column"
            css={css`
                padding: 0;
                height: 100%;
                flex-wrap: nowrap;
                align-items: flex-start;
            `}
        >
            <Grid
                container
                css={css`
                    background: ${colors.white.primary};
                    height: fit-content;
                    border-bottom: 1px solid #dee2e6;
                `}
            >
                <Grid
                    item
                    xs={12}
                    lg={3}
                    css={css`
                        padding-left: 8px;
                        display: flex;
                        align-items: center;
                    `}
                >
                    <OnwardBreadcrumbInactive onClick={() => navigate(-1)}>
                        {source || 'Active'}
                    </OnwardBreadcrumbInactive>
                    <OnwardBreadcrumbSpacer />
                    <OnwardBreadcrumbActive
                        css={css`
                            margin-left: 8px;
                        `}
                    >
                        {route.route_alias || `Route ${route.route_number}`}
                    </OnwardBreadcrumbActive>
                </Grid>
                <Grid item xs={9} lg={6}>
                    <OnwardTabContainer
                        value={tabIndex}
                        onChange={handleTabChange}
                        textColor="primary"
                        indicatorColor="primary"
                        centered
                    >
                        <OnwardTab label="Track" value={0} />
                        <OnwardTab label="Orders" value={2} />
                    </OnwardTabContainer>
                </Grid>
                <Grid
                    item
                    xs={3}
                    css={css`
                        text-align: end;
                        padding-right: 15px;
                        align-self: center;
                    `}
                >
                    {completeRouteDisplay && (
                        <Tooltip
                            title="Deprecated - Complete the route by completing each stop in the side panel."
                            placement="bottom"
                        >
                            <span>
                                <Button variant="contained" disabled>
                                    Mark route complete
                                </Button>
                            </span>
                        </Tooltip>
                    )}
                    {startRouteDisplay && (
                        <PrimaryButton onClick={() => setStartRouteModal(true)}>Start Route</PrimaryButton>
                    )}
                </Grid>
            </Grid>
            {routeLoading ? (
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    css={css`
                        height: 100%;
                    `}
                >
                    <CircularProgress size={200} />
                </Grid>
            ) : (
                <>
                    {tabIndex === 0 ? (
                        <Grid
                            container
                            css={css`
                                flex-grow: 1;
                                overflow-y: hidden;
                            `}
                        >
                            <ExceptionModal
                                {...(editingException || {})}
                                loading={
                                    insertExceptionEventLoading || insertExceptionLoading || updateExceptionLoading
                                }
                                types={[
                                    EXCEPTION_TYPES.DAMAGED,
                                    EXCEPTION_TYPES.REFUSAL,
                                    EXCEPTION_TYPES.ATTEMPTED_DELIVERY,
                                    EXCEPTION_TYPES.CUSTOMER_CANCELLATION,
                                    EXCEPTION_TYPES.NO_ROOM,
                                ]}
                                callbacks={{
                                    onError: (e) => {
                                        setErrorMessage('Error reporting exception');
                                    },
                                    onSubmitCreateAnother: ({ exception }) => {
                                        return createException(exception).then(() => {
                                            editException(null);
                                        });
                                    },
                                    onSubmit: ({ exception }) => {
                                        return createException(exception).then(() => {
                                            const incompleteStops = route?.stopsByRouteId?.filter(
                                                (s) =>
                                                    !s?.stop_completion_time &&
                                                    !exceptionStops.some(
                                                        ({ stop: { stop_id }, hasException }) =>
                                                            stop_id === s.stop_id && hasException
                                                    ) &&
                                                    !cancelledStops.some(
                                                        ({ stop: { stop_id }, cancelled }) =>
                                                            stop_id === s.stop_id && cancelled
                                                    )
                                            );
                                            if (
                                                incompleteStops.length === 1 &&
                                                incompleteStops[0].stop_id === editingException?.stop?.stop_id
                                            ) {
                                                markRouteComplete({
                                                    variables: {
                                                        routeUpdates: [
                                                            {
                                                                where: { route_id: { _eq: route.route_id } },
                                                                _set: {
                                                                    status: 'complete',
                                                                    completed: new Date().toISOString(),
                                                                    last_update_source: 'web',
                                                                },
                                                            },
                                                        ],
                                                    },
                                                    onError: (error) => {
                                                        setErrorMessage(
                                                            'Looks like something went wrong. We were unable to complete your stop.'
                                                        );
                                                        captureException(error);
                                                        console.error(error.message);
                                                    },
                                                });
                                            }
                                            editException(null);
                                        });
                                    },
                                    onClose: () => editException(null),
                                }}
                                options={{
                                    disableAddAnother: true,
                                }}
                            />
                            <ExceptionResolutionModal
                                {...resolveExceptionParams}
                                loading={resolveInflight || submitInflight}
                                route_id={route.route_id}
                                callbacks={{
                                    onClose: () => {
                                        setResolveExceptionParams({});
                                    },
                                    onResolve: async ({
                                        toSubmit,
                                        items = [],
                                        order = {},
                                        exception = {},
                                        event = {},
                                    }) => {
                                        const toRemove = items.map((item) => item.item_id);
                                        const { order_id, ...orderUpdates } = order;
                                        const { event_id, ...eventUpdates } = event;
                                        const { exception_id, ...exceptionUpdates } = exception;

                                        let routeUpdates = {};

                                        if (exception.recalc_route) {
                                            const extras = {
                                                default_end_location,
                                                preferences_start_estimate,
                                                preferences_end_estimate,
                                                preferences_warehouse_return_estimate,
                                            };

                                            try {
                                                let updates = await recalculateRoute(
                                                    route,
                                                    exception,
                                                    clientUser,
                                                    (route.orders || []).map((mapping) => mapping.order),
                                                    extras
                                                );
                                                if (
                                                    updates &&
                                                    updates.estimatedDrivingDistance &&
                                                    updates.estimatedDrivingTime
                                                ) {
                                                    routeUpdates.estimated_driving_distance =
                                                        updates.estimatedDrivingDistance;
                                                    routeUpdates.estimated_driving_time = updates.estimatedDrivingTime;
                                                }
                                            } catch (error) {
                                                console.error('Error recalculating route:', error);
                                            }
                                        }

                                        return Promise.all([
                                            resolve({
                                                variables: {
                                                    orders: order_id ? [order_id] : [],
                                                    events: event_id ? [event_id] : [],
                                                    exceptions: exception_id ? [exception_id] : [],
                                                    items: toRemove,
                                                    order_update: orderUpdates,
                                                    event_update: eventUpdates,
                                                    exception_update: exceptionUpdates,
                                                    route_update: routeUpdates,
                                                    route_id: route.route_id,
                                                    include_route_update: true,
                                                },
                                            }),
                                            ...(toSubmit
                                                ? [
                                                      submitOrders({
                                                          client_id: toSubmit.shipper_id,
                                                          orders: [toSubmit],
                                                          filename: undefined,
                                                          type: 'MANUAL',
                                                      }),
                                                  ]
                                                : []),
                                        ]).then(async ([, resp]) => {
                                            if (resp?.data?.jobs?.length > 0) {
                                                if (exception.resolution === 'CREATE_RETURN') {
                                                    await saveAssociatedReturnOrder(order_id, resp.data.jobs[0]);
                                                }
                                                navigate(`/job/${resp.data.jobs[0]}`);
                                            } else {
                                                setResolveExceptionParams({});
                                            }
                                        });
                                    },
                                }}
                            />
                            <Grid
                                container
                                sm={5}
                                direction="column"
                                css={css`
                                    height: 100%;
                                    flex-wrap: nowrap;
                                    align-items: flex-start;
                                `}
                            >
                                <RouteInfo route={route} review={ratings[0]} />
                                <RouteStops
                                    route={route}
                                    stops={route.stopsByRouteId}
                                    orders={(route.orders || []).map((mapping) => mapping.order)}
                                    callbacks={{
                                        editException,
                                        setResolveExceptionParams,
                                        setOrderId,
                                    }}
                                    exceptionStops={exceptionStops}
                                    cancelledStops={cancelledStops}
                                    stopOrderMappings={stopOrderMappings}
                                />
                            </Grid>
                            <Grid
                                container
                                sm={7}
                                css={css`
                                    height: 100%;
                                `}
                            >
                                <RouteMap
                                    stops={route.stopsByRouteId}
                                    driverId={route?.driver_id || route?.carrier_id}
                                    routeKey={route?.route_id}
                                    deliveryDate={route?.scheduled_delivery}
                                    routeStatus={route?.status}
                                />
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid
                            container
                            css={css`
                                padding: 40px 62px;
                            `}
                        >
                            <Grid
                                item
                                xs={12}
                                css={css`
                                    margin-bottom: 24px;
                                `}
                            >
                                <div
                                    css={css`
                                        display: flex;
                                        justify-content: space-between;
                                    `}
                                >
                                    <H1>Orders</H1>

                                    {route.status === 'complete' &&
                                        user?.userType === 'shipper' &&
                                        !!carrierReviewFlag &&
                                        (ratings.length > 0 ? (
                                            <PrimaryButton
                                                variant="contained"
                                                onClick={() => setCarrierReviewOpen(true)}
                                            >
                                                Edit Carrier Review
                                            </PrimaryButton>
                                        ) : (
                                            <PrimaryButton
                                                variant="contained"
                                                onClick={() => setCarrierReviewOpen(true)}
                                            >
                                                Leave Carrier Review
                                            </PrimaryButton>
                                        ))}
                                </div>
                                {!!carrierReviewFlag && (
                                    <CarrierReviewModal
                                        isOpen={carrierReviewOpen}
                                        onClose={() => setCarrierReviewOpen(false)}
                                        reviewObject={ratings[0] || {}}
                                        routeId={route.route_id}
                                        revieweeId={route.carrier_id ? route.carrier_id : route.shipper_id}
                                        revieweeType={route.carrier_id ? 'CARRIER' : 'SHIPPER'}
                                        driverId={route?.driver_id}
                                        callbacks={{
                                            onClose: () => setCarrierReviewOpen(false),
                                        }}
                                    />
                                )}
                            </Grid>
                            {!route.orders.length ? (
                                <H3>No orders on this route </H3>
                            ) : (
                                <OrderTable
                                    orders={(route.orders || []).map((mapping) => mapping.order)}
                                    route={route}
                                />
                            )}
                        </Grid>
                    )}
                </>
            )}
            <StartRouteModal
                open={startRouteModal}
                onClose={() => setStartRouteModal(false)}
                route={route}
                username={username}
                setErrorMessage={setErrorMessage}
            />
            <Snackbar
                open={!!errorMessage}
                severity="error"
                message={errorMessage}
                handleClose={() => setErrorMessage('')}
            />
        </Grid>
    );
}

export default RouteDetails;
