import { colors } from '@/styles';

export const MANIFEST_STATUSES = {
    NOT_RECEIVED: 'NOT_RECEIVED',
    RECEIVED: 'RECEIVED',
    AWAITING_STAGING: 'AWAITING_STAGING',
    IN_PROGRESS: 'IN_PROGRESS',
    STAGED: 'STAGED',
    COMPLETE: 'COMPLETE',
};

export const MANIFEST_STATUS_LABELS = {
    [MANIFEST_STATUSES.NOT_RECEIVED]: {
        label: 'Not Stored',
        color: colors.reds.primary,
    },
    [MANIFEST_STATUSES.RECEIVED]: {
        label: 'Received',
        color: colors.greens.primary,
    },
    [MANIFEST_STATUSES.AWAITING_STAGING]: {
        label: 'Awaiting Staging',
        color: colors.reds.primary,
    },
    [MANIFEST_STATUSES.STAGED]: {
        label: 'Staged',
        color: colors.blues[0],
    },
    [MANIFEST_STATUSES.IN_PROGRESS]: {
        label: 'In Progress',
        color: colors.blues[0],
    },
    [MANIFEST_STATUSES.COMPLETE]: {
        label: 'Complete',
        color: colors.greens.primary,
    },
};

export const PALLET_STATUSES = {
    RECEIVED: 'RECEIVED',
    STORED: 'STORED',
    PICKED: 'PICKED',
    STAGED: 'STAGED',
    PICKED_UP: 'PICKED_UP',
    NOT_RECEIVED: 'NOT_RECEIVED',
};

export const PALLET_STATUS_LABELS = {
    [PALLET_STATUSES.RECEIVED]: 'Received',
    [PALLET_STATUSES.STORED]: 'Stored',
    [PALLET_STATUSES.PICKED]: 'Picked',
    [PALLET_STATUSES.STAGED]: 'Staged',
    [PALLET_STATUSES.PICKED_UP]: 'Picked Up',
};

export const PALLET_STATUS_PROGRESSION = [
    PALLET_STATUSES.RECEIVED,
    PALLET_STATUSES.STORED,
    PALLET_STATUSES.PICKED,
    PALLET_STATUSES.STAGED,
    PALLET_STATUSES.PICKED_UP,
];

export const RECEIVED_STATUSES = [
    PALLET_STATUSES.RECEIVED,
    PALLET_STATUSES.STORED,
    PALLET_STATUSES.PICKED,
    PALLET_STATUSES.STAGED,
];

export const STORED_STATUSES = [
    PALLET_STATUSES.STORED,
    PALLET_STATUSES.PICKED,
    PALLET_STATUSES.STAGED,
    PALLET_STATUSES.PICKED_UP,
];

export const PICKED_STATUSES = [PALLET_STATUSES.PICKED, PALLET_STATUSES.STAGED, PALLET_STATUSES.PICKED_UP];

export const STAGED_STATUSES = [PALLET_STATUSES.STAGED, PALLET_STATUSES.PICKED_UP];

export const MANIFEST_TYPE_LABELS = {
    INBOUND: 'Inbound',
    CROSS_DOCK: 'Cross-Dock',
    OUTBOUND: 'Outbound',
    RETURN_TO_SENDER: 'OS&D',
    WILL_CALL: 'Will Call',
};

export const MANIFEST_TYPE_TOOLTIPS = {
    INBOUND: '(Receiving)',
    CROSS_DOCK: '(Receiving)',
    OUTBOUND: '(Deliveries)',
    RETURN_TO_SENDER: '(Overages, Shortages, and Damages)',
    WILL_CALL: '(Pickups)',
};
