import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { css } from '@emotion/react';
import { colors } from '@/styles';
import { Grid, TableContainer, Tooltip } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import statesInverted from '@/constants/statesInverted.json';
import { useSwappedAttributes } from '@/components/ShipmentForm/hooks';
import { FIXED_CHARGES } from '@/components/Accessorials/constants';
import { genAccessorials } from '@/components/Account/Tariffs/utils';

import { useFlexLayout, useSortBy, useTable } from 'react-table';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import BodyRow from '../CarrierAccountingOrders/table/BodyRow';
import BodyCell from '../CarrierAccountingOrders/table/BodyCell';
import HeaderCell from '../CarrierAccountingOrders/table/HeaderCell';
import { format } from 'date-fns';
import { MKPL_CHARGES } from '@/components/Accessorials/constants';
import { calcOrderSubtotal } from '@/utilities/calcOrderSubtotal';

export const integerFormatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});

const dollarFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

const percentFormatter = new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});

const INVOICE_ORDERS_COLUMNS = [
    {
        id: 'invoice-orders-group',
        columns: [
            {
                Header: 'Order',
                id: 'order_number',
                width: 100,
                Cell: ({ row }) => {
                    const order = row.original.order;
                    return order.order_number;
                },
            },
            {
                Header: 'PO',
                id: 'po_number',
                width: 150,
                Cell: ({ row }) => {
                    const order = row.original.order;
                    return order.po_number ? '-' : order.po_number;
                },
            },
            {
                Header: 'Completed',
                id: 'completed_date',
                width: 150,
                Cell: ({ row }) => {
                    const order = row.original;
                    return order.completion_time
                        ? format(new Date(order.completion_time), 'MM/dd/yyyy')
                        : order.delivery_date
                        ? format(new Date(order.delivery_date), 'MM/dd/yyyy')
                        : '-';
                },
            },
            {
                Header: 'Customer',
                id: 'dropoff_name',
                width: 250,
                Cell: ({ row }) => {
                    const order = row.original.order;
                    return order.dropoff_name;
                },
            },
            {
                Header: 'Drop Off',
                id: 'dropoff',
                width: 250,
                accessor: (order) => [order.dropoff_city, order.dropoff_state, order.dropoff_zip].join(':'),
                Cell: ({ value, row }) => {
                    const order = row.original.order;
                    const { address, state, zip, city } = useSwappedAttributes(order);
                    const stateLookup = statesInverted[order[state]] ? statesInverted[order[state]] : order[state];

                    if (order.dropoff_address_confirmed) {
                        return (
                            <Grid
                                container
                                css={css`
                                    align-items: center;
                                    flex-wrap: nowrap;
                                `}
                            >
                                <Grid
                                    item
                                    css={css`
                                        margin-right: 4px;
                                    `}
                                >
                                    <Tooltip arrow placement="top" title={`Customer confirmed: ${order[address]}`}>
                                        <CheckCircleIcon
                                            css={css`
                                                color: ${colors.greens.primary};
                                            `}
                                        />
                                    </Tooltip>
                                </Grid>
                                {`${order[city]}, ${stateLookup} ${order[zip]}`}
                            </Grid>
                        );
                    } else {
                        return `${order[city]}, ${stateLookup} ${order[zip]}`;
                    }
                },
            },
            {
                Header: 'Product/Service',
                id: 'qbo_tag',
                width: 200,
                Cell: ({ row }) => {
                    const item = row.original;
                    return item.qbo_tag || '-';
                },
            },
            {
                Header: 'QBO Class',
                id: 'qbo_class',
                disableSortBy: true,
                Cell: ({ row }) => {
                    const item = row.original;
                    return item.qbo_class || '-';
                },
            },
            {
                Header: 'Description',
                id: 'description',
                disableSortBy: true,
                Cell: ({ row }) => {
                    const item = row.original;
                    return item.description || '-';
                },
            },
            {
                Header: 'Quantity',
                id: 'quantity',
                width: 105,
                disableSortBy: true,
                Cell: ({ row }) => {
                    const item = row.original;
                    return item.quantity ? integerFormatter.format(item.quantity) : '-';
                },
            },
            {
                Header: 'Rate',
                id: 'rate',
                width: 125,
                disableSortBy: true,
                Cell: ({ row }) => {
                    const item = row.original;
                    const meta = row.original.accessorial;
                    let display = '-';
                    if (item.rate >= 0) {
                        if (meta.isPercentage) {
                            display = percentFormatter.format(item.rate);
                        } else {
                            display = dollarFormatter.format(item.rate);
                        }
                    }
                    return display;
                },
            },
            {
                Header: 'Amount',
                id: 'subtotal',
                width: 125,
                disableSortBy: true,
                Cell: ({ row }) => {
                    const item = row.original;
                    return item.total ? dollarFormatter.format(item.total) : '-';
                },
            },
        ],
    },
];

const InvoiceOrdersTable = ({ orders, types, breakdown, tags }) => {
    const [sortBy, setSortBy] = useState([{ id: 'order_number', desc: true }]);

    const flattened = useMemo(() => {
        return orders.reduce((acc, order) => {
            const type = types[order.order_id] || types.default || 'DEFAULT';
            const accessorials = genAccessorials(type, tags);
            const accessorialsMap = Object.fromEntries(
                accessorials.map((accessorial) => [accessorial.type, accessorial])
            );
            const chargeType = !!order.oms ? FIXED_CHARGES : MKPL_CHARGES;

            return [
                ...acc,
                ...chargeType
                    .map(({ display, key }) => {
                        return {
                            order,
                            type: key,
                            qbo_tag: order?.qbo_tag,
                            qbo_class: order?.invoice_class,
                            description: display,
                            total: !!order.oms ? order?.price_breakdown?.[breakdown]?.[key] : calcOrderSubtotal(order),
                        };
                    })
                    .filter((charge) => charge.total > 0),
                ...(order?.price_breakdown?.[breakdown]?.accessorials || []).map(
                    ({ type, quantity, rate, qbo_tag }) => {
                        const meta = accessorialsMap[type] || accessorialsMap.default || 'DEFAULT';
                        return {
                            order,
                            type,
                            accessorial: meta,
                            qbo_tag: qbo_tag || order?.qbo_tag,
                            qbo_class: order?.invoice_class,
                            description: meta.label || 'Custom',
                            quantity,
                            rate,
                            total: quantity * rate,
                        };
                    }
                ),
            ];
        }, []);
    }, [orders, types]);

    const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows, state } = useTable(
        {
            columns: INVOICE_ORDERS_COLUMNS,
            data: flattened,
            initialState: {
                sortBy,
            },
            getRowId: (row) => [row.order.order_id, row.type].join(':'),
            defaultCanSort: true,
        },
        useSortBy,
        useFlexLayout
    );

    useEffect(() => {
        if (state?.sortBy?.length) {
            setSortBy(state.sortBy);
        }
    }, [state?.sortBy]);

    const Row = useCallback(
        ({ index, style }) => {
            //HEADERS
            if (index === 0) {
                const [group] = headerGroups;

                return (
                    <div {...group.getHeaderGroupProps({ style })}>
                        {group.headers.reduce(
                            (acc, subgroup) => [
                                ...acc,
                                subgroup.headers.map((col) => (
                                    <HeaderCell {...col.getHeaderProps()} key={col.id} cell={col} align={col.align} />
                                )),
                            ],
                            []
                        )}
                    </div>
                );
            }

            //BODY
            const row = rows[index - 1];
            prepareRow(row);

            return (
                <BodyRow
                    {...row.getRowProps({
                        style,
                    })}
                    row={row}
                >
                    {row.cells.map((cell) => (
                        <BodyCell
                            {...cell.getCellProps()}
                            cell={cell}
                            align={cell.column.align}
                            css={css`
                                overflow: hidden;
                            `}
                        />
                    ))}
                </BodyRow>
            );
        },
        [prepareRow, rows]
    );

    return (
        <TableContainer
            css={css`
                height: 100%;
                overflow: hidden;
                background-color: white;
            `}
        >
            <div
                {...getTableProps()}
                css={css`
                    height: 100%;
                    display: flex;
                    flex-grow: 1;
                    flex-direction: column;
                `}
            >
                <div
                    {...getTableBodyProps()}
                    css={css`
                        display: flex;
                        flex-grow: 1;
                    `}
                >
                    <AutoSizer>
                        {({ height, width }) => (
                            <FixedSizeList height={height} itemCount={rows.length + 1} itemSize={57} width={width}>
                                {Row}
                            </FixedSizeList>
                        )}
                    </AutoSizer>
                </div>
            </div>
        </TableContainer>
    );
};

export default InvoiceOrdersTable;
