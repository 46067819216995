import { createContext, useMemo, useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import debounce from 'lodash/debounce';
import { useClientUser } from '@/hooks';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useExport } from '@/components/admin/AdminFinancials/payables/hooks';
import { SET_INVOICE_STATUS } from '@/components/admin/AdminFinancials/payables/graphql';
import { QUICKBOOKS_CSV_COLUMNS } from '@/components/CarrierAccountingInvoice/csvColumns';
import { useAccessorials } from '@/components/Account/Tariffs/utils';
import { GET_PRICING_OVERRIDES } from '@/graphql/queries/pricing_overrides';
import { captureException } from '@sentry/react';
import { useInvoiceColumns } from './columns';
import { CARRIER_INVOICES } from './graphql';
import { addDays } from 'date-fns';
import { asBrowserDate } from '@/utilities/convertToISO';
import { DETAILED_CSV_COLUMNS } from '../CarrierAccountingInvoice/detailedViewCsvColumns';

export const Context = createContext();

export const ContextProvider = ({ children }) => {
    const navigate = useNavigate();
    const { user_id, shipping_partners } = useClientUser();

    const [invoiceType, setInvoiceType] = useState('INTERNAL');
    const [status, setStatus] = useState('ALL');
    const [search, setSearch] = useState('');
    const [selectedMap, setSelected] = useState({});
    const [issuedDate, setIssuedDate] = useState('');
    const [dueDate, setDueDate] = useState('');
    const [selectedShippers, setSelectedShippers] = useState([]);

    const where = useMemo(() => {
        const _issuedDate = issuedDate ? asBrowserDate(issuedDate).toISOString() : null;
        const _dueDate = dueDate ? asBrowserDate(dueDate).toISOString() : null;
        let conditions = [{ client_id: { _eq: user_id } }, { type: { _eq: invoiceType } }];

        if (_issuedDate) {
            conditions.push({ created_at: { _gte: _issuedDate } });
            conditions.push({ created_at: { _lt: addDays(new Date(_issuedDate), 1).toISOString() } });
        }

        if (_dueDate) {
            conditions.push({ due_date: { _gte: _dueDate } });
            conditions.push({ due_date: { _lt: addDays(new Date(_dueDate), 1).toISOString() } });
        }

        conditions.push({ status: status === 'ALL' ? {} : { _eq: status } });

        if (search.length > 0) {
            const orConditions = [
                { orders: { order_number: { _ilike: `%${search}%` } } },
                { orders: { po_number: { _ilike: `%${search}%` } } },
            ];

            const parsedInt = parseInt(search, 10);
            if (!isNaN(parsedInt) && Number.isInteger(parsedInt)) {
                orConditions.push({ invoice_number: { _eq: parsedInt } });
            }

            conditions.push({
                _or: orConditions,
            });
        }

        if (selectedShippers.length > 0) {
            conditions.push({ partner_client_id: { _in: selectedShippers } });
        }

        return conditions;
    }, [user_id, status, search, invoiceType, issuedDate, dueDate, selectedShippers]);

    const [getInvoices, { data, loading, refetch }] = useLazyQuery(CARRIER_INVOICES, {
        onError: (err) => {
            console.error(err);
            captureException(err);
        },
    });

    const [setInvoiceStatus, { loading: invoiceStatusInflight }] = useMutation(SET_INVOICE_STATUS, {
        onError: (err) => {
            console.error(err);
            captureException(err);
        },
    });

    const handleShipperChange = (shipper) => {
        const _selectedShippers = [...selectedShippers];
        if (_selectedShippers.includes(shipper)) {
            _selectedShippers.splice(_selectedShippers.indexOf(shipper), 1);
        } else {
            _selectedShippers.push(shipper);
        }
        setSelectedShippers(_selectedShippers);
    };

    const [fetchOverrides, { data: resp }] = useLazyQuery(GET_PRICING_OVERRIDES);

    useEffect(() => {
        if (user_id) {
            fetchOverrides({
                variables: {
                    shipper_ids: [],
                    carrier_ids: [user_id],
                    client_ids: [user_id],
                    partner_client_ids: [],
                },
            });
        }
    }, [user_id]);

    const getInvoicesDebounced = useMemo(
        () =>
            debounce((where) => {
                getInvoices({
                    variables: {
                        where: {
                            _and: where,
                        },
                    },
                });
            }, 500),
        []
    );

    const selected = useMemo(() => {
        return Object.keys(selectedMap).filter((attr) => selectedMap[attr]);
    }, [selectedMap]);

    useEffect(() => {
        getInvoicesDebounced(where);
    }, [where, getInvoicesDebounced]);

    const invoices = useMemo(() => {
        // Match shipper name to alias defined on user's account under Shipping Partners
        if (shipping_partners?.length) {
            const shippingPartnerNameMap = Object.fromEntries(
                shipping_partners.map((partner) => [partner.shipper_id, partner.shipper_alias])
            );
            const invoicesWithShipperAliases = (data?.carrier_invoices || []).map((i) => {
                if (!i?.partner_client_id) {
                    return i;
                }

                const shipperAlias = shippingPartnerNameMap?.[i?.partner_client_id];
                const { business_name, ...partnerClient } = i?.partner_client || {};
                return {
                    ...i,
                    partner_client: {
                        ...(partnerClient ? partnerClient : {}),
                        business_name: shipperAlias || business_name || '',
                    },
                };
            });
            return invoicesWithShipperAliases;
        } else {
            return data?.carrier_invoices || [];
        }
    }, [data]);

    const invoicesMap = useMemo(() => {
        return Object.fromEntries(invoices.map((invoice) => [invoice.carrier_invoice_id, invoice]));
    }, [invoices]);

    const filtered = useMemo(() => {
        return invoices.filter((invoice) => {
            if (status === 'ALL') {
                return true;
            }

            return invoice.status === status;
        });
    }, [invoices, status]);

    const [type] = useMemo(() => {
        if (!resp) {
            return 'DEFAULT';
        }

        const { internal, carrier } = resp;
        const po = invoiceType === 'INTERNAL' ? internal : carrier;
        const type = po.length > 0 ? po[0]?.algo_type : 'DEFAULT';

        return [type];
    }, [resp, invoiceType]);

    const tabs = useMemo(() => {
        return [
            {
                label: 'All',
                value: 'ALL',
            },
            ...(invoiceType === 'INTERNAL'
                ? [
                      {
                          label: 'Unpaid',
                          value: 'UNPAID',
                      },
                  ]
                : []),
            ...(invoiceType === 'PAYABLE'
                ? [
                      {
                          label: 'Pending Onward Approval',
                          value: 'UNPAID',
                      },
                      {
                          label: 'Approved by Onward',
                          value: 'APPROVED',
                      },
                  ]
                : []),
            {
                label: 'Paid',
                value: 'PAID',
            },
        ];
    }, [invoiceType]);

    const accessorials = useAccessorials(type);

    const { exportSelected: exportSelectedQuickbooks } = useExport({
        accessorials,
        invoices: selected.map((id) => invoicesMap[id]),
        columns: QUICKBOOKS_CSV_COLUMNS,
        breakdown: 'carrierBreakdown',
    });

    const { exportSelected: exportSelectedDetailed } = useExport({
        accessorials,
        invoices: selected.map((id) => invoicesMap[id]),
        columns: DETAILED_CSV_COLUMNS,
        breakdown: 'carrierBreakdown',
    });

    const callbacks = {
        setStatus,
        setInvoiceType,
        setSelected,
        setDueDate,
        setIssuedDate,
        handleShipperChange,
        setSearch: (e) => {
            return setSearch(e?.target?.value || '');
        },
        setPaid: () => {
            setInvoiceStatus({
                variables: {
                    ids: selected,
                    status: 'PAID',
                },
            });
        },
        paidId: (id) => {
            setInvoiceStatus({
                variables: {
                    ids: [id],
                    status: 'PAID',
                },
            });
        },
        refetch,
        exportSelectedQuickbooks,
        exportSelectedDetailed,
        gotoInvoice: (row) => {
            const invoice = row.original;
            return navigate(
                `/carrier/accounting/invoices/${invoice.carrier_invoice_id}`, 
                { state: { 
                    allInvoices: data?.allInvoiceIds || [],
                    returnTo: '/carrier/accounting/invoices',
                } }
            );
        },
        getId: (invoice) => {
            return invoice.carrier_invoice_id;
        },
    };

    const invoiceColumns = useInvoiceColumns({ shipping_partners, status, callbacks });

    return (
        <Context.Provider
            value={{
                state: {
                    invoices: filtered,
                    status,
                    invoiceType,
                    selected,
                    totalCount: data?.carrier_invoices_aggregate?.aggregate?.count || 0,
                    loading,
                    invoiceColumns,
                    shipping_partners,
                    selectedShippers,
                    filters: [
                        {
                            label: 'Internal',
                            value: 'INTERNAL',
                        },
                        {
                            label: 'Onward',
                            value: 'PAYABLE',
                        },
                    ],
                    tabs,
                },
                loading: {
                    init: loading,
                },
                callbacks,
            }}
        >
            {children}
        </Context.Provider>
    );
};
