import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { useTotalWeight, useTotalCubes } from './hooks';
import { LocationOn, NotListedLocation, Warning as WarningIcon } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import { aggregateEvents, calcMilestone, genAttributes } from '@onward-delivery/core';
import { delWindowToFormattedDateRange } from '@/utilities/delWindowToFormattedDate';
import { useItemQuantity, useOrderCarrierPricing, useOrderShipperPricing } from '@/hooks';

import { BodyCellText } from './blocks';
import { useClientUser } from '@/hooks';
import { startCase } from 'lodash';
import { RECEIVED_STATUSES } from '@/constants/manifestStatuses';
import { colors } from '@/styles';
import { LOCATION_TYPES } from '@/constants/locationTypes';
import { toNational } from '@/utilities/formatPhoneNumber';

const Placeholder = styled.div`
    width: 24px;
    height: 24px;
`;

export const integerFormatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});

const dateShort = new Intl.DateTimeFormat('en-US', {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
});

const dollarFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

const rate = (rate, override, listingRate) => {
    if (override || override === 0) return `$${override.toFixed(2)}`;
    if (listingRate) {
        return `$${listingRate.toFixed(2)}`;
    }
    return rate ? `$${rate.toFixed(2)}` : '-';
};

export const useColumns = () => {
    const dateSort = useMemo(() => (rowA, rowB, columnId) => {
        const a = rowA.values.deldate;
        const b = rowB.values.deldate;

        return a > b ? 1 : a < b ? -1 : 0;
    });

    const { circles, userType, email, user_id, isImposter, accountType } = useClientUser();

    const carrierBrokerColumns = [
        {
            Header: 'Shipper',
            id: 'shipper',
            disableSortBy: false,
            Cell: ({ row: order }) => {
                return order?.original?.order_shipper?.business_name || '-';
            },
        },
        {
            Header: 'Rates',
            id: 'rates',
            // width: 300,
            disableSortBy: false,
            Cell: ({ row }) => {
                const order = row.original;
                if (order.oms) {
                    return order.order_revenue ? `$${order.order_revenue.toFixed(2)}` : '-';
                } else if (!order.oms && user_id === order.shipper_id) {
                    return useOrderShipperPricing(order);
                } else if (!order.oms && user_id === order.carrier_id) {
                    return useOrderCarrierPricing(order);
                } else {
                    return '-';
                }
            },
        },
    ];

    const carrierRate = [
        {
            Header: 'Rate',
            id: 'rate',
            disableSortBy: false,
            accessor: (order) => {
                const isShipper = user_id === order.shipper_id;
                const rate = order.oms ? order.order_revenue : isShipper ? order.shipper_rate : order.carrier_rate;
                return rate ? dollarFormatter.format(rate) : '--'
            },
        },
    ];

    const orderShipper = [
        {
            id: 'shipper',
            Header: 'Shipper Name',
            accessor: (order) => order.order_shipper?.business_name || '-',
            width: 250,
        },
    ]

    return useMemo(() => {
        return [
            {
                Header: '',
                id: 'order-details-group',
                columns: [
                    {
                        id: 'order_number',
                        Header: 'Order',
                        accessor: (order) => order.order_number,
                        // disableSortBy: true,
                        width: 150,
                    },
                    {
                        id: 'po_number',
                        Header: 'PO',
                        accessor: (order) => order.po_number,
                        Cell: ({ value: numbers }) => {
                            if (numbers) {
                                let split = numbers.trim().split(', ');
                                return `${split.slice(0, 2).join(', ')}${
                                    split.length > 2 ? `... and ${split.length - 2} more` : ''
                                }`;
                            }

                            return '-';
                        },
                        // disableSortBy: true,
                        width: 200,
                    },
                    {
                        id: 'reference_id',
                        Header: 'Reference ID',
                        accessor: (order) => order?.reference_id || '-',
                        disableSortBy: true,
                        width: 150,
                    },
                    {
                        id: 'service_type',
                        Header: 'Service Type',
                        accessor: (order) => {
                            const { location_type } = genAttributes(order);
                            return order.service_level?.service_level || LOCATION_TYPES[order[location_type]] || '-';
                        },
                        width: 200,
                    },
                    {
                        id: 'customer',
                        Header: 'Customer',
                        accessor: (order) => order.dropoff_name,
                        width: 300,
                        disableSortBy: true,
                    },
                    ...(accountType === 'carrier' ? [...orderShipper, ...carrierRate,] : []),
                    {
                        id: 'destination_city',
                        Header: 'Destination City',
                        accessor: (order) => {
                            const { city } = genAttributes(order);
                            return order[city] || '-';
                        },
                        width: 275,
                        disableSortBy: true,
                    },
                    {
                        id: 'destination_state',
                        Header: 'Destination State',
                        accessor: (order) => {
                            const { state } = genAttributes(order);
                            return order[state] || '-';
                        },
                        width: 275,
                        disableSortBy: true,
                    },
                    {
                        id: 'destination_zip',
                        Header: 'Destination Zip',
                        accessor: (order) => {
                            const { zip } = genAttributes(order);
                            return order[zip] || '-';
                        },
                        width: 200,
                        disableSortBy: true,
                    },
                    {
                        id: 'order_type',
                        Header: 'Order Type',
                        accessor: (order) => {
                            if (!order.job_type || order.job_type === 'SHIPMENT') {
                                return startCase(order.order_type);
                            }
                            return order.job_type
                                .split('_')
                                .map((seg) => startCase(seg))
                                .join(' ');
                        },
                        width: 200,
                        disableSortBy: true,
                    },
                    {
                        id: 'warehousestatus',
                        Header: 'Warehousing Status',
                        accessor: (order) => {
                            const warehouse = aggregateEvents(order.wh_events);
                            return warehouse.status;
                        },
                        Cell: ({ row, value }) => {
                            let fragment;
                            let label;
                            let icon = null;
                            const order = row.original;
                            switch (value) {
                                case 'RECEIVED': {
                                    label = 'Received';
                                    fragment = css`
                                        color: ${colors.greens.primary};
                                    `;
                                    break;
                                }
                                case 'NOT_DELIVERED':
                                default: {
                                    const [received, total] = (order.itemsByOrderId || []).reduce(
                                        ([recAcc, totAcc], item) => {
                                            return [
                                                recAcc +
                                                    (RECEIVED_STATUSES.includes(item.pallet?.warehouse_status)
                                                        ? item.quantity || 1
                                                        : 0),
                                                totAcc + (item.quantity || 1),
                                            ];
                                        },
                                        [0, 0]
                                    );

                                    if (received > 0) {
                                        label = `${received}/${total} Received`;
                                        fragment = css`
                                            color: ${colors.blues[0]};
                                        `;
                                    } else {
                                        label = 'Not Received';
                                        fragment = css`
                                            color: ${colors.greys[3]};
                                        `;
                                    }
                                    break;
                                }
                            }

                            return (
                                <div
                                    css={css`
                                        display: flex;
                                        align-items: center;
                                        white-space: nowrap;
                                    `}
                                >
                                    <BodyCellText
                                        css={css`
                                            font-weight: 700;
                                            ${fragment};
                                        `}
                                    >
                                        {label}
                                    </BodyCellText>
                                    {icon}
                                </div>
                            );
                        },
                        width: 200,
                    },
                    {
                        id: 'received_date',
                        Header: 'Actual Received Date',
                        accessor: (order) => {
                            const warehouse = aggregateEvents(order.wh_events);
                            return new Date(warehouse.received_date);
                        },
                        Cell: ({ value }) => {
                            if (isNaN(value) || !value?.getTime()) {
                                return '-';
                            }

                            return dateShort.format(value);
                        },
                        width: 200,
                    },
                    {
                        id: 'deldate',
                        Header: 'Scheduled Date',
                        accessor: (order) => {
                            return order?.delivery_date_formatted || '-';
                        },
                        width: 200,
                    },
                    {
                        id: 'manufacturer',
                        Header: 'Manufacturer',
                        accessor: (order) => order?.manufacturer || '-',
                        width: 200,
                        disableSortBy: true,
                    },
                    {
                        id: 'qty',
                        Header: 'Units',
                        Cell: ({ row: order }) => {
                            const items = useItemQuantity([order]);

                            return integerFormatter.format(items);
                        },
                        width: 100,
                        disableSortBy: true,
                    },
                    {
                        id: 'vol',
                        Header: 'Cu. Ft.',
                        Cell: ({ row: order }) => {
                            const cubes = useTotalCubes([order.original]);

                            return integerFormatter.format(cubes);
                        },
                        disableSortBy: true,
                    },
                    {
                        id: 'weight',
                        Header: 'Weight (lbs)',
                        Cell: ({ row: order }) => {
                            const weight = useTotalWeight([order.original]);

                            return integerFormatter.format(weight);
                        },
                        disableSortBy: true,
                    },
                    {
                        Header: 'Estimated Delivery Date Range',
                        id: 'estimated_delivery_range',
                        width: 300,
                        disableSortBy: false,
                        Cell: ({ row: order }) => {
                            if (
                                !order.original.estimated_delivery_range_end &&
                                !order.original.estimated_delivery_range_end
                            ) {
                                return '-';
                            }
                            return `${order.original.estimated_delivery_range_start_formatted} - ${order.original.estimated_delivery_range_end_formatted}`;
                        },
                    },
                    {
                        id: 'target_delivery_date',
                        Header: 'Target Delivery Date',
                        accessor: (order) => {
                            return new Date(order?.target_delivery_date);
                        },
                        Cell: ({ value }) => {
                            if (isNaN(value) || !value?.getTime()) {
                                return '-';
                            }

                            return dateShort.format(value);
                        },
                        width: 200,
                        disableSortBy: true,
                    },
                    ...(accountType !== 'carrier' ? orderShipper : []),
                    {
                        id: 'dropoff_phone',
                        Header: 'Customer Primary Phone',
                        accessor: (order) => {
                            return toNational(order.dropoff_phone, 'N/A');
                        },
                        width: 250,
                        disableSortBy: true,
                    },
                    {
                        id: 'secondary_dropoff_contact_phone',
                        Header: 'Customer Primary Phone',
                        accessor: (order) => {
                            return toNational(order.secondary_dropoff_contact_phone, 'N/A');
                        },
                        width: 250,
                        disableSortBy: true,
                    },
                    {
                        id: 'dropoff_email',
                        Header: 'Customer Email',
                        accessor: (order) => order.dropoff_email || '-',
                        width: 200,
                        disableSortBy: true,
                    },
                    {
                        id: 'middle_mile_integration_meta.tracking_number',
                        Header: 'Middle Mile Tracking #',
                        width: 200,
                        accessor: (order) => {
                            return order.middle_mile_integration_meta?.tracking_number;
                        },
                        Cell: ({ row: order, value }) => {
                            return value || '-';
                        },
                        disableSortBy: true,
                    },
                    {
                        id: 'middle_mile_integration_status',
                        Header: 'Middle Mile Status',
                        width: 200,
                        accessor: (order) => {
                            return order.middle_mile_integration_status;
                        },
                        Cell: ({ row: order, value }) => {
                            return value ? startCase(value.toLowerCase()).replace('_', '-') : '--';
                        },
                        disableSortBy: true,
                    },
                    {
                        id: 'est_ship_date',
                        Header: 'Est Ship Date',
                        accessor: (order) => {
                            const warehouse = aggregateEvents(order.wh_events);
                            return new Date(warehouse.est_ship_date);
                        },
                        Cell: ({ value }) => {
                            if (isNaN(value) || !value?.getTime()) {
                                return '-';
                            }

                            return dateShort.format(value);
                        },
                        width: 200,
                    },
                    {
                        id: 'est_received_date',
                        Header: 'Est Received Date',
                        accessor: (order) => {
                            const warehouse = aggregateEvents(order.wh_events);
                            return new Date(warehouse.est_received_date);
                        },
                        Cell: ({ value }) => {
                            if (isNaN(value) || !value?.getTime()) {
                                return '-';
                            }

                            return dateShort.format(value);
                        },
                        width: 200,
                    },
                    {
                        id: 'origin_city',
                        Header: 'Origin City',
                        accessor: (order) => {
                            const { city } = genAttributes(order, 'PICKUP');
                            return order[city] || '-';
                        },
                        width: 275,
                        disableSortBy: true,
                    },
                    {
                        id: 'origin_state',
                        Header: 'Origin State',
                        accessor: (order) => {
                            const { state } = genAttributes(order, 'PICKUP');
                            return order[state] || '-';
                        },
                        width: 275,
                        disableSortBy: true,
                    },
                    {
                        id: 'origin_zip',
                        Header: 'Origin Zip',
                        accessor: (order) => {
                            const { zip } = genAttributes(order, 'PICKUP');
                            return order[zip] || '-';
                        },
                        width: 200,
                        disableSortBy: true,
                    },
                ],
            },
            {
                sticky: 'right',
                Header: '',
                id: 'sticky-right',
                columns: [
                    {
                        id: 'milestone',
                        Header: 'Status',
                        accessor: (order) => calcMilestone(order) || '-',
                        width: 200,
                        disableSortBy: true,
                    },
                ],
            },
        ];
    }, [accountType, user_id]);
};
