import {
    Card,
    Grid,
    IconButton,
    InputAdornment,
    MenuItem,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TextField,
} from '@material-ui/core';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { Context } from '../store';
import NavResponsiveModal, {
    ModalActions,
    ModalContent,
    ModalHeader,
} from '@/components/Navigation/NavResponsiveModal';
import { PrimaryButton, SecondaryButton } from '@/styles/blocks';
import { Body1, H1 } from '../blocks';
import { cloneDeep, isNumber, startCase } from 'lodash';
import { AddCircle, Check, Error, RemoveCircle } from '@material-ui/icons';
import { colors } from '@/styles';
import CurrencyTextField from '@unicef/material-ui-currency-textfield/dist/CurrencyTextField';
import { OnwardCheckbox, OnwardToggle } from '@/components/ShipmentForm/blocks';
import { useClientUser } from '@/hooks';

export const SERVICE_LEVELS = {
    CURBSIDE_NO_CONTACT: 'Dock / Curbside - No Contact',
    CURBSIDE_NO_CONTACT_2MAN: '2 Man Dock / Curbside - No Contact',
    CURBSIDE: 'Dock / Curbside',
    CURBSIDE_2MAN: '2 Man Dock / Curbside',
    THRESHOLD: 'Threshold / First Dry Space',
    THRESHOLD_2MAN: '2 Man Threshold / First Dry Space',
    ROOM_OF_CHOICE: 'Room of Choice',
    ROOM_OF_CHOICE_2MAN: '2 Man Room of Choice',
    WHITE_GLOVE: 'White Glove',
    WHITE_GLOVE_2MAN: '2 Man White Glove',
    WHITE_GLOVE_ASSEMBLY: 'White Glove w/ Assembly',
    WHITE_GLOVE_ASSEMBLY_2MAN: '2 Man White Glove w/ Assembly',
};

export const genServiceLevels = (serviceLevels = []) => {
    return [
        ...serviceLevels.map((service_level) => ({
            key: service_level.service_level_id,
            label: service_level.service_level,
            typePrefix: 'CUSTOM_SERVICE_LEVEL',
            service_level_id: service_level.service_level_id,
        })),
        ...Object.entries(SERVICE_LEVELS).map(([key, label]) => ({
            key,
            label,
            typePrefix: key,
        })),
    ];
};

const SERVICE_ACCESSORIALS = [
    { label: 'Items Included', type: 'ITEMS_INCLUDED', adornment: '', decorator: 'items', omitTag: true },
    { label: 'Additional Items', type: 'ITEMS_INCREMENTAL_RATE', adornment: '$', decorator: '/item' },
    { label: 'Labor', type: 'LABOR_INCREMENTAL_RATE', adornment: '$', decorator: '/increment', min: 'minutes' },
    { label: 'Labor Max', type: 'LABOR_MAX_RATE', adornment: '$', decorator: 'max' },
    { label: 'Labor Increments', type: 'LABOR_INCREMENTS', adornment: '', decorator: 'minutes', omitTag: true },
];

const COMMON_ACCESSORIALS = [
    { label: 'Stairs', type: 'STAIRS_BASE_RATE', adornment: '$' },
    {
        label: 'Additional Stairs',
        type: 'STAIRS_INCREMENTAL_RATE',
        adornment: '$',
        decorator: '/flight',
        min: 'flights',
    },
    {
        label: 'Additional Stairs by Weight',
        type: 'STAIRS_WEIGHT_RATE',
        adornment: '$',
        decorator: '/lb /flight',
        min: 'flights',
    },
    { label: 'Stairs Min', type: 'STAIRS_MIN_RATE', adornment: '$', decorator: 'min' },
    { label: 'Storage', type: 'STORAGE_INCREMENTAL_RATE', adornment: '$', decorator: '/day', min: 'days' },
    { label: 'Storage Max', type: 'STORAGE_MAX_RATE', adornment: '$', decorator: '/month' },
    { label: 'Haulaways', type: 'HAULAWAYS_BASE_RATE', adornment: '$' },
    {
        label: 'Additional Haulaways',
        type: 'HAULAWAYS_INCREMENTAL_RATE',
        adornment: '$',
        decorator: '/item',
        min: 'items',
    },
    { label: 'Packing Flat', type: 'PACKING_BASE_RATE', adornment: '$' },
    { label: 'Packing by Volume', type: 'PACKING_VOLUME_RATE', adornment: '$', decorator: '/cu ft' },
    { label: 'Packing Min', type: 'PACKING_MIN_RATE', adornment: '$', decorator: 'min' },
    { label: 'Airline', type: 'AIRLINE_BASE_RATE', adornment: '$' },
    {
        label: 'Fuel Surcharge',
        type: 'FUEL_PRICE_MODIFIER',
        adornment: '%',
        isPercentage: true,
        decorator: 'of subtotal',
    },
    {
        label: 'Exchange (percentage)',
        type: 'EXCHANGE_MODIFIER_RATE',
        adornment: '%',
        isPercentage: true,
        decorator: 'of freight charge',
    },
    { label: 'Exchange (flat)', type: 'EXCHANGE_FLAT_RATE', adornment: '$' },
    {
        label: 'Attempt (percentage)',
        type: 'ATTEMPT_MODIFIER_RATE',
        adornment: '%',
        isPercentage: true,
        decorator: 'of freight charge',
    },
    { label: 'Attempt (flat)', type: 'ATTEMPT_FLAT_RATE', adornment: '$' },
    {
        label: 'Cancellation (percentage)',
        type: 'CANCELLATION_MODIFIER_RATE',
        adornment: '%',
        isPercentage: true,
        decorator: 'of freight charge',
    },
    { label: 'Cancellation (flat)', type: 'CANCELLATION_FLAT_RATE', adornment: '$' },
];

export const ACCESSORIALS = [...SERVICE_ACCESSORIALS, ...COMMON_ACCESSORIALS];

export const genCustomAccessorials = (tags = []) => {
    return tags
        .filter((tag) => tag.accessorial_tag)
        .map((tag) => ({
            label: tag.tag,
            type: `CUSTOM_ACCESSORIAL_${tag.tag}`,
            adornment: '$',
        }));
};

const genSubregionDefaultRates = (subregions = [], service_levels = []) => {
    const serviceLevels = genServiceLevels(service_levels);
    return serviceLevels.reduce((acc, serviceLevel) => {
        return {
            ...acc,
            [`${serviceLevel.typePrefix}_BASE_RATE`]: subregions.map((subregion) => ({
                subregion_id: subregion.subregion_id,
                service_level_id: serviceLevel.service_level_id,
                min: 0,
                max: 'infinity',
                min2: 0,
                max2: 'infinity',
                rate: 0,
            })),
            [`${serviceLevel.typePrefix}_MIN_RATE`]: subregions.map((subregion) => ({
                subregion_id: subregion.subregion_id,
                service_level_id: serviceLevel.service_level_id,
                min: 0,
                max: 'infinity',
                min2: 0,
                max2: 'infinity',
                rate: 0,
            })),
            [`${serviceLevel.typePrefix}_ADDITIONAL_PRIMARY_RATE`]: [],
            [`${serviceLevel.typePrefix}_ADDITIONAL_SECONDARY_RATE`]: [],
            [`${serviceLevel.typePrefix}_ITEMS_INCREMENTAL_RATE`]: subregions.map((subregion) => ({
                subregion_id: subregion.subregion_id,
                service_level_id: serviceLevel.service_level_id,
                min: 0,
                max: 'infinity',
                min2: 0,
                max2: 'infinity',
                rate: 0,
            })),
        };
    }, {});
};

const genDefaultRates = (service_levels = []) => {
    const serviceLevels = genServiceLevels(service_levels);
    return serviceLevels.reduce((acc, serviceLevel) => {
        return {
            ...acc,
            [`${serviceLevel.typePrefix}_BASE_RATE`]: [
                { service_level_id: serviceLevel.service_level_id, min: 0, max: 25, min2: 0, max2: 'infinity' },
                { service_level_id: serviceLevel.service_level_id, min: 25, max: 50, min2: 0, max2: 'infinity' },
                { service_level_id: serviceLevel.service_level_id, min: 50, max: 75, min2: 0, max2: 'infinity' },
                {
                    service_level_id: serviceLevel.service_level_id,
                    min: 75,
                    max: 'infinity',
                    min2: 0,
                    max2: 'infinity',
                },
            ],
            [`${serviceLevel.typePrefix}_MIN_RATE`]: [
                { service_level_id: serviceLevel.service_level_id, min: 0, max: 25, min2: 0, max2: 'infinity' },
                { service_level_id: serviceLevel.service_level_id, min: 25, max: 50, min2: 0, max2: 'infinity' },
                { service_level_id: serviceLevel.service_level_id, min: 50, max: 75, min2: 0, max2: 'infinity' },
                {
                    service_level_id: serviceLevel.service_level_id,
                    min: 75,
                    max: 'infinity',
                    min2: 0,
                    max2: 'infinity',
                },
            ],
            [`${serviceLevel.typePrefix}_ADDITIONAL_PRIMARY_RATE`]: [],
            [`${serviceLevel.typePrefix}_ADDITIONAL_SECONDARY_RATE`]: [],
            [`${serviceLevel.typePrefix}_ITEMS_INCREMENTAL_RATE`]: [
                { service_level_id: serviceLevel.service_level_id, min: 0, max: 25 },
                { service_level_id: serviceLevel.service_level_id, min: 25, max: 50 },
                { service_level_id: serviceLevel.service_level_id, min: 50, max: 75 },
                { service_level_id: serviceLevel.service_level_id, min: 75, max: 'infinity' },
            ],
            [`${serviceLevel.typePrefix}_ITEMS_INCLUDED`]: [
                { service_level_id: serviceLevel.service_level_id, min: 0, max: 'infinity', rate: 2 },
            ],
            [`${serviceLevel.typePrefix}_LABOR_INCREMENTAL_RATE`]: [
                { service_level_id: serviceLevel.service_level_id, min: 30, max: 'infinity', rate: 0 },
            ],
            [`${serviceLevel.typePrefix}_LABOR_MAX_RATE`]: [
                { service_level_id: serviceLevel.service_level_id, min: 0, max: 'infinity', rate: 0 },
            ],
            [`${serviceLevel.typePrefix}_LABOR_INCREMENTS`]: [
                { service_level_id: serviceLevel.service_level_id, min: 0, max: 'infinity', rate: 5 },
            ],
        };
    }, {});
};

export const DEFAULT_RATES = {
    ...genDefaultRates([]),
    STAIRS_BASE_RATE: [{ min: 0, max: 'infinity' }],
    STAIRS_INCREMENTAL_RATE: [{ min: 2, max: 'infinity' }],
    STAIRS_WEIGHT_RATE: [{ min: 2, max: 'infinity', rate: 0 }],
    STAIRS_MIN_RATE: [{ min: 0, max: 'infinity', rate: 0 }],
    STORAGE_INCREMENTAL_RATE: [{ min: 14, max: 'infinity' }],
    STORAGE_MAX_RATE: [{ min: 0, max: 'infinity' }],
    HAULAWAYS_BASE_RATE: [{ min: 0, max: 'infinity', rate: 0 }],
    HAULAWAYS_INCREMENTAL_RATE: [{ min: 0, max: 'infinity' }],
    PACKING_BASE_RATE: [{ min: 0, max: 'infinity' }],
    PACKING_VOLUME_RATE: [{ min: 0, max: 'infinity', rate: 0 }],
    PACKING_MIN_RATE: [{ min: 0, max: 'infinity', rate: 0 }],
    ATTEMPT_FLAT_RATE: [{ min: 0, max: 'infinity' }],
    ATTEMPT_MODIFIER_RATE: [{ min: 0, max: 'infinity' }],
    CANCELLATION_FLAT_RATE: [{ min: 0, max: 'infinity' }],
    CANCELLATION_MODIFIER_RATE: [{ min: 0, max: 'infinity' }],
    EXCHANGE_FLAT_RATE: [{ min: 0, max: 'infinity' }],
    EXCHANGE_MODIFIER_RATE: [{ min: 0, max: 'infinity' }],
    AIRLINE_BASE_RATE: [{ min: 0, max: 'infinity', rate: 0 }],
};

export const AXIS_OPTIONS = {
    MILEAGE: 'Distance (mi)',
    SUBREGION: 'Area (zip)',
    VOLUME: 'Volume (cu ft)',
    WEIGHT: 'Weight (lbs)',
    DIM_WEIGHT: 'DIM Weight',
};

export const AXIS2_OPTIONS = {
    MILEAGE: 'Distance (mi)',
    VOLUME: 'Volume (cu ft)',
    WEIGHT: 'Weight (lbs)',
    DIM_WEIGHT: 'DIM Weight',
};

const AXIS_DECORATORS = {
    MILEAGE: 'mi',
    SUBREGION: '',
    VOLUME: 'cu ft',
    WEIGHT: 'lbs',
    DIM_WEIGHT: 'dim',
};

export const QTY_OPTIONS = {
    FLAT: 'Flat rate $',
    MILEAGE: '$ per mi',
    VOLUME: '$ per cu ft',
    WEIGHT: '$ per lb',
    DIM_WEIGHT: '$ per dim weight',
};

const QTY_DECORATORS = {
    FLAT: '',
    MILEAGE: '/mi',
    VOLUME: '/cu ft',
    WEIGHT: '/lb',
    DIM_WEIGHT: '/dim',
};

const CopyBaseRates = ({ open, state, callbacks, serviceLevels, serviceLevel }) => {
    const [copyTo, setCopyTo] = useState({});

    const isValid = useMemo(() => {
        return Object.values(copyTo).some((x) => !!x);
    }, [copyTo]);

    return (
        <NavResponsiveModal open={open} onClose={callbacks.onClose}>
            <ModalHeader title="Copy Base Rates" onClose={callbacks.onCose} />
            <ModalContent>
                <Grid
                    container
                    css={css`
                        margin-bottom: 12px;
                    `}
                >
                    <Body1>
                        Copy{' '}
                        <span
                            css={css`
                                font-weight: 700;
                            `}
                        >
                            {serviceLevel.label}
                        </span>{' '}
                        base rates to other service levels
                    </Body1>
                </Grid>
                {serviceLevels
                    .filter(({ key }) => key !== serviceLevel.key)
                    .map(({ key, label }) => (
                        <Grid
                            key={key}
                            container
                            css={css`
                                margin-bottom: 12px;
                            `}
                        >
                            <OnwardCheckbox
                                label={label}
                                checked={!!copyTo[key]}
                                onChange={(e) => setCopyTo((prev) => ({ ...prev, [key]: e.target.checked }))}
                            />
                        </Grid>
                    ))}
            </ModalContent>
            <ModalActions>
                <SecondaryButton onClick={callbacks.onClose}>Cancel</SecondaryButton>
                <PrimaryButton
                    onClick={() => {
                        callbacks.confirm({ serviceLevels: serviceLevels.filter(({ key }) => copyTo[key]) });
                        setCopyTo({});
                    }}
                    disabled={!isValid}
                >
                    Confirm
                </PrimaryButton>
            </ModalActions>
        </NavResponsiveModal>
    );
};

const BandsConfig = ({ additional, bands, type, startAdornment, endAdornment, callbacks }) => {
    return (
        <>
            <Grid
                container
                css={css`
                    align-items: center;
                `}
            >
                <H1>
                    {startAdornment} Axis{endAdornment ? ` (${endAdornment})` : ''}
                </H1>
                <IconButton disabled={bands.length <= 2} onClick={callbacks.decrement}>
                    <RemoveCircle
                        css={css`
                            color: ${colors.reds.primary};
                        `}
                    />
                </IconButton>
                <IconButton onClick={callbacks.increment}>
                    <AddCircle
                        css={css`
                            color: ${colors.greens.primary};
                        `}
                    />
                </IconButton>
                <OnwardToggle
                    checked={`${bands[bands.length - 1]}`.toLowerCase() !== 'infinity'}
                    onChange={(e) => callbacks.toggleCutoff(e.target.checked)}
                />
                <Body1
                    css={css`
                        margin-left: 12px;
                    `}
                >
                    Hard Cutoff
                </Body1>
            </Grid>
            <Grid
                container
                css={css`
                    margin-bottom: 12px;
                `}
            >
                {bands.map((band, x) =>
                    `${band}`.toLowerCase() === 'infinity' ? (
                        <TextField
                            key={`${type}-${x}`}
                            disabled
                            value={'Infinity'}
                            css={css`
                                width: 75px;
                                margin-right: 12px;
                            `}
                        />
                    ) : (
                        <TextField
                            key={`${type}-${x}`}
                            type="number"
                            disabled={x === 0}
                            value={band}
                            onChange={(e) => {
                                const clone = cloneDeep(bands);
                                clone[x] = parseInt(e.target.value);
                                callbacks.setBands(clone);
                            }}
                            css={css`
                                width: 75px;
                                margin-right: 12px;
                            `}
                            error={!isNumber(band) || bands[x - 1] >= band || bands[x + 1] <= band}
                        />
                    )
                )}
            </Grid>
            <Grid
                container
                css={css`
                    margin-top: 12px;
                    margin-bottom: 24px;
                    align-items: center;
                `}
            >
                <Body1>Additional ${QTY_DECORATORS[type]} after: </Body1>
                <TextField
                    type="number"
                    value={additional}
                    onChange={(e) => callbacks.setAdditional(parseInt(e.target.value))}
                    css={css`
                        margin-left: 12px;
                        width: 75px;
                    `}
                />
            </Grid>
        </>
    );
};

const GenerateGrid = ({ open, state, callbacks, baseRates, additionalPrimary, additionalSecondary }) => {
    const [bandsX, setBandsX] = useState([]);
    const [bandsY, setBandsY] = useState([]);
    const [additionalX, setAdditionalX] = useState();
    const [additionalY, setAdditionalY] = useState();

    useEffect(() => {
        setBandsX([...(baseRates?.[0]?.rates || [{ min: 0 }]).map((rate) => rate.min), 'infinity']);
        setBandsY(
            !state.base_rates_axis2
                ? [0, 'infinity']
                : [...(baseRates || [{ min: 0 }]).map((rate) => rate.min), 'infinity']
        );
        setAdditionalX(Math.max(...(additionalPrimary || [{ min: 0 }]).map((rate) => rate.min || 0)));
        setAdditionalY(Math.max(...(additionalSecondary || [{ min2: 0 }]).map((rate) => rate.min2 || 0)));
    }, [state, baseRates, additionalPrimary, additionalSecondary]);

    const isValid = useMemo(() => {
        const isBandsValid = (bands, type) => {
            return (
                type === 'SUBREGION' ||
                bands.every(
                    (band, x) =>
                        (isNumber(band) || `${band}`.toLowerCase() === 'infinity') &&
                        !(bands[x - 1] >= band || bands[x + 1] <= band)
                )
            );
        };
        return isBandsValid(bandsX, state.base_rates_axis) && isBandsValid(bandsY, state.base_rates_axis2);
    }, [state, bandsX, bandsY]);

    const incrementor = (prev) => {
        const last = prev[prev.length - 1];
        if (`${last}`.toLowerCase() === 'infinity') {
            const clone = prev.slice(0, prev.length - 1);
            return [...clone, clone[clone.length - 1] + 25, 'infinity'];
        } else {
            return [...prev, last + 25];
        }
    };

    const decrementor = (prev) => {
        const last = prev[prev.length - 1];
        if (`${last}`.toLowerCase() === 'infinity') {
            const clone = prev.slice(0, prev.length - 2);
            return [...clone, 'infinity'];
        } else {
            return prev.slice(0, prev.length - 1);
        }
    };

    const cutoffToggler = (checked) => (prev) => {
        const clone = prev.slice(0, prev.length - 1);
        return [...clone, checked ? clone[clone.length - 1] + 25 : 'infinity'];
    };

    return (
        <NavResponsiveModal open={open} onClose={callbacks.onClose}>
            <ModalHeader title="Generate Base Rates Grid" onClose={callbacks.onClose} />
            <ModalContent
                css={css`
                    min-width: 600px;
                `}
            >
                {state.base_rates_axis !== 'SUBREGION' ? (
                    <BandsConfig
                        additional={additionalX}
                        bands={bandsX}
                        type={state.base_rates_axis}
                        startAdornment={'X'}
                        endAdornment={AXIS_DECORATORS[state.base_rates_axis]}
                        callbacks={{
                            increment: () => setBandsX(incrementor),
                            decrement: () => setBandsX(decrementor),
                            toggleCutoff: (checked) => setBandsX(cutoffToggler(checked)),
                            setBands: (val) => setBandsX(val),
                            setAdditional: (val) => setAdditionalX(val),
                        }}
                    />
                ) : null}

                {state.base_rates_axis2 && state.base_rates_axis2 !== 'SUBREGION' ? (
                    <BandsConfig
                        additional={additionalY}
                        bands={bandsY}
                        type={state.base_rates_axis2}
                        startAdornment={'Y'}
                        endAdornment={AXIS_DECORATORS[state.base_rates_axis2]}
                        callbacks={{
                            increment: () => setBandsY(incrementor),
                            decrement: () => setBandsY(decrementor),
                            toggleCutoff: (checked) => setBandsY(cutoffToggler(checked)),
                            setBands: (val) => setBandsY(val),
                            setAdditional: (val) => setAdditionalY(val),
                        }}
                    />
                ) : null}
            </ModalContent>
            <ModalActions>
                <SecondaryButton onClick={callbacks.onClose}>Cancel</SecondaryButton>
                <PrimaryButton
                    onClick={() => callbacks.confirm({ bandsX, bandsY, additionalX, additionalY })}
                    disabled={!isValid}
                >
                    Confirm
                </PrimaryButton>
            </ModalActions>
        </NavResponsiveModal>
    );
};

const SingleRate = ({
    label,
    adornment,
    decorator,
    isPercentage,
    type,
    rates: allRates,
    errors,
    callbacks,
    omitTag = false,
    qbo_tags = [],
    serviceLevelId,
}) => {
    let rates = allRates?.[type] || [];
    if (type.startsWith('CUSTOM_SERVICE_LEVEL')) {
        rates = rates.filter((r) => r.service_level_id === serviceLevelId);
    }
    const rate = rates?.[0] || {};
    const mod = isPercentage ? 100 : 1;

    return (
        <Grid
            container
            css={css`
                margin-bottom: 12px;
                flex-wrap: nowrap;
                align-items: center;
            `}
        >
            <Grid
                item
                css={css`
                    width: 200px;
                    margin-right: 24px;
                `}
            >
                <Body1>{label}</Body1>
            </Grid>
            <Grid
                item
                css={css`
                    width: 512px;
                    margin-right: 24px;
                `}
            >
                <CurrencyTextField
                    size="small"
                    value={rate.rate ? rate.rate * mod : ''}
                    onChange={(_, val) => {
                        callbacks.setRate({
                            type,
                            bucket: 0,
                            rate: val / mod,
                        });
                    }}
                    error={errors?.missing?.[type] && !(rate.rate !== null && rate.rate >= 0)}
                    InputProps={{
                        startAdornment: adornment ? (
                            <InputAdornment position="start">{adornment}</InputAdornment>
                        ) : null,
                        endAdornment: decorator ? <InputAdornment position="end">{decorator}</InputAdornment> : null,
                    }}
                    css={css`
                        width: 150px;
                    `}
                />
            </Grid>
            <Grid item>
                {!omitTag ? (
                    <TextField
                        select
                        size="small"
                        label="QBO Product/Service"
                        value={rate?.qbo_tag || ''}
                        onChange={(e) => {
                            const next = e.target.value;
                            callbacks.updateQBO({
                                qbo: next,
                                types: [type],
                            });
                        }}
                        css={css`
                            width: 200px;
                        `}
                    >
                        {qbo_tags.map((tag) => (
                            <MenuItem key={tag} value={tag}>
                                {tag}
                            </MenuItem>
                        ))}
                    </TextField>
                ) : null}
            </Grid>
        </Grid>
    );
};

const IncrementalRate = ({
    label,
    adornment,
    decorator,
    isPercentage,
    min,
    type,
    rates: allRates,
    errors,
    callbacks,
    omitTag = false,
    qbo_tags = [],
    serviceLevelId,
}) => {
    let rates = allRates?.[type] || [];
    if (type.startsWith('CUSTOM_SERVICE_LEVEL')) {
        rates = rates.filter((r) => r.service_level_id === serviceLevelId);
    }
    const rate = rates?.[0] || {};
    const mod = isPercentage ? 100 : 1;

    return (
        <Grid
            container
            css={css`
                margin-bottom: 12px;
                flex-wrap: nowrap;
                align-items: center;
            `}
        >
            <Grid
                item
                css={css`
                    width: 200px;
                    margin-right: 24px;
                `}
            >
                <Body1>{label}</Body1>
            </Grid>
            <Grid
                item
                css={css`
                    width: 512px;
                    margin-right: 24px;
                `}
            >
                <CurrencyTextField
                    size="small"
                    value={rate.rate ? rate.rate * mod : ''}
                    onChange={(_, val) => {
                        callbacks.setRate({
                            type,
                            bucket: 0,
                            rate: val / mod,
                        });
                    }}
                    error={errors?.missing?.[type] && !(rate.rate !== null && rate.rate >= 0)}
                    InputProps={{
                        startAdornment: adornment ? (
                            <InputAdornment position="start">{adornment}</InputAdornment>
                        ) : null,
                        endAdornment: decorator ? <InputAdornment position="end">{decorator}</InputAdornment> : null,
                    }}
                    css={css`
                        width: 150px;
                        margin-right: 12px;
                    `}
                />
                <Body1
                    css={css`
                        margin-right: 12px;
                    `}
                >
                    after
                </Body1>
                <CurrencyTextField
                    size="small"
                    value={rate.min || ''}
                    onChange={(_, val) => {
                        callbacks.setRate({
                            type,
                            bucket: 0,
                            min: val,
                        });
                    }}
                    InputProps={{
                        startAdornment: null,
                        endAdornment: min ? <InputAdornment position="end">{min}</InputAdornment> : null,
                    }}
                    css={css`
                        width: 150px;
                    `}
                />
            </Grid>
            <Grid item>
                {!omitTag ? (
                    <TextField
                        select
                        size="small"
                        label="QBO Product/Service"
                        value={rate?.qbo_tag || ''}
                        onChange={(e) => {
                            const next = e.target.value;
                            callbacks.updateQBO({
                                qbo: next,
                                types: [type],
                            });
                        }}
                        css={css`
                            width: 200px;
                        `}
                    >
                        {qbo_tags.map((tag) => (
                            <MenuItem key={tag} value={tag}>
                                {tag}
                            </MenuItem>
                        ))}
                    </TextField>
                ) : null}
            </Grid>
        </Grid>
    );
};

const ComboRate = ({
    label,
    flat,
    modifier,
    rates: allRates,
    errors,
    callbacks,
    omitTag = false,
    qbo_tags = [],
    serviceLevelId,
}) => {
    const [flatRates, modifierRates] = [flat, modifier].map((type) => {
        let rates = allRates?.[type] || [];
        if (type.startsWith('CUSTOM_SERVICE_LEVEL')) {
            rates = rates.filter((r) => r.service_level_id === serviceLevelId);
        }
        return rates;
    });

    const flatRate = flatRates?.[0] || {};
    const modifierRate = modifierRates?.[0] || {};

    return (
        <Grid
            container
            css={css`
                margin-bottom: 12px;
                flex-wrap: nowrap;
                align-items: center;
            `}
        >
            <Grid
                item
                css={css`
                    width: 200px;
                    margin-right: 24px;
                `}
            >
                <Body1>{label}</Body1>
            </Grid>
            <Grid
                item
                css={css`
                    width: 512px;
                    margin-right: 24px;
                `}
            >
                <CurrencyTextField
                    size="small"
                    value={flatRate.rate || ''}
                    onChange={(_, val) => {
                        callbacks.setRate({
                            type: flat,
                            bucket: 0,
                            rate: val / 1,
                        });
                    }}
                    error={errors?.missing?.[flat] && !(flatRate.rate !== null && flatRate.rate >= 0)}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    css={css`
                        width: 150px;
                        margin-right: 12px;
                    `}
                />
                <Body1
                    css={css`
                        margin-right: 12px;
                    `}
                >
                    or
                </Body1>
                <CurrencyTextField
                    size="small"
                    value={modifierRate.rate ? modifierRate.rate * 100 : ''}
                    onChange={(_, val) => {
                        callbacks.setRate({
                            type: modifier,
                            bucket: 0,
                            rate: val / 100,
                        });
                    }}
                    error={errors?.missing?.[modifier] && !(modifierRate.rate !== null && modifierRate.rate >= 0)}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">%</InputAdornment>,
                    }}
                    css={css`
                        width: 150px;
                        margin-right: 12px;
                    `}
                />
                <Body1>whichever is greater</Body1>
            </Grid>

            <Grid item>
                {!omitTag ? (
                    <TextField
                        select
                        size="small"
                        label="QBO Product/Service"
                        value={flatRate?.qbo_tag || ''}
                        onChange={(e) => {
                            const next = e.target.value;
                            callbacks.updateQBO({
                                qbo: next,
                                types: [flat, modifier],
                            });
                        }}
                        css={css`
                            width: 200px;
                        `}
                    >
                        {qbo_tags.map((tag) => (
                            <MenuItem key={tag} value={tag}>
                                {tag}
                            </MenuItem>
                        ))}
                    </TextField>
                ) : null}
            </Grid>
        </Grid>
    );
};

const renderAccessorial = ({ accessorial, rates, errors, callbacks, qbo_tags, serviceLevelId }) => {
    const params = {
        rates,
        errors,
        callbacks,
        qbo_tags,
        serviceLevelId,
    };

    if (['_LABOR_INCREMENTAL_RATE'].some((substr) => accessorial.type.endsWith(substr))) {
        return <IncrementalRate {...accessorial} {...params} />;
    }

    if (
        ['_LABOR_MAX_RATE', '_LABOR_INCREMENTS', '_ITEMS_INCLUDED'].some((substr) =>
            accessorial.type.endsWith(substr)
        ) ||
        ['CUSTOM_ACCESSORIAL_'].some((substr) => accessorial.type.startsWith(substr))
    ) {
        return <SingleRate {...accessorial} {...params} />;
    }

    switch (accessorial.type) {
        case 'STAIRS_INCREMENTAL_RATE':
        case 'STORAGE_INCREMENTAL_RATE':
        case 'HAULAWAYS_INCREMENTAL_RATE':
            return <IncrementalRate {...accessorial} {...params} />;
        case 'STAIRS_BASE_RATE':
        case 'STAIRS_WEIGHT_RATE':
        case 'STAIRS_MIN_RATE':
        case 'PACKING_BASE_RATE':
        case 'PACKING_VOLUME_RATE':
        case 'PACKING_MIN_RATE':
        case 'STORAGE_MAX_RATE':
        case 'HAULAWAYS_BASE_RATE':
        case 'AIRLINE_BASE_RATE':
            return <SingleRate {...accessorial} {...params} />;
        case 'ATTEMPT_FLAT_RATE':
        case 'CANCELLATION_FLAT_RATE':
        case 'EXCHANGE_FLAT_RATE':
            const modifier = accessorial.type.replace('FLAT', 'MODIFIER');
            return (
                <ComboRate
                    label={startCase(accessorial.type.split('_')[0].toLowerCase())}
                    flat={accessorial.type}
                    modifier={modifier}
                    {...params}
                />
            );
        case 'FUEL_PRICE_MODIFIER':
        case 'ATTEMPT_MODIFIER_RATE':
        case 'CANCELLATION_MODIFIER_RATE':
        case 'EXCHANGE_MODIFIER_RATE':
        default:
            return null;
    }
};

export const OnwardConsolidated = () => {
    const { tags, qbo_tags, service_levels } = useClientUser();
    const [serviceLevelKey, selectServiceLevel] = useState(Object.keys(SERVICE_LEVELS)[0]);
    const { state, errors, callbacks } = useContext(Context);
    const [generatingGrid, generateGrid] = useState(false);
    const [copyingBaseRates, copyBaseRates] = useState(false);
    const [decimalPlacesOverride, setDecimalPlaces] = useState(null);

    const serviceLevels = useMemo(() => {
        return genServiceLevels(service_levels);
    }, [service_levels]);

    const serviceLevel = useMemo(() => {
        const byKey = Object.fromEntries(serviceLevels.map((sl) => [sl.key, sl]));
        return byKey[serviceLevelKey] || {};
    }, [serviceLevelKey, serviceLevels]);

    const [baseRates, itemRates, additionalPrimary, additionalSecondary] = useMemo(() => {
        const serviceFilter = (r) =>
            serviceLevel.service_level_id ? serviceLevel.service_level_id === r.service_level_id : true;

        const baseRates = cloneDeep(state.rates[`${serviceLevel.typePrefix}_BASE_RATE`] || []).filter(serviceFilter);
        const minRates = cloneDeep(state.rates[`${serviceLevel.typePrefix}_MIN_RATE`] || []).filter(serviceFilter);
        const itemRates = cloneDeep(state.rates[`${serviceLevel.typePrefix}_ITEMS_INCREMENTAL_RATE`] || []).filter(
            serviceFilter
        );
        const additionalPrimary = cloneDeep(
            state.rates[`${serviceLevel.typePrefix}_ADDITIONAL_PRIMARY_RATE`] || []
        ).filter(serviceFilter);
        const additionalSecondary = cloneDeep(
            state.rates[`${serviceLevel.typePrefix}_ADDITIONAL_SECONDARY_RATE`] || []
        ).filter(serviceFilter);
        let grouped = baseRates
            .reduce((acc, rate) => {
                const rowIdx = acc.findIndex((r) => r.min === rate.min2);
                if (rowIdx >= 0) {
                    acc[rowIdx].rates = [...(acc[rowIdx].rates || []), rate].sort((a, b) => a.min - b.min);
                    return acc;
                } else {
                    return [
                        ...acc,
                        {
                            min: rate.min2,
                            max: rate.max2,
                            rates: [rate],
                        },
                    ];
                }
            }, [])
            .sort((a, b) => a.min - b.min);
        minRates.forEach((rate) => {
            const row = grouped.find((r) => r.min === rate.min2);
            if (row) {
                const bucket = row?.rates?.find((b) =>
                    b.subregion_id ? b.subregion_id === rate.subregion_id : b.min === rate.min
                );
                if (bucket) bucket.minimumRate = rate.rate;
            }
        });
        additionalPrimary.forEach((rate) => {
            const row = grouped.find((r) => r.min === rate.min2);
            if (row) {
                row.additionalRate = rate;
            }
        });
        return [grouped, itemRates, additionalPrimary, additionalSecondary];
    }, [state.rates, serviceLevel]);

    const decimalPlaces = useMemo(() => {
        if (decimalPlacesOverride) return decimalPlacesOverride;
        let max = 2;
        for (const bucket of baseRates) {
            for (const rate of bucket?.rates || []) {
                const decimals = `${rate.rate}`.split('.')[1]?.length || 2;
                max = Math.max(max, decimals);
            }
        }
        return max;
    }, [decimalPlacesOverride, baseRates]);

    const customAccessorials = useMemo(() => {
        return genCustomAccessorials(tags);
    }, [tags]);

    const getHeader = (rate, type, axis2 = false) => {
        if (type === 'SUBREGION') {
            const subregion = state.subregions.findIndex((s) => s.subregion_id === rate.subregion_id);
            return subregion >= 0 ? state.subregions[subregion].name || `Subregion ${subregion}` : rate.subregion_id;
        }
        const attrs = axis2 ? ['min2', 'max2'] : ['min', 'max'];
        const min = rate[attrs[0]];
        const max = rate[attrs[1]];
        const range = `${max}`.toLowerCase() === 'infinity' ? `${min} +` : `${min} - ${max}`;
        return `${range} ${AXIS_DECORATORS[type]}`;
    };

    const fragments = {
        cell: css`
            text-wrap: nowrap;
        `,
    };

    const [baseRateQBO, itemRateQBO] = useMemo(() => {
        return [baseRates[0]?.rates?.[0]?.qbo_tag, itemRates?.[0]?.qbo_tag];
    }, [baseRates, itemRates]);

    return (
        <>
            <Card
                css={css`
                    margin-bottom: 30px;
                    padding: 24px;
                    overflow: visible;
                `}
            >
                <Grid
                    container
                    css={css`
                        margin-bottom: 12px;
                    `}
                >
                    <H1>Tariff Configuration</H1>
                </Grid>
                <Grid
                    container
                    css={css`
                        margin-bottom: 24px;
                        flex-wrap: nowrap;
                    `}
                >
                    <TextField
                        select
                        variant="outlined"
                        label="X Axis"
                        value={state.base_rates_axis || ''}
                        onChange={(e) => {
                            const prev = state.base_rates_axis;
                            const next = e.target.value;
                            if (prev !== next && next === 'SUBREGION') {
                                callbacks.setRatesMulti({
                                    rates: genSubregionDefaultRates(state.subregions, service_levels),
                                });
                            }
                            if (prev !== next && prev === 'SUBREGION') {
                                callbacks.setRatesMulti({
                                    rates: genDefaultRates(service_levels),
                                });
                            }
                            callbacks.updateTariff({ base_rates_axis: next });
                        }}
                        css={css`
                            width: 190px;
                        `}
                        error={state.base_rates_axis === state.base_rates_axis2}
                    >
                        {Object.entries(AXIS_OPTIONS).map(([value, label]) => (
                            <MenuItem value={value} key={value} disabled={state.base_rates_axis2 === value}>
                                {label}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        select
                        variant="outlined"
                        label="Y Axis"
                        value={state.base_rates_axis2 || ''}
                        onChange={(e) => {
                            const next = e.target.value === 'NONE' ? null : e.target.value;
                            callbacks.updateTariff({
                                base_rates_axis2: next,
                            });
                        }}
                        css={css`
                            margin-left: 20px;
                            width: 190px;
                        `}
                        error={state.base_rates_axis === state.base_rates_axis2}
                    >
                        <MenuItem value={'NONE'}>None</MenuItem>
                        {Object.entries(AXIS2_OPTIONS).map(([value, label]) => (
                            <MenuItem value={value} key={value} disabled={state.base_rates_axis === value}>
                                {label}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        select
                        variant="outlined"
                        label="Base Rate Type"
                        value={state.base_rates_qty || ''}
                        onChange={(e) => callbacks.updateTariff({ base_rates_qty: e.target.value })}
                        css={css`
                            margin-left: 20px;
                            width: 190px;
                        `}
                    >
                        {Object.entries(QTY_OPTIONS).map(([value, label]) => (
                            <MenuItem value={value} key={value}>
                                {label}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        variant="outlined"
                        type="number"
                        label="Decimal Places"
                        value={decimalPlaces || 2}
                        onChange={(e) => setDecimalPlaces(parseInt(e.target.value))}
                        css={css`
                            margin-left: 20px;
                            width: 190px;
                        `}
                    />
                </Grid>
                <Grid
                    container
                    css={css`
                        margin-bottom: 24px;
                        align-items: center;
                    `}
                >
                    <TextField
                        select
                        variant="outlined"
                        label="Service Level"
                        value={serviceLevelKey}
                        onChange={(e) => selectServiceLevel(e.target.value)}
                        css={css`
                            width: 400px;
                        `}
                    >
                        {serviceLevels.map(({ key, label }) => (
                            <MenuItem value={key} key={key}>
                                {label}
                            </MenuItem>
                        ))}
                    </TextField>

                    <PrimaryButton
                        size="small"
                        css={css`
                            width: 190px;
                            margin-left: 20px;
                        `}
                        onClick={() => generateGrid(true)}
                    >
                        Generate New Grid
                    </PrimaryButton>

                    <GenerateGrid
                        open={generatingGrid}
                        state={state}
                        baseRates={baseRates}
                        additionalPrimary={additionalPrimary}
                        additionalSecondary={additionalSecondary}
                        callbacks={{
                            ...callbacks,
                            onClose: () => generateGrid(false),
                            confirm: ({ bandsX, bandsY, additionalX, additionalY }) => {
                                let newBase = [];
                                let newItems = [];
                                let newAdditionalX = [];
                                let newAdditionalY = [];
                                if (state.base_rates_axis === 'SUBREGION') {
                                    state.subregions.forEach((subregion, x) => {
                                        for (let y = 0; y < bandsY.length - 1; y++) {
                                            newBase.push({
                                                min: 0,
                                                max: 'infinity',
                                                min2: bandsY[y],
                                                max2: bandsY[y + 1],
                                                subregion_id: subregion.subregion_id,
                                                rate: 0,
                                            });
                                        }
                                        newItems.push({
                                            min: 0,
                                            max: 'infinity',
                                            subregion_id: subregion.subregion_id,
                                        });
                                        if (additionalY >= 0) {
                                            newAdditionalY.push({
                                                min: 0,
                                                max: 'infinity',
                                                min2: additionalY,
                                                max2: 'infinity',
                                                subregion_id: subregion.subregion_id,
                                                rate: 0,
                                            });
                                        }
                                    });
                                } else {
                                    for (let x = 0; x < bandsX.length - 1; x++) {
                                        newItems.push({
                                            min: bandsX[x],
                                            max: bandsX[x + 1],
                                        });
                                        if (state.base_rates_axis2 === 'SUBREGION') {
                                            state.subregions.forEach((subregion, y) => {
                                                newBase.push({
                                                    min: bandsX[x],
                                                    max: bandsX[x + 1],
                                                    min2: 0,
                                                    max2: 'infinity',
                                                    subregion_Id: subregion.subregion_id,
                                                    rate: 0,
                                                });
                                                if (x === 0 && additionalX >= 0) {
                                                    newAdditionalX.push({
                                                        min: additionalX,
                                                        max: 'infinity',
                                                        min2: 0,
                                                        max2: 'infinity',
                                                        subregion_id: subregion.subregion_id,
                                                        rate: 0,
                                                    });
                                                }
                                            });
                                        } else {
                                            if (additionalY >= 0) {
                                                newAdditionalY.push({
                                                    min: bandsX[x],
                                                    max: bandsX[x + 1],
                                                    min2: additionalY,
                                                    max2: 'infinity',
                                                    rate: 0,
                                                });
                                            }
                                            for (let y = 0; y < bandsY.length - 1; y++) {
                                                newBase.push({
                                                    min: bandsX[x],
                                                    max: bandsX[x + 1],
                                                    min2: bandsY[y],
                                                    max2: bandsY[y + 1],
                                                    rate: 0,
                                                });
                                                if (x === 0 && additionalX >= 0) {
                                                    newAdditionalX.push({
                                                        min: additionalX,
                                                        max: 'infinity',
                                                        min2: bandsY[y],
                                                        max2: bandsY[y + 1],
                                                        rate: 0,
                                                    });
                                                }
                                            }
                                        }
                                    }
                                }

                                callbacks.setRatesMulti({
                                    rates: {
                                        [`${serviceLevel.typePrefix}_BASE_RATE`]: newBase.map((r) => ({
                                            ...r,
                                            service_level_id: serviceLevel.service_level_id,
                                        })),
                                        [`${serviceLevel.typePrefix}_ITEMS_INCREMENTAL_RATE`]: newItems.map((r) => ({
                                            ...r,
                                            service_level_id: serviceLevel.service_level_id,
                                        })),
                                        [`${serviceLevel.typePrefix}_MIN_RATE`]: newBase.map((r) => ({
                                            ...r,
                                            rate: 0,
                                            service_level_id: serviceLevel.service_level_id,
                                        })),
                                        [`${serviceLevel.typePrefix}_ADDITIONAL_PRIMARY_RATE`]: newAdditionalX.map(
                                            (r) => ({ ...r, service_level_id: serviceLevel.service_level_id })
                                        ),
                                        [`${serviceLevel.typePrefix}_ADDITIONAL_SECONDARY_RATE`]: newAdditionalY.map(
                                            (r) => ({ ...r, service_level_id: serviceLevel.service_level_id })
                                        ),
                                        ...Object.fromEntries(
                                            SERVICE_ACCESSORIALS.map(({ type }) => {
                                                return [
                                                    `${serviceLevel.typePrefix}_${type}`,
                                                    [
                                                        {
                                                            service_level_id: serviceLevel.service_level_id,
                                                            min: 0,
                                                            max: 'infinity',
                                                            rate: 0,
                                                        },
                                                    ],
                                                ];
                                            })
                                        ),
                                    },
                                });
                                generateGrid(false);
                            },
                        }}
                    />
                    <PrimaryButton
                        size="small"
                        css={css`
                            width: 190px;
                            margin-left: 20px;
                        `}
                        onClick={() => copyBaseRates(true)}
                    >
                        Copy Base Rates
                    </PrimaryButton>

                    <CopyBaseRates
                        open={copyingBaseRates}
                        serviceLevels={serviceLevels}
                        serviceLevel={serviceLevel}
                        callbacks={{
                            onClose: () => copyBaseRates(false),
                            confirm: ({ serviceLevels }) => {
                                let updates = {};
                                for (const level of serviceLevels) {
                                    for (const type of [
                                        'BASE_RATE',
                                        'MIN_RATE',
                                        'ITEMS_INCREMENTAL_RATE',
                                        'ITEMS_INCLUDED',
                                        'ADDITIONAL_PRIMARY_RATE',
                                        'ADDITIONAL_SECONDARY_RATE',
                                        'LABOR_INCREMENTAL_RATE',
                                        'LABOR_MAX_RATE',
                                        'LABOR_INCREMENTS',
                                    ]) {
                                        const copyFrom = state.rates[`${serviceLevel.typePrefix}_${type}`];
                                        const newType = `${level.typePrefix}_${type}`;
                                        const copied = cloneDeep(copyFrom).map((rate) => ({
                                            ...rate,
                                            type: newType,
                                            service_level_id: level.service_level_id,
                                        }));
                                        updates[newType] = [...(updates[newType] || []), ...copied];
                                    }
                                }
                                callbacks.setRatesMulti({ rates: updates });
                                copyBaseRates(false);
                            },
                        }}
                    />
                </Grid>

                <Grid
                    container
                    css={css`
                        align-items: center;
                        margin-bottom: 12px;
                    `}
                >
                    <H1>Base Rates</H1>
                    <TextField
                        select
                        size="small"
                        label="QBO Product/Service"
                        value={baseRateQBO || ''}
                        onChange={(e) => {
                            const next = e.target.value;
                            callbacks.updateQBO({
                                qbo: next,
                                types: [
                                    'BASE_RATE',
                                    'MIN_RATE',
                                    'ADDITIONAL_PRIMARY_RATE',
                                    'ADDITIONAL_SECONDARY_RATE',
                                ].map((type) => `${serviceLevel.typePrefix}_${type}`),
                            });
                        }}
                        css={css`
                            width: 300px;
                            margin-left: 1rem;
                        `}
                    >
                        {(qbo_tags || []).map((qbo) => (
                            <MenuItem key={qbo} value={qbo}>
                                {qbo}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                {[state.base_rates_qty, state.base_rates_axis, state.base_rates_axis2].some(
                    (attr) => attr === 'DIM_WEIGHT'
                ) ? (
                    <Grid
                        container
                        css={css`
                            margin-bottom: 12px;
                        `}
                    >
                        <SingleRate
                            label="DIM Weight Factor"
                            adornment=""
                            decorator="cu in/lb"
                            type="DIMENSIONAL_WEIGHT_FACTOR"
                            rates={state.rates}
                            errors={errors}
                            callbacks={callbacks}
                        />
                    </Grid>
                ) : null}
                <Table
                    key={decimalPlaces}
                    css={css`
                        margin-bottom: 24px;
                    `}
                >
                    <TableRow>
                        {state.base_rates_axis2 ? <TableCell css={fragments.cell} /> : null}
                        {(baseRates[0]?.rates || []).map((rate) => (
                            <TableCell css={fragments.cell} key={`header-${rate.subregion_id}-${rate.min}`}>
                                <Body1>{getHeader(rate, state.base_rates_axis)}</Body1>
                            </TableCell>
                        ))}
                        {additionalPrimary?.length ? (
                            <TableCell css={fragments.cell}>
                                <Body1>{getHeader(additionalPrimary[0], state.base_rates_axis)}</Body1>
                            </TableCell>
                        ) : null}
                    </TableRow>
                    {(baseRates || []).map((group) => (
                        <TableRow key={`group-${group.min}`}>
                            {state.base_rates_axis2 ? (
                                <TableCell css={fragments.cell}>
                                    <Body1>{getHeader(group, state.base_rates_axis2)}</Body1>
                                </TableCell>
                            ) : null}
                            {(group.rates || []).map((rate) => (
                                <TableCell key={`${rate.subregion_id}-${rate.min}`}>
                                    <Grid container direction="column">
                                        <CurrencyTextField
                                            size="small"
                                            value={rate.rate || ''}
                                            decimalPlaces={decimalPlaces}
                                            onChange={(_, val) => {
                                                callbacks.updateRate({
                                                    type: `${serviceLevel.typePrefix}_BASE_RATE`,
                                                    service_level_id: serviceLevel.service_level_id,
                                                    min: rate.min,
                                                    min2: group.min,
                                                    subregion_id: rate.subregion_id,
                                                    rate: val,
                                                });
                                            }}
                                            error={
                                                errors?.missing?.[`${serviceLevel.typePrefix}_BASE_RATE`] &&
                                                !(rate.rate !== null && rate.rate >= 0)
                                            }
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                endAdornment: QTY_DECORATORS[state.base_rates_qty] ? (
                                                    <InputAdornment position="end">
                                                        {QTY_DECORATORS[state.base_rates_qty]}
                                                    </InputAdornment>
                                                ) : null,
                                            }}
                                            css={css`
                                                width: 150px;
                                            `}
                                        />
                                        {state.base_rates_qty !== 'FLAT' ? (
                                            <CurrencyTextField
                                                size="small"
                                                value={rate.minimumRate || ''}
                                                onChange={(_, val) => {
                                                    callbacks.updateRate({
                                                        type: `${serviceLevel.typePrefix}_MIN_RATE`,
                                                        service_level_id: serviceLevel.service_level_id,
                                                        min: rate.min,
                                                        min2: group.min,
                                                        subregion_id: rate.subregion_id,
                                                        rate: val,
                                                    });
                                                }}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                    endAdornment: QTY_DECORATORS[state.base_rates_qty] ? (
                                                        <InputAdornment position="end">min</InputAdornment>
                                                    ) : null,
                                                }}
                                                css={css`
                                                    width: 150px;
                                                `}
                                            />
                                        ) : null}
                                    </Grid>
                                </TableCell>
                            ))}
                            {additionalPrimary?.length ? (
                                <TableCell>
                                    <CurrencyTextField
                                        size="small"
                                        value={group.additionalRate?.rate || ''}
                                        decimalPlaces={decimalPlaces}
                                        onChange={(_, val) => {
                                            callbacks.updateRate({
                                                type: `${serviceLevel.typePrefix}_ADDITIONAL_PRIMARY_RATE`,
                                                service_level_id: serviceLevel.service_level_id,
                                                min: group.additionalRate.min,
                                                min2: group.min,
                                                subregion_id: group.additionalRate.subregion_id,
                                                rate: val,
                                            });
                                        }}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    {QTY_DECORATORS[state.base_rates_axis]}
                                                </InputAdornment>
                                            ),
                                        }}
                                        css={css`
                                            width: 150px;
                                        `}
                                    />
                                </TableCell>
                            ) : null}
                        </TableRow>
                    ))}
                    {state.base_rates_axis2 && additionalSecondary?.length ? (
                        <TableRow>
                            <TableCell css={fragments.cell}>
                                <Body1>{getHeader(additionalSecondary[0], state.base_rates_axis2, true)}</Body1>
                            </TableCell>
                            {additionalSecondary.map((rate) => (
                                <TableCell key={`${rate.subregion_id}-${rate.min}`}>
                                    <CurrencyTextField
                                        size="small"
                                        value={rate.rate || ''}
                                        decimalPlaces={decimalPlaces}
                                        onChange={(_, val) => {
                                            callbacks.updateRate({
                                                type: `${serviceLevel.typePrefix}_ADDITIONAL_SECONDARY_RATE`,
                                                service_level_id: serviceLevel.service_level_id,
                                                min: rate.min,
                                                min2: rate.min2,
                                                subregion_id: rate.subregion_id,
                                                rate: val,
                                            });
                                        }}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    {QTY_DECORATORS[state.base_rates_axis2]}
                                                </InputAdornment>
                                            ),
                                        }}
                                        css={css`
                                            width: 150px;
                                        `}
                                    />
                                </TableCell>
                            ))}
                        </TableRow>
                    ) : null}
                </Table>

                <Grid
                    container
                    css={css`
                        align-items: center;
                        margin-bottom: 12px;
                    `}
                >
                    <H1>Item Rates</H1>
                    <TextField
                        select
                        size="small"
                        label="QBO Product/Service"
                        value={itemRateQBO || ''}
                        onChange={(e) => {
                            const next = e.target.value;
                            callbacks.updateQBO({
                                qbo: next,
                                types: ['ITEMS_INCREMENTAL_RATE'].map((type) => `${serviceLevel.typePrefix}_${type}`),
                            });
                        }}
                        css={css`
                            width: 300px;
                            margin-left: 1rem;
                        `}
                    >
                        {(qbo_tags || []).map((qbo) => (
                            <MenuItem key={qbo} value={qbo}>
                                {qbo}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Table
                    css={css`
                        margin-bottom: 24px;
                    `}
                >
                    <TableRow>
                        {itemRates.map((rate) => (
                            <TableCell css={fragments.cell} key={`header-${rate.subregion_id}-${rate.min}`}>
                                <Body1>{getHeader(rate, state.base_rates_axis)}</Body1>
                            </TableCell>
                        ))}
                    </TableRow>
                    <TableRow>
                        {itemRates.map((rate) => (
                            <TableCell key={`${rate.subregion_id}-${rate.min}`}>
                                <CurrencyTextField
                                    size="small"
                                    value={rate.rate || ''}
                                    onChange={(_, val) =>
                                        callbacks.updateRate({
                                            type: `${serviceLevel.typePrefix}_ITEMS_INCREMENTAL_RATE`,
                                            service_level_id: serviceLevel.service_level_id,
                                            min: rate.min,
                                            min2: rate.min2,
                                            subregion_id: rate.subregion_id,
                                            rate: val,
                                        })
                                    }
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                        endAdornment: <InputAdornment position="end">{'/item'}</InputAdornment>,
                                    }}
                                    css={css`
                                        width: 150px;
                                    `}
                                />
                            </TableCell>
                        ))}
                    </TableRow>
                </Table>

                <Grid
                    container
                    css={css`
                        margin-bottom: 12px;
                    `}
                >
                    <H1>Accessorials</H1>
                </Grid>
                {SERVICE_ACCESSORIALS.map((accessorial) =>
                    renderAccessorial({
                        accessorial: { ...accessorial, type: `${serviceLevel.typePrefix}_${accessorial.type}` },
                        serviceLevelId: serviceLevel.service_level_id,
                        rates: state.rates,
                        errors,
                        callbacks,
                        qbo_tags,
                    })
                )}
            </Card>
            <Card
                css={css`
                    margin-bottom: 30px;
                    padding: 24px;
                    overflow: visible;
                `}
            >
                <Grid
                    container
                    css={css`
                        margin-bottom: 12px;
                    `}
                >
                    <H1>Common Accessorials</H1>
                </Grid>
                {COMMON_ACCESSORIALS.map((accessorial) =>
                    renderAccessorial({
                        accessorial,
                        rates: state.rates,
                        errors,
                        callbacks,
                        qbo_tags,
                    })
                )}
            </Card>
            <Card
                css={css`
                    margin-bottom: 30px;
                    padding: 24px;
                    overflow: visible;
                `}
            >
                <Grid
                    container
                    css={css`
                        margin-bottom: 12px;
                    `}
                >
                    <H1>Custom Accessorials</H1>
                </Grid>

                {customAccessorials.length > 0 ? (
                    <>
                        {customAccessorials.map((accessorial) =>
                            renderAccessorial({
                                accessorial,
                                rates: state.rates,
                                errors,
                                callbacks,
                                qbo_tags,
                            })
                        )}
                    </>
                ) : (
                    <Body1>Create some order tags to create custom accessorials</Body1>
                )}
            </Card>
        </>
    );
};
