import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { colors } from '@/styles';
import { Grid, Tooltip, IconButton, Menu, MenuItem } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useClientUser, useTotalCubesSubscription } from '@/hooks';
import { useTotalWeightSubscription } from '@/hooks';
import statesInverted from '@/constants/statesInverted.json';
import { useSwappedAttributes } from '@/components/ShipmentForm/hooks';
import { aggregateEvents, CROSS_DOCKABLE_STATES, genAttributes } from '@onward-delivery/core';
import { asBrowserDate, asDateInTZ } from '@/utilities/convertToISO';
import {
    EditOutlined as EditOutlinedIcon,
    Send as SendIcon,
    Warning as WarningIcon,
    CallSplit as CallSplitIcon,
    MoreVert,
    Warning,
} from '@material-ui/icons';
import { BodyAnchor } from './blocks';
import { BodyCellText } from '../MyOrders/blocks';
import { formatInTimeZone } from 'date-fns-tz';
import { LOCATION_TYPES } from '@/constants/locationTypes';

const Placeholder = styled.div`
    width: 24px;
    height: 24px;
`;

export const integerFormatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});

const dateShort = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    weekday: 'short',
});

export const useTableColumns = ({ editEnabled = false, marketplaceEnabled = false, accountType, user_id }) => {
    const carrierColumns = [
        {
            Header: 'Owner',
            id: 'owner',
            width: 125,
            disableSortBy: true,
            accessor: (order) => order.shipper_id,
            Cell: ({ value, row: order }) => {
                if (order.original.oms) {
                    return (
                        <div
                            css={css`
                                color: #ff903d;
                                font-weight: 600;
                            `}
                        >
                            Internal
                        </div>
                    );
                }

                return (
                    <div
                        css={css`
                            color: #59b863;
                            font-weight: 600;
                        `}
                    >
                        {order.original.carrier_id === user_id ? 'Claimed' : 'Onward'}
                    </div>
                );
            },
        },
    ];

    return useMemo(() => {
        return [
            {
                Header: 'Order Details',
                id: 'order-details-group',
                columns: [
                    {
                        Header: 'Order',
                        id: 'order_number',
                        width: 160,
                        disableSortBy: false,
                        accessor: (order) => order.order_number,
                        Cell: ({ value, row }) => {
                            const order = row.original;
                            return (
                                <div>
                                    <BodyCellText>{order.order_number}</BodyCellText>
                                    {order.exceptions.length ? (
                                        <Warning
                                            style={{ fontSize: 20 }}
                                            css={css`
                                                color: #ff903d;
                                                padding-bottom: 3px;
                                            `}
                                        />
                                    ) : null}
                                    {order.crossdock_leg ? (
                                        <BodyCellText
                                            css={css`
                                                font-style: italic;
                                                color: ${colors.greys.secondary};
                                            `}
                                        >
                                            {` (${order.crossdock_leg === 'dropoff' ? 'delivery' : 'pickup'})`}
                                        </BodyCellText>
                                    ) : null}
                                </div>
                            );
                        },
                    },
                    {
                        Header: 'PO',
                        id: 'po_number',
                        width: 200,
                        disableSortBy: false,
                        accessor: (order) => (!order.po_number ? 'N/A' : order.po_number),
                        Cell: ({ value, row: order }) => {
                            if (!value) {
                                return 'N/A';
                            }

                            return (
                                <div
                                    css={css`
                                        display: flex;
                                        align-items: center;
                                        white-space: nowrap;
                                    `}
                                >
                                    <BodyCellText>{value}</BodyCellText>
                                </div>
                            );
                        },
                    },
                    {
                        Header: 'Customer',
                        id: 'dropoff_name',
                        width: 200,
                        disableSortBy: false,
                        accessor: (order) => order.dropoff_name,
                    },
                    {
                        Header: 'Service Type',
                        id: 'service_type',
                        width: 200,
                        disableSortBy: true,
                        accessor: (order) => {
                            const { location_type } = genAttributes(order);
                            return order.service_level?.service_level || LOCATION_TYPES[order[location_type]] || '-';
                        },
                    },
                    ...(accountType === 'carrier'
                        ? [
                              {
                                  Header: 'Shipper',
                                  id: 'shipper',
                                  width: 200,
                                  disableSortBy: true,
                                  accessor: (order) =>
                                      (order.shipper_id !== user_id && order.order_shipper?.business_name) || '-',
                              },
                              {
                                  Header: 'Manufacturer',
                                  id: 'manufacturer',
                                  width: 200,
                                  disableSortBy: true,
                                  accessor: (order) => order.manufacturer || '-',
                              },
                          ]
                        : []),
                    {
                        Header: 'Pick Up',
                        id: 'pickup',
                        width: 250,
                        disableSortBy: false,
                        accessor: (order) => [order.pickup_city, order.pickup_state, order.pickup_zip].join(':'),
                        Cell: ({ row: order }) => {
                            const { state, zip, city } = useSwappedAttributes(order.original, true);
                            const stateLookup = statesInverted[order.original[state]]
                                ? statesInverted[order.original[state]]
                                : order.original[state];
                            return `${order.original[city]}, ${stateLookup} ${order.original[zip]}`;
                        },
                    },
                    {
                        Header: 'Drop Off',
                        id: 'dropoff',
                        width: 250,
                        disableSortBy: false,
                        accessor: (order) => [order.dropoff_city, order.dropoff_state, order.dropoff_zip].join(':'),
                        Cell: ({ value, row: order }) => {
                            const { address, state, zip, city } = useSwappedAttributes(order.original);
                            const stateLookup = statesInverted[order.original[state]]
                                ? statesInverted[order.original[state]]
                                : order.original[state];

                            if (order.original.dropoff_address_confirmed) {
                                return (
                                    <Grid
                                        container
                                        css={css`
                                            align-items: center;
                                            flex-wrap: nowrap;
                                        `}
                                    >
                                        <Grid
                                            item
                                            css={css`
                                                margin-right: 4px;
                                            `}
                                        >
                                            <Tooltip
                                                arrow
                                                placement="top"
                                                title={`Customer confirmed: ${order.original[address]}`}
                                            >
                                                <CheckCircleIcon
                                                    css={css`
                                                        color: ${colors.greens.primary};
                                                    `}
                                                />
                                            </Tooltip>
                                        </Grid>
                                        {`${order.original[city]}, ${stateLookup} ${order.original[zip]}`}
                                    </Grid>
                                );
                            } else {
                                return `${order.original[city]}, ${stateLookup} ${order.original[zip]}`;
                            }
                        },
                    },
                    {
                        Header: 'Estimated Delivery Date Range',
                        id: 'estimated_delivery_range',
                        width: 300,
                        disableSortBy: false,
                        Cell: ({ row: order }) => {
                            if (
                                !order.original.estimated_delivery_range_end &&
                                !order.original.estimated_delivery_range_end
                            ) {
                                return '--';
                            }
                            return `${order.original.estimated_delivery_range_start_formatted} - ${order.original.estimated_delivery_range_end_formatted}`;
                        },
                    },
                    {
                        Header: 'Customer Availability',
                        id: 'availability',
                        width: 300,
                        disableSortBy: true,
                        Cell: ({ row }) => {
                            return (row.original.alternative_delivery_dates_formatted || []).join(', ') || '--';
                        },
                    },
                    {
                        id: 'vol',
                        Header: 'Cu. Ft',
                        width: 75,
                        disableSortBy: true,
                        Cell: ({ row: order }) => {
                            const cubes = useTotalCubesSubscription([order.original]);

                            return integerFormatter.format(cubes);
                        },
                    },
                    {
                        id: 'weight',
                        Header: 'Weight',
                        width: 75,
                        disableSortBy: true,
                        Cell: ({ row: order }) => {
                            const weight = useTotalWeightSubscription([order.original]);

                            return integerFormatter.format(weight);
                        },
                    },
                    {
                        Header: 'Order Type',
                        id: 'order_type',
                        width: 125,
                        disableSortBy: true,
                        accessor: (order) => order.order_type,
                        Cell: ({ value }) => {
                            if (!value) {
                                return 'Delivery';
                            }

                            return `${value[0].toUpperCase()}${value.slice(1)}`;
                        },
                    },
                    ...(accountType === 'carrier' ? carrierColumns : []),
                    {
                        id: 'est_ship_date',
                        Header: 'Estimated Ship Date',
                        accessor: (order) => {
                            const warehouse = aggregateEvents(order.wh_events);
                            return new Date(warehouse.est_ship_date);
                        },
                        Cell: ({ value }) => {
                            if (isNaN(value) || !value?.getTime()) {
                                return '-';
                            }

                            return dateShort.format(value);
                        },
                        width: 200,
                        disableSortBy: true,
                    },
                    {
                        id: 'est_received_date',
                        Header: 'Estimated Received Date',
                        accessor: (order) => {
                            const warehouse = aggregateEvents(order.wh_events);
                            return new Date(warehouse.est_received_date);
                        },
                        Cell: ({ value }) => {
                            if (isNaN(value) || !value?.getTime()) {
                                return '-';
                            }

                            return dateShort.format(value);
                        },
                        width: 200,
                        disableSortBy: true,
                    },
                    {
                        id: 'received_date',
                        Header: 'Actual Received Date',
                        accessor: (order) => {
                            const warehouse = aggregateEvents(order.wh_events);
                            return new Date(warehouse.received_date);
                        },
                        Cell: ({ value }) => {
                            if (isNaN(value) || !value?.getTime()) {
                                return '-';
                            }

                            return dateShort.format(value);
                        },
                        width: 200,
                        disableSortBy: true,
                    },
                ],
            },
            {
                sticky: 'right',
                Header: 'Actions',
                id: 'actions-group',
                columns: [
                    {
                        id: 'warehousestatus',
                        Header: 'Warehousing Status',
                        accessor: (order) => {
                            const warehouse = aggregateEvents(order.wh_events);
                            return warehouse.status;
                        },
                        Cell: ({ row: order, value }) => {
                            let fragment;
                            let label;
                            let icon = null;
                            switch (value) {
                                case 'RECEIVED': {
                                    label = 'Received';
                                    fragment = css`
                                        color: #59b863;
                                    `;
                                    break;
                                }
                                case 'NOT_DELIVERED': {
                                    label = 'Not Received';
                                    fragment = css`
                                        color: #2b2b2b;
                                    `;
                                    break;
                                }
                                default:
                                    label = '-';
                            }

                            return (
                                <div
                                    css={css`
                                        display: flex;
                                        align-items: center;
                                        white-space: nowrap;
                                    `}
                                >
                                    <BodyCellText
                                        css={css`
                                            font-weight: 700;
                                            ${fragment};
                                        `}
                                    >
                                        {label}
                                    </BodyCellText>
                                    {icon}
                                </div>
                            );
                        },
                        width: 200,
                    },
                    {
                        Header: 'Actions',
                        id: 'actionMenu',
                        width: 100,
                        Cell: ({ row, callbacks }) => {
                            const menuOptions = useMemo(
                                () => [
                                    {
                                        display: 'Send to Planning',
                                        callback: () => callbacks.onClickPlan(row.original),
                                        disabled: false,
                                    },
                                    {
                                        display: row.original.oms ? 'Edit Order' : 'Cannot edit claimed order.',
                                        callback: () => callbacks.onEditOrder(row.original),
                                        disabled: !row.original.oms,
                                    },
                                    ...(marketplaceEnabled
                                        ? [
                                              {
                                                  display: 'Send to Onward',
                                                  callback: () => callbacks.onClickOnward(row.original),
                                                  disabled: !row.original.oms,
                                              },
                                          ]
                                        : []),
                                    {
                                        display: CROSS_DOCKABLE_STATES.includes(row.original.event_state)
                                            ? 'Cross-dock'
                                            : 'Already Cross-docked',
                                        callback: () => callbacks.onClickCrossdock(row.original),
                                        disabled: !CROSS_DOCKABLE_STATES.includes(row.original.event_state),
                                    },
                                ],
                                [row.original, marketplaceEnabled, user_id, callbacks]
                            );

                            const [anchorEl, setAnchorEl] = useState(null);

                            const openMenu = (e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                setAnchorEl(e.currentTarget);
                            };

                            const closeMenu = (e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                setAnchorEl(null);
                            };

                            return (
                                <>
                                    <IconButton size="small" onClick={openMenu}>
                                        <MoreVert
                                            css={css`
                                                color: ${colors.greens.primary};
                                            `}
                                        />
                                    </IconButton>
                                    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={closeMenu}>
                                        {menuOptions.map((option, i) => (
                                            <MenuItem
                                                key={i}
                                                onClick={(e) => {
                                                    option.callback();
                                                    closeMenu(e);
                                                }}
                                                disabled={option.disabled}
                                            >
                                                {option.display}
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </>
                            );
                        },
                    },
                ],
            },
        ];
    }, [editEnabled, marketplaceEnabled]);
};
