import NavResponsiveModal, {
    ModalActions,
    ModalContent,
    ModalHeader,
} from '@/components/Navigation/NavResponsiveModal';
import { PrimaryButton, SecondaryButton } from '@/styles/blocks';
import { Grid } from '@material-ui/core';
import React, { useEffect, useState, useMemo, useRef } from 'react';
import { css } from '@emotion/react';
import { OnwardCheckbox } from '@/components/ShipmentForm/blocks';
import { StickyModalActions } from '@/styles/blocks';
import { deliveryTypeLabels, getItemQR, getPalletQR } from '../utils';
import { useReactToPrint } from 'react-to-print';
import { captureException } from '@sentry/react';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import printJS from 'print-js';

const getShipperName = (order) => {
    return order?.order_shipper?.business_name || 'N/A';
};

const getCarrierName = (order) => {
    return order?.order_carrier?.business_name || 'ONWARD';
};

const labelRow = (name, rowObject, onChange, isFirst) => {
    let description;
    let palletName;
    if (rowObject.isPallet) {
        palletName = rowObject.pallet_name;
        description = `${rowObject.items[0].order?.po_number || ''} - `;
        description += rowObject.items
            .map((i) => {
                return `${i.description || 'No Desc. Given'}`;
            })
            .join(', ');
    } else {
        description = rowObject.items.description;
    }

    return (
        <>
            <hr
                key={`${name}-key-hr`}
                css={css`
                    margin-top: 0;
                    margin-bottom: 0;
                `}
            />

            <Grid
                key={`${name}-key`}
                item
                container
                direction="row"
                css={css`
                    flex-wrap: nowrap;
                    align-items: center;
                    margin-top: 1.5rem;
                    margin-bottom: 1.5rem;
                `}
            >
                <Grid item>
                    <OnwardCheckbox checked={rowObject.checked} onChange={(e) => onChange(name)} />
                </Grid>

                <Grid
                    item
                    container
                    css={css`
                        margin-left: 15px;
                        flex-direction: column;
                    `}
                >
                    <Grid
                        item
                        css={css`
                            font-weight: 700;
                        `}
                    >
                        {palletName || name}
                    </Grid>
                    <Grid item>{description}</Grid>
                </Grid>
            </Grid>
        </>
    );
};

const itemLabelBody = (item, qrSvg, currCount, maxCount) => {
    return (
        <div
            key={`${item.item_id}-key`}
            className="label-border"
            css={css`
                page-break-inside: avoid;
                break-inside: avoid;
            `}
            style={{
                margin: 0,
                padding: 0,
                border: '1px solid black',
                height: '576px',
                width: '384px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                backgroundColor: 'white',
                pageBreakAfter: 'always',
                pageBreakInside: 'avoid',
                pageBreakBefore: 'avoid',
            }}
        >
            <div
                className="header no-margin"
                style={{
                    backgroundColor: 'black',
                    color: 'white',
                    textAlign: 'center',
                    WebkitPrintColorAdjust: 'exact',
                    margin: '0',
                }}
            >
                <div
                    className="header no-margin"
                    style={{
                        backgroundColor: 'black',
                        color: 'white',
                        textAlign: 'center',
                        WebkitPrintColorAdjust: 'exact',
                        fontSize: '22px',
                        fontWeight: 700,
                        margin: '8px 0px 8px 0px',
                    }}
                >
                    {getCarrierName(item?.order)}
                </div>
            </div>
            <div
                className="info"
                css={css`
                    margin-top: 4px;
                    margin-bottom: 4px;
                    padding-left: 15px;
                `}
                style={{ textAlign: 'left' }}
            >
                <div
                    className="less-margin"
                    css={css`
                        font-size: 18px;
                        font-weight: 700;
                    `}
                >
                    PO: {item.order?.po_number}
                </div>
                <div
                    className="less-margin"
                    css={css`
                        font-size: 18px;
                        font-weight: 700;
                    `}
                >
                    Customer: {item.order?.dropoff_name ? item.order.dropoff_name : 'N/A'}
                </div>
                <div
                    className="less-margin"
                    css={css`
                        font-size: 18px;
                        font-weight: 700;
                    `}
                >
                    Address: {item.order?.dropoff_address}
                </div>
                <div
                    className="less-margin"
                    css={css`
                        font-size: 18px;
                        font-weight: 700;
                    `}
                >
                    Shipper: {getShipperName(item?.order)}
                </div>
            </div>
            <div
                css={css`
                    border-bottom: 4px solid black;
                `}
            ></div>
            <div
                className="info-grid horizontal-padding"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '3px',
                    paddingLeft: '15px',
                    paddingRight: '15px',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginTop: '4px',
                    }}
                >
                    <div
                        css={css`
                            font-size: 14px !important;
                            font-weight: 600;
                            padding: 0 !important;
                        `}
                        className="info-grid-item"
                        style={{ width: '100%' }}
                    >
                        Quantity: {maxCount}
                    </div>
                </div>
                {item.description && (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            marginBottom: '4px',
                        }}
                    >
                        <div
                            css={css`
                                font-size: 14px !important;
                                font-weight: 600;
                                padding: 0 !important;
                            `}
                            className="info-grid-item"
                            style={{ width: '100%' }}
                        >
                            Description: {item.description}
                        </div>
                    </div>
                )}
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom: '4px',
                    }}
                >
                    <div
                        css={css`
                            font-size: 14px !important;
                            font-weight: 600;
                            padding: 0 !important;
                        `}
                        className="info-grid-item"
                        style={{ width: '100%' }}
                    >
                        SKU: {item.sku ? item.sku : 'N/A'}
                    </div>
                </div>
            </div>
            <div
                css={css`
                    border-bottom: 4px solid black;
                `}
            ></div>
            <div
                css={css`
                    margin-top: 3px !important;
                    margin-bottom: 3px !important;
                    padding: 0 15px 0 15px !important;
                    font-weight: 700;
                    font-size: 18px;
                `}
            >
                Additional Information
            </div>
            <div
                className="info-grid horizontal-padding"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '3px',
                    paddingLeft: '15px',
                    paddingRight: '15px',
                    pageBreakAfter: 'avoid',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                    }}
                >
                    <div
                        css={css`
                            font-size: 14px !important;
                            font-weight: 600;
                            margin: 0 !important;
                            padding: 0 !important;
                        `}
                        className="info-grid-item"
                        style={{ width: '50%' }}
                    >
                        MFG: {item.order?.manufacturer ? item.order.manufacturer : 'N/A'}
                    </div>
                    <div
                        css={css`
                            font-size: 14px !important;
                            font-weight: 600;
                            margin: 0 !important;
                            padding: 0 !important;
                        `}
                        className="info-grid-item"
                        style={{ width: '50%' }}
                    >
                        Dropoff type: {deliveryTypeLabels[item?.order?.dropoff_location_type] || '--'}
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                    }}
                >
                    <div
                        css={css`
                            font-size: 14px !important;
                            font-weight: 600;
                            margin: 0 !important;
                            padding: 0 !important;
                        `}
                        className="info-grid-item"
                        style={{ width: '100%' }}
                    >
                        Order: {item.order?.order_number}
                    </div>
                </div>
            </div>
            <div
                className="svg-div"
                css={css`
                    page-break-inside: avoid;
                    break-inside: avoid;
                `}
                style={{
                    pageBreakInside: 'avoid',
                    pageBreakBefore: 'avoid',
                    height: '100%',
                    width: '100%',
                    textAlign: 'center',
                    overflow: 'auto',
                    objectFit: 'scale-down',
                }}
            >
                <img
                    css={css`
                    height: 100%;
                    overflow: 'auto',
                    page-break-inside: avoid;
                    break-inside: avoid;
                `}
                    style={{
                        pageBreakInside: 'avoid',
                        pageBreakBefore: 'avoid',
                        objectFit: 'scale-down',
                    }}
                    src={`data:image/svg+xml;utf8,${encodeURIComponent(qrSvg)}`}
                />
            </div>
        </div>
    );
};

const palletLabelbody = (pallet, qrSvg) => {
    return (
        <div
            className="label-border"
            style={{
                margin: 0,
                padding: 0,
                border: '1px solid black',
                height: '576px',
                width: '384px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                backgroundColor: 'white',
            }}
        >
            <div
                className="header no-margin"
                style={{
                    backgroundColor: 'black',
                    color: 'white',
                    textAlign: 'center',
                    WebkitPrintColorAdjust: 'exact',
                    fontSize: '22px',
                    fontWeight: 700,
                    margin: '0px 0px 8px 0px',
                }}
            >
                {getCarrierName(pallet?.items[0]?.order)}
            </div>
            <div
                className="info-grid horizontal-padding"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '3px',
                    paddingLeft: '15px',
                    paddingRight: '15px',
                }}
            >
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '4px' }}>
                    <div
                        css={css`
                            font-size: 14px !important;
                            font-weight: 600;
                            padding: 0 !important;
                            width: 30%;
                        `}
                        className="info-grid-item"
                    >
                        PO:
                    </div>
                    <div
                        css={css`
                            font-size: 14px !important;
                            font-weight: 600;
                            padding: 0 !important;
                        `}
                        className="info-grid-item"
                        style={{ width: '100%' }}
                    >
                        {pallet.items[0].order?.po_number}
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div
                        css={css`
                            font-size: 14px !important;
                            font-weight: 600;
                            padding: 0 !important;
                            width: 30%;
                        `}
                        className="info-grid-item"
                    >
                        Customer:
                    </div>
                    <div
                        css={css`
                            font-size: 14px !important;
                            font-weight: 600;
                            padding: 0 !important;
                        `}
                        className="info-grid-item"
                        style={{ width: '100%' }}
                    >
                        {pallet.items[0].order?.dropoff_name ? pallet.items[0].order.dropoff_name : 'N/A'}
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '4px' }}>
                    <div
                        css={css`
                            font-size: 14px !important;
                            font-weight: 600;
                            padding: 0 !important;
                            width: 30%;
                        `}
                        className="info-grid-item"
                    >
                        Address:
                    </div>
                    <div
                        css={css`
                            font-size: 14px !important;
                            font-weight: 600;
                            padding: 0 !important;
                        `}
                        className="info-grid-item"
                        style={{ width: '100%' }}
                    >
                        {pallet.items[0].order?.dropoff_address}
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '4px' }}>
                    <div
                        css={css`
                            font-size: 14px !important;
                            font-weight: 600;
                            padding: 0 !important;
                            width: 30%;
                        `}
                        className="info-grid-item"
                    >
                        Shipper:
                    </div>
                    <div
                        css={css`
                            font-size: 14px !important;
                            font-weight: 600;
                            padding: 0 !important;
                        `}
                        className="info-grid-item"
                        style={{ width: '100%' }}
                    >
                        {getShipperName(pallet?.items[0]?.order)}
                    </div>
                </div>
            </div>
            <div
                css={css`
                    border-bottom: 4px solid black;
                `}
            ></div>
            <div
                className="info-grid horizontal-padding"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '3px',
                    paddingLeft: '15px',
                    paddingRight: '15px',
                    marginTop: '4px',
                }}
            >
                <div
                    className="less-margin"
                    css={css`
                        font-size: 14px !important;
                        font-weight: 600;
                        margin: 0 !important;
                        padding: 0 !important;
                    `}
                >
                    Pallet: {pallet?.pallet_name ? pallet.pallet_name : pallet.pallet_number}
                </div>
                <div
                    className="less-margin"
                    css={css`
                        font-size: 14px !important;
                        font-weight: 600;
                        margin: 0 !important;
                        padding: 0 !important;
                    `}
                >
                    Order: {pallet?.items[0]?.order.order_number}
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div
                        css={css`
                            font-size: 14px !important;
                            font-weight: 600;
                            margin: 0 !important;
                            padding: 0 !important;
                        `}
                        className="info-grid-item"
                        style={{ width: '100%' }}
                    >
                        Items: {pallet.items.length}
                    </div>
                </div>
                <div
                    css={css`
                        margin-bottom: 10px;
                        display: flex;
                        flex-direction: column;
                        flex-shrink: 1;
                        overflow: hidden;
                        font-size: 14px !important;
                        font-weight: 600;
                        margin: 0 !important;
                        padding: 0 !important;
                    `}
                >
                    SKU(s):
                    {pallet.items.map((item, index) => (
                        <div
                            key={index}
                            className="info-grid-item"
                            css={css`
                                font-size: 14px !important;
                                font-weight: 600;
                                margin: 0 !important;
                                padding: 0 !important;
                                line-height: normal;
                            `}
                        >
                            {item?.description ? item.description : 'No Desc. Given'}
                            {item?.sku ? ` - ${item.sku}` : ''}
                        </div>
                    ))}
                </div>
            </div>
            <div
                className="svg-div"
                css={css`
                    page-break-inside: avoid;
                    break-inside: avoid;
                `}
                style={{
                    pageBreakInside: 'avoid',
                    pageBreakBefore: 'avoid',
                    height: '100%',
                    width: '100%',
                    textAlign: 'center',
                    overflow: 'auto',
                    objectFit: 'scale-down',
                }}
            >
                <img
                    css={css`
                        height: 100%;
                        overflow: 'auto';
                        page-break-inside: avoid;
                        break-inside: avoid;
                    `}
                    style={{
                        pageBreakInside: 'avoid',
                        pageBreakBefore: 'avoid',
                        objectFit: 'scale-down',
                    }}
                    src={`data:image/svg+xml;utf8,${encodeURIComponent(qrSvg)}`}
                />
            </div>
        </div>
    );
};

const PrintLabelsModal = ({ printingManifest, loading, callbacks }) => {
    const [manifestItems, setManifestItems] = useState({});
    const [countMapping, setCountMapping] = useState({});
    const [printing, setPrinting] = useState(false);
    const componentRef = useRef();

    useEffect(() => {
        const orders = printingManifest?.orders || [];
        const items = orders.length
            ? orders.reduce((accum, o) => { 
                    const enrichedManifestItems = (o?.manifestItems || []).map((manifestItem) => {
                        return {
                            ...manifestItem,
                            order: o,
                        }
                    })
                  return [...accum, ...enrichedManifestItems];
              }, [])
            : printingManifest?.items?.map((item) => item.item);

        const poCount = {};
        const itemCount = {};
        const groupedItems = (items || []).reduce((itemsObject, i) => {
            const isPallet = i?.pallet;
            if (isPallet) {
                const palletArray = [...(itemsObject?.[`Pallet ${i.pallet.pallet_number}`]?.items || [])];
                palletArray.push(i);
                itemsObject[`Pallet ${i.pallet.pallet_number}`] = {
                    items: palletArray,
                    isPallet: true,
                    checked: false,
                    pallet_name: i.pallet?.pallet_name,
                };
            } else {
                let quantityCount = itemCount[i.order_id] || 0;
                itemCount[i.item_id] = quantityCount;
                quantityCount += i?.item_piece_count || i?.quantity || 1;
                itemCount[i.order_id] = quantityCount;

                let count = poCount[i.order?.po_number] || 0;
                count++;
                itemsObject[`Item ${i.order?.po_number}-${count}`] = {
                    items: i,
                    isPallet: false,
                    checked: false,
                };
                poCount[i.order?.po_number] = count;
            }
            return itemsObject;
        }, {});

        setManifestItems(groupedItems);
        setCountMapping(itemCount);
    }, [printingManifest]);

    const cancel = () => {
        callbacks.printLabels(null);
    };

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        onPrintError: (error) => {
            captureException(error);
            console.error(error);
            setPrinting(false);
        },
        print: async (printIframe) => {
            try {
                const document = printIframe.contentDocument;
                let hmtlTags = document.getElementsByTagName('html');
                let html = hmtlTags?.[0];
                if (html) {
                    const body = html.getElementsByTagName('body')?.[0];
                    const pageElements = body.childNodes[0].childNodes;

                    let doc = new jsPDF({
                        orientation: 'portrait',
                        unit: 'in',
                        format: [4, 6],
                    });

                    const pages = [];
                    pageElements.forEach((page) => pages.push(page));

                    await pages.reduce(async (acc, page, index) => {
                        await acc;
                        if (index > 0) {
                            doc.addPage();
                        }

                        let canvas = await html2canvas(page, {
                            height: 576,
                            width: 384,
                        });
                        let img = canvas.toDataURL('image/jpeg', 1.0);
                        doc.addImage(img, 'JPEG', 0, 0, 4, 6);
                    }, undefined);

                    const pdfOutput = doc.output('blob');
                    const pdfOutputUrl = URL.createObjectURL(pdfOutput);
                    printJS(pdfOutputUrl);
                }
            } catch (err) {
                console.log(err);
            }
            setPrinting(false);
        },
    });

    const printSelected = () => {
        setPrinting(true);
        handlePrint();
    };

    const anySelected = useMemo(() => {
        return Object.entries(manifestItems).some(([key, i]) => {
            return i.checked;
        });
    }, [manifestItems]);

    const handleCheck = (name) => {
        setManifestItems((prev) => {
            const prevObject = { ...prev };
            prevObject[name].checked = !prevObject[name].checked;
            return prevObject;
        });
    };

    const handleCheckAll = () => {
        if (Object.values(manifestItems).every((i) => i.checked)) {
            const itemsClone = {
                ...manifestItems,
            };
            for (const key of Object.keys(itemsClone)) {
                itemsClone[key].checked = false;
            }
            setManifestItems(itemsClone);
        } else {
            const itemsClone = {
                ...manifestItems,
            };
            for (const key of Object.keys(itemsClone)) {
                itemsClone[key].checked = true;
            }
            setManifestItems(itemsClone);
        }
    };

    const parsedItems = useMemo(() => {
        const [itemsToPrint, palletsToPrint] = Object.entries(manifestItems).reduce(
            (accum, [key, i]) => {
                if (!i.checked) {
                    return accum;
                }
                if (i.isPallet) {
                    const accumPallets = [...accum[1]];
                    accumPallets.push({
                        ...i,
                        ...i.items[0].pallet,
                    });
                    return [[...accum[0]], accumPallets];
                } else {
                    const accumItems = [...accum[0]];
                    accumItems.push(i.items);
                    return [accumItems, [...accum[1]]];
                }
            },
            [[], []]
        );

        const palletsWithQrCodes = palletsToPrint.map((pallet) => {
            const palletQrCode = getPalletQR(pallet);
            const palletClone = {
                ...pallet,
                qrCode: palletQrCode,
            };
            return palletClone;
        });
        let itemQrCodes = [];
        const itemsWithQrCodes = itemsToPrint.map((item) => {
            itemQrCodes.push(getItemQR(item));
            const itemQrCode = getItemQR(item);
            const itemClone = {
                ...item,
                qrCode: itemQrCode,
            };
            return itemClone;
        });

        return [itemsWithQrCodes, palletsWithQrCodes];
    }, [manifestItems]);

    return (
        <>
            <NavResponsiveModal open={!!printingManifest} onClose={cancel}>
                <ModalHeader title={'Print Labels'} onClose={cancel} />
                <ModalContent
                    css={css`
                        width: 400px;
                    `}
                >
                    <Grid
                        container
                        direction="column"
                        css={css`
                            height: 100%;
                            flex-wrap: nowrap;
                        `}
                    >
                        <Grid
                            item
                            container
                            direction="row"
                            css={css`
                                flex-wrap: nowrap;
                                align-items: center;
                                margin-bottom: 1.5rem;
                            `}
                        >
                            <Grid item>
                                <OnwardCheckbox
                                    checked={Object.values(manifestItems).every((i) => i.checked)}
                                    onChange={handleCheckAll}
                                />
                            </Grid>

                            <Grid
                                item
                                container
                                css={css`
                                    margin-left: 15px;
                                    flex-direction: column;
                                `}
                            >
                                <Grid
                                    item
                                    css={css`
                                        font-weight: 700;
                                    `}
                                >
                                    Select All
                                </Grid>
                            </Grid>
                        </Grid>
                        {Object.keys(manifestItems).map((key, idx) =>
                            labelRow(key, manifestItems[key], handleCheck, idx === 0)
                        )}
                    </Grid>
                </ModalContent>
                <StickyModalActions border>
                    <SecondaryButton onClick={cancel}>Cancel</SecondaryButton>
                    <PrimaryButton disabled={loading.updateLoading || !anySelected || printing} onClick={printSelected}>
                        Print Selected
                    </PrimaryButton>
                </StickyModalActions>
            </NavResponsiveModal>
            <div style={{ display: 'none' }}>
                <div ref={componentRef}>
                    {parsedItems?.[0]?.reduce((accum, i) => {
                        let maxCount = countMapping[i.order_id];
                        // let maxCount = 1;
                        let countStart = countMapping[i.item_id];
                        let numOfItems = i?.item_piece_count || i?.quantity || 1;

                        // for (let idx = 1; idx <= numOfItems; idx++) {
                        //     accum.push(itemLabelBody(i, i.qrCode, countStart + idx, maxCount));
                        // }
                        accum.push(itemLabelBody(i, i.qrCode, countStart, maxCount));
                        return accum;
                    }, [])}

                    {parsedItems?.[1]?.map((i) => {
                        return palletLabelbody(i, i.qrCode);
                    })}
                </div>
            </div>
        </>
    );
};

export default PrintLabelsModal;
