import React, { useMemo, useState, useEffect, useCallback } from 'react';
import zipcode_to_timezone from 'zipcode-to-timezone';
import _, { pick } from 'lodash';
import { useClientUser } from '@/hooks';
import { useEditModalDynamicFields } from '@/components/ShipmentForm/hooks/modal';
import { price } from '@/components/ShipmentForm/utilities/processOrders';
import { genAttributes, EXCEPTION_TYPES } from '@onward-delivery/core';

import {
    ACTIONS,
    CLONE_ITEM,
    CLONE_ORDER,
    REQUIRES_CARRIER_INSTRUCTION,
    INSTRUCTIONS,
    CLONE_MM_FIELDS,
} from './constants';

export const useExceptionActions = ({ order, omitted, items, exception, isAdmin, isShipper, isCarrier }) => {
    return useMemo(() => {
        if (!exception) {
            return [];
        }

        const state = order?.wh_events?.[order.wh_events.length - 1]?.transition?.current;
        const canReschedule =
            !order.read_only &&
            (['START', 'RECEIVING', 'CD_PENDING_PO', 'WAREHOUSE_RETURN'].includes(state) || order.oms);
        const canReassign = !order.oms;
        const canSplit = omitted?.itemsByOrderId?.length > 0 && items?.length > 0;
        const canOmit = omitted?.itemsByOrderId?.length > 0;
        const itemKept = !!exception?.item_kept;

        switch (exception.type) {
            case EXCEPTION_TYPES.SHORTAGE: {
                return [
                    ...(canReschedule && !canSplit ? [ACTIONS.RESCHEDULE] : []),
                    ...(!itemKept && canOmit ? [ACTIONS.OMIT_ITEM] : []),
                    ...(canSplit ? [ACTIONS.SPLIT_ORDERS] : []),
                    ACTIONS.CANCEL_ORDER,
                    ...(isAdmin ? [ACTIONS.RESOLVE_NOOP] : []),
                ];
            }
            case EXCEPTION_TYPES.DAMAGED: {
                return [
                    ...(itemKept ? [ACTIONS.CREATE_EXCHANGE] : []),
                    ...(canReschedule ? [ACTIONS.RESCHEDULE] : []),
                    ...(!itemKept && canOmit ? [ACTIONS.OMIT_ITEM] : []),
                    ...(!itemKept && canSplit ? [ACTIONS.SPLIT_ORDERS] : []),
                    ACTIONS.CREATE_RETURN,
                    ACTIONS.CANCEL_ORDER,
                    ACTIONS.RESOLVE_NOOP,
                ];
            }
            case EXCEPTION_TYPES.OVERAGE: {
                return [ACTIONS.RESOLVE_NOOP];
            }
            case EXCEPTION_TYPES.REFUSAL: {
                return [
                    ...(canReschedule && !canSplit ? [ACTIONS.RESCHEDULE] : []),
                    ...(!itemKept && canOmit ? [ACTIONS.OMIT_ITEM] : []),
                    ...(canSplit ? [ACTIONS.SPLIT_ORDERS] : []),
                    ACTIONS.CANCEL_ORDER,
                    ACTIONS.RESOLVE_NOOP,
                ];
            }
            case EXCEPTION_TYPES.CUSTOMER_CANCELLATION:
            case EXCEPTION_TYPES.ATTEMPTED_DELIVERY: {
                return [...(canReschedule ? [ACTIONS.RESCHEDULE] : []), ACTIONS.CANCEL_ORDER, ACTIONS.RESOLVE_NOOP];
            }
            case EXCEPTION_TYPES.CARRIER_CANCELLATION: {
                return [
                    ...(canReschedule ? [ACTIONS.RESCHEDULE] : []),
                    ...(canReassign ? [ACTIONS.CHANGE_CARRIER] : []),
                    ACTIONS.RESOLVE_NOOP,
                    ACTIONS.CREATE_RETURN,
                ];
            }
            case EXCEPTION_TYPES.WEATHER:
            case EXCEPTION_TYPES.NO_ROOM: {
                return [...(canReschedule ? [ACTIONS.RESCHEDULE] : []), ACTIONS.RESOLVE_NOOP];
            }
            default:
                return [ACTIONS.RESOLVE_NOOP];
        }
    }, [order, items, exception, isAdmin, isShipper, isCarrier]);
};

export const useExceptionInstructions = ({ order, exception, resolution }) => {
    return useMemo(() => {
        if (!exception) {
            return [];
        }

        switch (exception.type) {
            case EXCEPTION_TYPES.DAMAGED: {
                return [
                    INSTRUCTIONS.RETURN_ITEMS,
                    INSTRUCTIONS.SENDER_PICKUP,
                    INSTRUCTIONS.DISPOSE_ITEMS,
                    INSTRUCTIONS.NOOP,
                ];
            }
            default:
                return [INSTRUCTIONS.RETURN_ITEMS, INSTRUCTIONS.SENDER_PICKUP, INSTRUCTIONS.DISPOSE_ITEMS];
        }
    }, [order, exception, resolution]);
};

export const useExceptionState = ({ order, items, exception }) => {
    const { user_id, roles } = useClientUser();
    const [resolution, setAction] = useState(null);
    const [instruction, setInstruction] = useState(null);
    const [updates, setUpdates] = useState({});
    const [omittedUpdates, setOmittedUpdates] = useState({});
    const [carrierClearedUpdates, setCarrierClearedUpdates] = useState({});
    const [duplicateUpdates, setDuplicateUpdates] = useState({
        haulaway_items: [],
        known_availability: false,
        source_form: 'MANUAL',
    });
    const [exchangeUpdates, setExchangedUpdates] = useState({
        haulaway_items: [],
        known_availability: false,
        source_form: 'MANUAL',
    });

    const isAdmin = !!roles['ONWARD_ADMIN'];
    const isShipper = user_id === order?.shipper_id;
    const isCarrier = user_id === order?.carrier_id;

    useEffect(() => {
        setAction(null);
        setInstruction(exception?.instruction);
    }, [exception]);

    const omitted = useMemo(() => {
        if (!order) {
            return {};
        }
        const toOmit = Object.fromEntries((items || []).map((item) => [item.item_id, true]));
        const remaining = (order?.itemsByOrderId || []).filter((item) => !toOmit[item.item_id]);

        return {
            ...order,
            ...omittedUpdates,
            itemsByOrderId: remaining,
            ...(remaining.length === 0 ? { order_status: 'cancelled' } : {}),
        };
    }, [order, items, omittedUpdates]);

    const itemDelivered = useMemo(() => {
        if (!order) {
            return {};
        }

        return {
            ...pick(order, CLONE_ORDER),
            ...duplicateUpdates,
            itemsByOrderId: (items || []).map((item) => {
                return pick(item, CLONE_ITEM);
            }),
        };
    }, [order, items, duplicateUpdates]);

    const itemExchanged = useMemo(() => {
        if (!order) {
            return {};
        }

        return {
            ...pick(order, CLONE_ORDER),
            ...exchangeUpdates,
            itemsByOrderId: [
                ...(items || []).map((item) => {
                    return pick(item, CLONE_ITEM);
                }),
                ...(items || []).map((item) => {
                    return { ...pick(item, CLONE_ITEM), is_return: !item.is_return };
                }),
            ],
        };
    }, [order, items, exchangeUpdates]);

    const itemReturned = useMemo(() => {
        if (!order) {
            return {};
        }

        return {
            ...pick(order, [...CLONE_ORDER, ...CLONE_MM_FIELDS]),
            ...duplicateUpdates,
            order_type: 'return',
            order_number: `${order.order_number}-R`,
            itemsByOrderId: [
                ...(items || []).map((item) => {
                    return { ...pick(item, CLONE_ITEM), is_return: true };
                }),
            ],
        };
    }, [order, items, duplicateUpdates]);

    const carrierCleared = useMemo(() => {
        if (!order) {
            return {};
        }

        const isWarehouse = (order?.wh_events || []).some(({ __typename, exception, transition, ...rest }) => {
            return ['START:RECEIVING'].includes(rest.action);
        });

        return {
            ...pick(order, CLONE_ORDER),
            ...carrierClearedUpdates,
            itemsByOrderId: [
                ...(order.itemsByOrderId || []).map((item) => {
                    return pick(item, CLONE_ITEM);
                }),
            ],
            carrier_id: null,
            ...(isWarehouse
                ? {
                      is_middle_mile: true,
                  }
                : {}),
            source_form: 'MANUAL',
            po_duplicate_override: true,
        };
    }, [order, items]);

    useEditModalDynamicFields(omitted, setOmittedUpdates);
    useEditModalDynamicFields(itemDelivered, setDuplicateUpdates);
    useEditModalDynamicFields(itemExchanged, setExchangedUpdates);
    useEditModalDynamicFields(carrierCleared, setCarrierClearedUpdates);

    const tz = useMemo(() => {
        if (!order) {
            return 'America/New_York';
        }

        const { zip } = genAttributes(order);
        return zipcode_to_timezone.lookup(order[zip]) || 'America/New_York';
    }, [order]);

    const isValid = useMemo(() => {
        const validInstruction = !REQUIRES_CARRIER_INSTRUCTION.includes(exception?.type) || instruction;

        switch (resolution) {
            case ACTIONS.CHANGE_CARRIER:
            case ACTIONS.SPLIT_ORDERS:
            case ACTIONS.CREATE_EXCHANGE:
            case ACTIONS.CREATE_RETURN:
            case ACTIONS.OMIT_ITEM:
            case ACTIONS.CANCEL_ORDER:
            case ACTIONS.RESOLVE_NOOP:
                return validInstruction;
            case ACTIONS.RESCHEDULE:
                return validInstruction;
            default:
                return false;
        }
    }, [instruction, resolution, updates, exception]);

    return [
        {
            instruction,
            isAdmin,
            isCarrier,
            isShipper,
            isValid,
            itemDelivered,
            itemExchanged,
            itemReturned,
            omitted,
            carrierCleared,
            resolution,
            tz,
            updates,
        },
        {
            setAction: (action) => {
                setAction(action);
                setUpdates({});
            },
            setUpdates,
            setInstruction,
        },
    ];
};

export const useExceptionCallbacks = ({
    callbacks,
    exception,
    instruction,
    itemDelivered,
    itemExchanged,
    itemReturned,
    carrierCleared,
    items,
    omitted,
    order,
    resolution,
    updates,
    user_id,
}) => {
    const resolve = useCallback(() => {
        const last = order?.wh_events?.[order.wh_events.length - 1];
        const status =
            !order.oms && REQUIRES_CARRIER_INSTRUCTION.includes(exception.type) ? 'PENDING_CONFIRMATION' : 'RESOLVED';

        switch (resolution) {
            case ACTIONS.SPLIT_ORDERS: {
                callbacks.setInflight(true);
                return price([omitted]).then(([, priced]) => {
                    const updates = Object.fromEntries(
                        ['order_type', 'shipper_rate', 'carrier_rate', 'price_breakdown', 'order_revenue'].map(
                            (attr) => [attr, priced[attr]]
                        )
                    );

                    callbacks.onResolve({
                        toSubmit: itemDelivered,
                        order: {
                            ...updates,
                            order_id: order.order_id,
                        },
                        exception: {
                            exception_id: exception.exception_id,
                            status,
                            resolution: ACTIONS.SPLIT_ORDERS,
                            instruction,
                        },
                        items,
                    });

                    callbacks.setInflight(false);
                });
            }
            case ACTIONS.CREATE_EXCHANGE: {
                callbacks.setInflight(true);
                return callbacks
                    .onResolve({
                        toSubmit: itemExchanged,
                        order: {
                            order_id: order.order_id,
                        },
                        exception: {
                            exception_id: exception.exception_id,
                            status,
                            resolution: ACTIONS.CREATE_EXCHANGE,
                            instruction,
                        },
                        items,
                    })
                    .then(() => {
                        callbacks.setInflight(false);
                    });
            }
            case ACTIONS.CREATE_RETURN: {
                callbacks.setInflight(true);
                return callbacks
                    .onResolve({
                        toSubmit: itemReturned,
                        order: {
                            order_id: order.order_id,
                        },
                        exception: {
                            exception_id: exception.exception_id,
                            status,
                            resolution: ACTIONS.CREATE_RETURN,
                            instruction,
                        },
                    })
                    .then(() => {
                        callbacks.setInflight(false);
                    });
            }
            case ACTIONS.CHANGE_CARRIER: {
                return callbacks.onResolve({
                    toSubmit: carrierCleared,
                    order: {
                        order_id: order.order_id,
                    },
                    exception: {
                        exception_id: exception.exception_id,
                        status,
                        resolution: ACTIONS.CHANGE_CARRIER,
                        instruction,
                    },
                });
            }
            case ACTIONS.OMIT_ITEM: {
                callbacks.setInflight(true);
                return price([omitted]).then(([, priced]) => {
                    const updates = Object.fromEntries(
                        ['order_type', 'shipper_rate', 'carrier_rate', 'price_breakdown', 'order_revenue'].map(
                            (attr) => [attr, priced[attr]]
                        )
                    );

                    callbacks.onResolve({
                        order: {
                            ...updates,
                            order_id: order.order_id,
                        },
                        exception: {
                            exception_id: exception.exception_id,
                            status,
                            resolution: ACTIONS.OMIT_ITEM,
                            instruction,
                        },
                        items,
                    });

                    callbacks.setInflight(false);
                });
            }
            case ACTIONS.CANCEL_ORDER: {
                const { route_rev_action, custom_charge, recalc_route } = updates;
                return callbacks.onResolve({
                    order: {
                        order_id: order.order_id,
                        order_status: user_id === order.carrier_id ? 'rejected' : 'cancelled',
                    },
                    exception: {
                        exception_id: exception.exception_id,
                        route_id: exception.route_id,
                        status,
                        resolution: ACTIONS.CANCEL_ORDER,
                        instruction,
                        route_rev_action,
                        custom_charge,
                        recalc_route,
                    },
                });
            }
            case ACTIONS.RESOLVE_NOOP: {
                const { route_rev_action, custom_charge, recalc_route } = updates;
                return callbacks.onResolve({
                    exception: {
                        exception_id: exception.exception_id,
                        route_id: exception.route_id,
                        status,
                        resolution: ACTIONS.RESOLVE_NOOP,
                        instruction,
                        route_rev_action,
                        custom_charge,
                        recalc_route,
                    },
                });
            }
            case ACTIONS.RESCHEDULE: {
                const { pickup_date, delivery_date, route_rev_action, custom_charge, recalc_route } = updates;

                return callbacks.onResolve({
                    order: {
                        order_id: order.order_id,
                        pickup_date: pickup_date || null,
                        delivery_date: delivery_date || null,
                        del_window_start: null,
                        del_window_end: null,
                        delivery_time_confirmed: null,
                    },
                    exception: {
                        exception_id: exception.exception_id,
                        route_id: exception.route_id,
                        status,
                        resolution: ACTIONS.RESCHEDULE,
                        instruction,
                        order_id: order.order_id,
                        route_rev_action,
                        custom_charge,
                        recalc_route,
                    },
                    ...(last
                        ? {
                              event: {
                                  est_pickup_date: pickup_date,
                                  est_delivery_date: delivery_date,
                                  event_id: last.order_id,
                              },
                          }
                        : {}),
                });
            }
        }
    }, [
        resolution,
        omitted,
        itemDelivered,
        itemExchanged,
        itemReturned,
        updates,
        order,
        items,
        exception,
        instruction,
    ]);

    const acknowledge = useCallback(() => {
        callbacks.onResolve({
            order: {
                order_id: order.order_id,
            },
            exception: {
                exception_id: exception.exception_id,
                status: 'RESOLVED',
            },
        });
    }, [order, exception]);

    return { resolve, acknowledge };
};
