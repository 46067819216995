import React, { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Grid, Tooltip } from '@material-ui/core';
import { OnwardCard } from './Card';
import dateFns from '@/utilities/dateFns';
import { useClientUser, useOrderShipperPricing, useOrderCarrierPricing } from '@/hooks';
import { delWindowToFormattedDateRange } from '@/utilities/delWindowToFormattedDate';
import zipcode_to_timezone from 'zipcode-to-timezone';
import { css } from '@emotion/react';
import { colors } from '@/styles';
import { startCase } from 'lodash';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { hourMinuteFormatter } from '@/constants/formats';
import ScheduledDeliveryDateModal from './modals/ScheduledDeliveryDateModal';
import { format } from 'date-fns';
import { recommendedEquipmentLabels } from '@/constants/recommendedEquipmentLabels';
import { LOCATION_TYPES } from '@/constants/locationTypes';
import { CardTitle, CardItem, CardSubtitle, CardItemBold } from './blocks';
import { Header1 } from './Crossdocking/blocks';
import { PrimaryButton } from '@/styles/blocks';
import { ITEM_LABELS_BY_FREIGHT } from '../ShipmentForm/constants/freightTypes';

export default function OrderSummaryCard(props) {
    const { order, editable, editPalletDimensions } = props;
    const { teammateRoles, user_id, accountType, circles, payment_type } = useClientUser();
    const navigate = useNavigate();

    const [openScheduledDeliveryModal, setOpenScheduledDeliverymodal] = useState(false);

    const displayDeliveryTime = order?.carrier_id && user_id !== order?.carrier_id && !!order?.del_window_start;
    const displayNonSaasDeliveryTime =
        order?.carrier_id && user_id !== order?.carrier_id && !!order?.del_hour && !!order?.del_end;

    let timeRangeStart = '';
    let timeRangeEnd = '';
    if (displayDeliveryTime) {
        timeRangeStart = order.del_window_start;
        timeRangeEnd = order.del_window_end;
    } else if (displayNonSaasDeliveryTime) {
        const tz = zipcode_to_timezone.lookup(order.dropoff_zip) || 'America/New_York';

        timeRangeStart = new Date(new Date().toLocaleString('en-US', { timeZone: tz }));
        timeRangeEnd = new Date(new Date().toLocaleString('en-US', { timeZone: tz }));

        timeRangeStart.setHours(order.del_hour);
        timeRangeEnd.setHours(order.del_end);

        if (order.del_hour % 1 !== 0) {
            timeRangeStart.setMinutes(30);
        }
        if (order.del_end % 1 !== 0) {
            timeRangeEnd.setMinutes(30);
        }

        timeRangeStart = timeRangeStart.toISOString();
        timeRangeEnd = timeRangeEnd.toISOString();
    }

    const {
        ['2_man_team']: twoManTeam,
        ['3_man_team']: threeManTeam,
        lift_gate,
        ...otherEquipment
    } = useMemo(() => {
        return order.recommended_equipment
            ? Object.keys(order.recommended_equipment)
                  .filter((key) => order.recommended_equipment[key])
                  .reduce((acc, val) => ({ ...acc, [val]: recommendedEquipmentLabels[val] }), {})
            : {};
    }, [order]);

    const dateShort = new Intl.DateTimeFormat('en-US', {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
    });

    const orderTypeText = useMemo(() => {
        const isInternal = !!order.oms;
        const isCarrier = user_id === order.carrier_id;

        return (
            <span
                css={css`
                    color: ${isInternal ? 'black' : colors.greens.primary};
                `}
            >
                {isInternal ? 'Internal Order' : isCarrier ? 'Claimed Order' : 'Sent to Onward'}
            </span>
        );
    }, [order]);

    const duration = order?.duration_seconds && hourMinuteFormatter.format(order?.duration_seconds / 60);

    const CardRow = ({ children, ...rest }) => {
        return (
            <Grid
                container
                item
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                style={{ marginTop: '0.75rem', marginBottom: '0.75rem' }}
                {...rest}
            >
                {children}
            </Grid>
        );
    };

    const HalfGridItem = ({ children, props }) => {
        return (
            <Grid item style={{ maxWidth: '50%', textAlign: 'right' }} {...props}>
                {children}
            </Grid>
        );
    };

    const [pickupRoute, dropoffRoute] = useMemo(() => {
        const sortedRoutes = [...(order?.routes || [])].sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
        return [
            sortedRoutes.find((mapping) => mapping.type === 'PICKUP')?.route,
            sortedRoutes.find((mapping) => mapping.type !== 'PICKUP')?.route,
        ];
    }, [order]);

    const handleRouteClick = (routeId) => {
        if (routeId) {
            navigate(`/${accountType}/routedetails/${routeId}`, {
                state: { source: 'OrderSummary' },
            });
        }
    };

    const RouteNumber = ({ route }) => {
        if (!route) return '-';
        const isClickable = route.carrier_id === user_id;

        return isClickable ? (
            <span
                onClick={(e) => {
                    e.stopPropagation();
                    handleRouteClick(route.route_id);
                }}
                css={css`
                    cursor: pointer;
                    color: ${colors.greens.primary};
                    text-decoration: underline;
                    &:hover {
                        color: ${colors.blues.dark};
                    }
                `}
            >
                {route.route_number}
            </span>
        ) : (
            <span>{route.route_number}</span>
        );
    };

    return (
        <>
            <OnwardCard>
                <CardRow style={{ marginBottom: 0 }}>
                    <Grid item>
                        <CardTitle>
                            {order.pickup_city}, {order.pickup_state} - {order.dropoff_city}, {order.dropoff_state}
                        </CardTitle>
                    </Grid>
                </CardRow>
                <CardRow style={{ marginTop: 0, marginBottom: '1.75rem' }}>
                    <Grid item>
                        <CardSubtitle>{orderTypeText}</CardSubtitle>
                    </Grid>
                </CardRow>

                <CardRow>
                    <Grid item>
                        <CardItem>PO / Ref #</CardItem>
                    </Grid>
                    <HalfGridItem>
                        <CardItemBold>
                            {order?.po_number || order?.reference_id
                                ? `${order?.po_number || '--'} / ${order?.reference_id || '--'}`
                                : 'N/A'}
                        </CardItemBold>
                    </HalfGridItem>
                </CardRow>
                <CardRow>
                    <Grid item>
                        <CardItem>Created</CardItem>
                    </Grid>
                    <HalfGridItem>
                        <CardItemBold>
                            {order.created_at ? format(new Date(order.created_at), 'EEE, MMM d, yyyy') : 'N/A'}
                        </CardItemBold>
                    </HalfGridItem>
                </CardRow>
                <CardRow>
                    <Grid item>
                        <CardItem>Shipper</CardItem>
                    </Grid>
                    <HalfGridItem>
                        <CardItemBold>{order.order_shipper?.business_name || '--'}</CardItemBold>
                    </HalfGridItem>
                </CardRow>
                <CardRow>
                    <Grid item>
                        <CardItem>Available</CardItem>
                    </Grid>
                    <HalfGridItem>
                        <CardItemBold>
                            {order?.first_available ? format(new Date(order.first_available, 'MM/dd/yyyy')) : '--'}
                        </CardItemBold>
                    </HalfGridItem>
                </CardRow>
                <CardRow>
                    <Grid item>
                        <CardItem>Preferred Delivery Date</CardItem>
                    </Grid>
                    <HalfGridItem>
                        <CardItemBold>{order.preferred_delivery_date_formatted || 'N/A'}</CardItemBold>
                    </HalfGridItem>
                </CardRow>
                <CardRow>
                    <Grid item>
                        <CardItem>Alternative Delivery Dates</CardItem>
                    </Grid>
                    <HalfGridItem>
                        <CardItemBold>
                            {order.alternative_delivery_dates
                                ? order.alternative_delivery_dates_formatted.join(', ')
                                : 'N/A'}
                        </CardItemBold>
                    </HalfGridItem>
                </CardRow>

                <CardRow>
                    <Grid item>
                        <CardItem>Scheduled Delivery Date</CardItem>
                    </Grid>
                    <HalfGridItem>
                        <CardItemBold
                            style={{ cursor: 'pointer' }}
                            onClick={() =>
                                editable && !order.routes?.length ? setOpenScheduledDeliverymodal(true) : null
                            }
                        >
                            <u>{order.delivery_date_formatted || 'TBD'}</u>
                        </CardItemBold>
                    </HalfGridItem>
                </CardRow>
                {order?.target_delivery_date && (
                    <CardRow>
                        <Grid item>
                            <CardItem>Target Delivery Date</CardItem>
                        </Grid>
                        <HalfGridItem>
                            <CardItemBold>{`${dateShort.format(
                                new Date(order?.target_delivery_date || 0)
                            )}`}</CardItemBold>
                        </HalfGridItem>
                    </CardRow>
                )}
                <CardRow>
                    <Grid item>
                        <CardItem>Estimated Distance</CardItem>
                    </Grid>
                    <HalfGridItem>
                        <CardItemBold>{order.distance ? `${order.distance}` : 'N/A'}</CardItemBold>
                    </HalfGridItem>
                </CardRow>
                <CardRow>
                    <Grid item>
                        <CardItem>Estimated Trip Time</CardItem>
                    </Grid>
                    <HalfGridItem>
                        <CardItemBold>{duration || 'N/A'}</CardItemBold>
                    </HalfGridItem>
                </CardRow>
                <CardRow>
                    <Grid item>
                        <CardItem>Route</CardItem>
                    </Grid>
                    <HalfGridItem>
                        <CardItemBold>
                            {pickupRoute ? <RouteNumber route={pickupRoute} /> : null}
                            {pickupRoute && dropoffRoute ? ' / ' : null}
                            <RouteNumber route={dropoffRoute} />
                        </CardItemBold>
                    </HalfGridItem>
                </CardRow>
                <CardRow>
                    <Grid item>
                        <CardItem>Driver</CardItem>
                    </Grid>
                    <HalfGridItem>
                        <CardItemBold>
                            {[
                                ...(pickupRoute ? [pickupRoute?.teammateByDriverId?.username || '-'] : []),
                                dropoffRoute?.teammateByDriverId?.username || '-',
                            ].join(' / ')}
                        </CardItemBold>
                    </HalfGridItem>
                </CardRow>
                <CardRow>
                    <Grid item>
                        <CardItem>Freight Type</CardItem>
                    </Grid>
                    <HalfGridItem>
                        <CardItemBold>{ITEM_LABELS_BY_FREIGHT[order.freight_type]}</CardItemBold>
                    </HalfGridItem>
                </CardRow>
                <CardRow>
                    <Grid item>
                        <CardItem>Service Level</CardItem>
                    </Grid>
                    <HalfGridItem>
                        <CardItemBold>
                            {order?.service_level?.service_level || LOCATION_TYPES[order.dropoff_location_type]}
                        </CardItemBold>
                    </HalfGridItem>
                </CardRow>
                <CardRow>
                    <Grid item>
                        <CardItem>Equipment</CardItem>
                    </Grid>
                    <HalfGridItem>
                        <CardItemBold>{Object.values(otherEquipment).join(', ')}</CardItemBold>
                    </HalfGridItem>
                </CardRow>
                <CardRow>
                    <Grid item>
                        <CardItem>Upload Method</CardItem>
                    </Grid>
                    <HalfGridItem>
                        <CardItemBold>{order.source_form ? `${order.source_form}` : 'N/A'}</CardItemBold>
                    </HalfGridItem>
                </CardRow>
                {!!order?.airline && (
                    <CardRow>
                        <Grid item>
                            <CardItem>Airline</CardItem>
                        </Grid>
                        <HalfGridItem>
                            <CardItemBold>{order.airline}</CardItemBold>
                        </HalfGridItem>
                    </CardRow>
                )}
                {!!order?.palletize_returns && (
                    <CardRow>
                        <Grid item>
                            <CardItem>Palletized Dimensions</CardItem>
                        </Grid>
                        <HalfGridItem>
                            {['L', 'W', 'H'].every((dimension) => order?.palletized_dimensions?.[dimension]) ? (
                                <CardItemBold>{`${['L', 'W', 'H']
                                    .map((key) => `${order.palletized_dimensions?.[key]}${key}`)
                                    .join(', ')}`}</CardItemBold>
                            ) : (
                                <Grid
                                    direction="column"
                                    container
                                    css={css`
                                        margin-right: 16px;
                                        flex: 0;
                                    `}
                                >
                                    <PrimaryButton
                                        onClick={() => {
                                            editPalletDimensions();
                                        }}
                                    >
                                        <Header1>Add Dimensions</Header1>
                                    </PrimaryButton>
                                </Grid>
                            )}
                        </HalfGridItem>
                    </CardRow>
                )}
            </OnwardCard>

            <ScheduledDeliveryDateModal
                order={order}
                open={openScheduledDeliveryModal}
                onClose={() => setOpenScheduledDeliverymodal(false)}
            />
        </>
    );
}
