import React, { useContext, useCallback, useMemo, useState } from 'react';
import { Grid, Checkbox, FormControlLabel, FormControl, MenuItem } from '@material-ui/core';
import { FixedSizeList, areEqual, VariableSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import searchOrders from '@/utilities/searchOrders';
import OrderListItem from './OrderListItem';
import { PlanningContext } from '../context';
import { MODALS } from '../constants';
import { css } from '@emotion/react';
import { Body2, PlanningListItem, PrimaryButtonFullW } from '../blocks';
import { isEmpty } from 'lodash';
import Select from '@material-ui/core/Select';
import SearchInput from '@/components/misc/SearchInput';
import { useClientUser } from '@/hooks';

const FILTER_OPTIONS = {
    ALL: 'ALL',
    ASSIGNED: 'ASSIGNED',
    UNASSIGNED: 'UNASSIGNED',
    SCHEDULED: 'SCHEDULED',
    UNSCHEDULED: 'UNSCHEDULED',
    CLAIMED: 'CLAIMED',
    INTERNAL: 'INTERNAL',
    RECEIVED: 'RECEIVED',
};

const FILTERS = {
    [FILTER_OPTIONS.ALL]: {
        label: 'All',
        filter: (o) => o,
    },
    [FILTER_OPTIONS.ASSIGNED]: {
        label: 'Assigned',
        filter: (o) => {
            return o.crossdock_leg === 'pickup' ? !!o.pickup_route_id : !!o.dropoff_route_id;
        },
    },
    [FILTER_OPTIONS.UNASSIGNED]: {
        label: 'Unassigned',
        filter: (o) => {
            return o.crossdock_leg === 'pickup' ? !o.pickup_route_id : !o.dropoff_route_id;
        },
    },
    [FILTER_OPTIONS.SCHEDULED]: {
        label: 'Scheduled',
        filter: (o) => {
            return o.crossdock_leg === 'pickup' ? !!o.pickup_date : !!o.delivery_date;
        },
    },
    [FILTER_OPTIONS.UNSCHEDULED]: {
        label: 'Unscheduled',
        filter: (o) => {
            return o.crossdock_leg === 'pickup' ? !o.pickup_date : !o.delivery_date;
        },
        flag: 'plan-page-unscheduled'
    },
    [FILTER_OPTIONS.RECEIVED]: {
        label: 'Received',
        filter: (o) => {
            return (o.crossdock_leg === 'pickup' ? !o.pickup_date : !o.delivery_date) && (o?.wh_events || []).some(wh_e => wh_e.status === 'RECEIVED');
        },
        flag: 'plan-page-received'
    },
    [FILTER_OPTIONS.CLAIMED]: {
        label: 'Marketplace',
        filter: (o) => {
            return !o.oms && o.carrier_id && o.carrier_id !== o.shipper_id;
        },
    },
    [FILTER_OPTIONS.INTERNAL]: {
        label: 'Internal',
        filter: (o) => {
            return o.oms && !o.carrier_id;
        },
    },
};

const PlanningOrders = () => {
    const {
        setSelectedOrders,
        setModalOpen,
        selectedOrders,
        state: { orders, routes },
    } = useContext(PlanningContext);
    const { circles } = useClientUser();

    const [search, setSearch] = useState('');
    const [checkFilter, setCheckFilter] = useState(FILTER_OPTIONS.ALL);

    const filtered = useMemo(() => {
        let filtered = orders.filter(FILTERS[checkFilter].filter);
        if (!isEmpty(search)) {
            const searchedResults = searchOrders(search, {
                all: filtered,
            });
            filtered = searchedResults.all;
        }
        return filtered;
    }, [orders, searchOrders, search, checkFilter]);

    const checked = useMemo(() => {
        const allChecked = filtered.every((order) => selectedOrders[`${order.order_id}_${order.crossdock_leg}`]);
        const numSelected = Object.values(selectedOrders).reduce((count, val) => {
            if (val) count++;
            return count;
        }, 0);

        return allChecked && numSelected === filtered.length;
    }, [selectedOrders, checkFilter, filtered]);

    const onSelectAll = (e) => {
        e.stopPropagation();
        if (!checked) {
            setSelectedOrders(
                Object.fromEntries(filtered.map((order) => [`${order.order_id}_${order.crossdock_leg}`, true]))
            );
        } else {
            setSelectedOrders({});
        }
    };

    const order = useCallback(
        ({ index, data, style }) => {
            if (index > data.length - 1) {
                return null;
            }

            const order = data[index];
            return <OrderListItem style={style} order={order} routes={routes} key={order.order_id} />;
        },
        [routes]
    );

    const handleMenuItemClick = (e) => {
        e.stopPropagation();
        if (e.target.value) {
            setCheckFilter(e.target.value);
            setSelectedOrders({});
        }
    };

    return (
        <>
            <PlanningListItem
                css={css`
                    border-top: 1px solid #e2e2e2;
                    border-bottom: none;
                `}
            >
                <SearchInput onChange={(s) => setSearch(s)} placeholder="Search Orders" />
            </PlanningListItem>
            <PlanningListItem>
                <FormControl variant="outlined">
                    <Select
                        labelId="order-filter-select"
                        id="order-filter-select"
                        inputProps={{ 'aria-label': 'Without label' }}
                        renderValue={(value) => {
                            return (
                                <FormControlLabel
                                    css={css`
                                        width: 100%;
                                        margin-right: 0;
                                        .MuiFormControlLabel-label {
                                            flex-grow: 1;
                                        }
                                    `}
                                    control={<Checkbox onMouseDown={onSelectAll} color="primary" checked={checked} />}
                                    label={<Body2>{FILTERS[value].label}</Body2>}
                                />
                            );
                        }}
                        css={css`
                            & .MuiInputBase-input {
                                padding-top: 0px;
                                padding-bottom: 0px;
                            }

                            && .MuiOutlinedInput-notchedOutline {
                                border-color: rgba(0, 0, 0, 0.23);
                                border-width: 1px;
                            }
                        `}
                        value={checkFilter}
                        onClick={handleMenuItemClick}
                    >
                        {Object.values(FILTER_OPTIONS).filter(option => {
                            return !FILTERS[option]?.flag || !!circles?.[FILTERS[option]?.flag]}).map((option) => (
                            <MenuItem value={option} key={option}>
                                {FILTERS[option].label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </PlanningListItem>

            <Grid
                container
                css={css`
                    display: flex;
                    flex-grow: 1;
                    flex-basis: 0;
                `}
            >
                <AutoSizer>
                    {({ height, width }) => {
                        return (
                            <VariableSizeList
                                overscanCount={20}
                                height={height}
                                itemData={filtered}
                                itemCount={filtered.length + 1}
                                itemSize={(index) => {
                                    let size = 265;
                                    const item = filtered[index];
                                    if (item?.reference_id) {
                                        size += 15;
                                    }
                                    if (item?.dropoff_comments) {
                                        size += 15;
                                    }
                                    return size;
                                }}
                                width={width}
                            >
                                {order}
                            </VariableSizeList>
                        );
                    }}
                </AutoSizer>
            </Grid>

            <Grid
                container
                css={css`
                    padding: 1rem;
                `}
            >
                <PrimaryButtonFullW onClick={() => setModalOpen(MODALS.ORDER_OMS)}>
                    Back to Unassigned
                </PrimaryButtonFullW>
            </Grid>
        </>
    );
};

export default PlanningOrders;
