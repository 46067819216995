import React, { useEffect, useCallback, forwardRef, useRef, useState, useContext, useMemo } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { useTable, useSortBy, useRowSelect, useFlexLayout } from 'react-table';
import { useSticky } from 'react-table-sticky';
import { OnwardTab, OnwardTabContainer } from '@/components/Tabs';
import { TableContainer, Table, TableHead, Button } from '@material-ui/core';

import HeaderCell from './table/HeaderCell';
import BodyCell from './table/BodyCell';
import BodyRow from './table/BodyRow';
import { CustomCheckbox } from './blocks';

import { useClientUser } from '@/hooks';
import Modal from '@mui/material/Modal';
import { Grid } from '@material-ui/core';
import { CardTitle, CardSubtitle } from '../OrderDetailsPage/blocks';
import CloseIcon from '@material-ui/icons/Close';
import Image from 'react-bootstrap/Image';
import { View } from '@react-pdf/renderer';
import { UserContext } from '../App';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import getOrdersPhotosAsZip from '../../utilities/getOrdersPhotosAsZip';
import getAllOrdersFiles from '../../utilities/getAllOrdersFiles';
import { saveAs } from 'file-saver';
import * as Sentry from '@sentry/react';
export const StickyTable = styled(({ children, ...props }) => <Table {...props}>{children}</Table>)`
    overflow: scroll;
`;

export const StickyHeader = styled(({ children, ...props }) => <TableHead {...props}>{children}</TableHead>)`
    position: sticky;
    background-color: blue;
    z-index: 1;
    width: fit-content;
`;

const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
    const defaultRef = useRef();
    const resolvedRef = ref || defaultRef;

    useEffect(() => {
        if (resolvedRef.current) {
            resolvedRef.current.indeterminate = indeterminate;
        }
    }, [resolvedRef, indeterminate]);

    return (
        <CustomCheckbox
            ref={resolvedRef}
            {...rest}
            css={css`
                .MuiSvgIcon-root {
                    height: 20px;
                    width: 20px;
                }
            `}
        />
    );
});

function AccountingOrderTable({ state, loading, callbacks }) {
    const [sortBy, setSortBy] = useState([{ id: 'delivery_date', desc: true }]);

    const { filteredOrders, selectedOrderIds, subTab, podModalOrder, invoiceAccess, columns, subTabs } = state;

    async function handleDownload() {
        const zip = await getAllOrdersFiles([podModalOrder]);
        if (zip) {
            const zipBlob = await zip.generateAsync({ type: 'blob' });
            saveAs(zipBlob, `${podModalOrder.order_number}-photos.zip`);
        } else {
            const error = new Error(
                `Could not successfully retrieve Final Delivery Photo zip file for order ${podModalOrder.order_number}`
            );
            Sentry.captureException(error);
            console.error(error);
        }
    }

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows,
        state: tableState,
    } = useTable(
        {
            columns,
            data: filteredOrders,
            initialState: {
                selectedRowIds: selectedOrderIds,
                sortBy,
            },
            getRowId: (order) => order.order_id,
            enableRowSelection: true,
            enableMultiRowSelection: true,
            autoResetSelectedRows: true,
        },
        useSortBy,
        useRowSelect,
        useSticky,
        useFlexLayout,
        (hooks) => {
            hooks.visibleColumns.push((columns) => [
                {
                    id: 'selection',
                    sticky: 'left',
                    width: 50,
                    Header: ({ toggleRowSelected, rows, selectedFlatRows }) => {
                        const style = {
                            cursor: 'pointer',
                        };
                        const checked = rows.length > 1 && rows.length - selectedFlatRows.length === 1;
                        const title = 'Toggle All Rows Selected';
                        const indeterminate = !checked && selectedFlatRows.length > 0;

                        const overiddenOnChange = (event) => {
                            rows.forEach((row) => {
                                if (!row.original.carrier_invoice_id) {
                                    toggleRowSelected(row.id, event.currentTarget.checked);
                                }
                            });
                        };
                        const modifiedToggleAllRowsProps = {
                            onChange: overiddenOnChange,
                            style: style,
                            checked: indeterminate,
                            title: title,
                            indeterminate: indeterminate,
                        };
                        return (
                            <div>
                                <IndeterminateCheckbox {...modifiedToggleAllRowsProps} />
                            </div>
                        );
                    },
                    Cell: ({ row }) => (
                        <IndeterminateCheckbox
                            onClick={(e) => e.stopPropagation()}
                            {...row.getToggleRowSelectedProps()}
                        />
                    ),
                },
                ...columns,
            ]);
        }
    );

    useEffect(() => {
        if (tableState?.sortBy?.length) {
            setSortBy(tableState.sortBy);
        }
    }, [tableState?.sortBy]);

    useEffect(() => {
        callbacks.setSelectedOrderIds(tableState?.selectedRowIds);
    }, [tableState?.selectedRowIds]);

    const Row = useCallback(
        ({ index, style }) => {
            //HEADERS
            if (index === 0) {
                const [group] = headerGroups;

                return (
                    <div {...group.getHeaderGroupProps({ style })}>
                        {group.headers.reduce(
                            (acc, subgroup) => [
                                ...acc,
                                subgroup.headers.map((col) => (
                                    <HeaderCell {...col.getHeaderProps()} key={col.id} cell={col} align={col.align} />
                                )),
                            ],
                            []
                        )}
                    </div>
                );
            }

            //BODY
            const row = rows[index - 1];
            prepareRow(row);
            return (
                <BodyRow
                    {...row.getRowProps({
                        style,
                    })}
                    row={row}
                    callbacks={callbacks}
                    onClick={() => callbacks.gotoAccessorial(row.original.order_id)}
                >
                    {row.cells.map((cell) => (
                        <BodyCell
                            {...cell.getCellProps()}
                            cell={cell}
                            align={cell.column.align}
                            span={cell.column.span}
                            css={css`
                                overflow: auto;
                            `}
                        />
                    ))}
                </BodyRow>
            );
        },
        [prepareRow, rows]
    );

    return (
        <TableContainer
            css={css`
                height: 100%;
                overflow: hidden;
                background-color: white;
            `}
        >
            <OnwardTabContainer
                value={subTab}
                onChange={(event, newVal) => callbacks.setSubTab(newVal)}
                textColor="primary"
                indicatorColor="primary"
                centered
            >
                {subTabs.map((tab, idx) => (
                    <OnwardTab
                        key={`accounting-tab-${tab.label}`}
                        label={tab.label}
                        value={idx}
                        disabled={tab.accessReq && !invoiceAccess}
                        style={{ outline: 0 }}
                    />
                ))}
            </OnwardTabContainer>

            <div
                {...getTableProps()}
                css={css`
                    height: calc(100% - 66.5px);
                    display: flex;
                    flex-grow: 1;
                    flex-direction: column;
                `}
            >
                <div
                    {...getTableBodyProps()}
                    css={css`
                        display: flex;
                        flex-grow: 1;
                    `}
                >
                    <AutoSizer>
                        {({ height, width }) => (
                            <FixedSizeList height={height} itemCount={rows.length + 1} itemSize={57} width={width}>
                                {Row}
                            </FixedSizeList>
                        )}
                    </AutoSizer>

                    {rows.length === 0 && !loading.init && (
                        <div
                            css={css`
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                flex-grow: 1;
                            `}
                        >
                            No {subTabs[subTab].label} Orders
                        </div>
                    )}
                </div>
            </div>
        </TableContainer>
    );
}

export default React.memo(AccountingOrderTable);
