import React from 'react';
import { css } from '@emotion/react';
import { BodyText, StickyCell } from '@/components/CarrierAccountingOrders/blocks';
import { colors } from '@/styles';

import styled from '@emotion/styled';
import { Grid, MenuItem, TextField, TableRow, Tooltip, IconButton } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';

const Row = styled(({ children, ...rest }) => <TableRow {...rest}>{children}</TableRow>)`
    cursor: pointer;

    :hover td {
        background-color: #f5f5f5;
    }

    color: ${(props) => (props.error ? '#f44336' : '#2b2b2b')};
`;

export function BodyRow({ row, children, ...props }) {
    return <Row {...props}>{children}</Row>;
}

export function BodyCell({ cell, span = true, callbacks = {}, isChecked, className, children, ...props }) {
    return (
        <StickyCell
            align="left"
            className={className}
            {...props}
            css={css`
                position: relative;
            `}
        >
            {span ? (
                <BodyText
                    css={css`
                        position: absolute;
                        top: 50%;
                        -webkit-transform: translateY(-50%);
                        transform: translateY(-50%);
                        ${cell?.column?.id === 'est_received_date' && `width: -webkit-fill-available;`}
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        overflow-x: auto;
                        justify-content: flex-start;
                        align-items: center;
                        width: 100%;
                        padding-left: 30px;
                    `}
                >
                    {cell.render('Cell', { callbacks, isChecked })}
                    {children}
                </BodyText>
            ) : (
                cell.render('Cell', { callbacks, isChecked })
            )}
        </StickyCell>
    );
}

export function TextCell({ cell, span = true, callbacks = {}, isChecked, className, children, ...props }) {
    return (
        <StickyCell
            align="left"
            className={className}
            {...props}
            css={css`
                position: relative;
            `}
        >
            <BodyText
                css={css`
                    position: absolute;
                    top: 50%;
                    -webkit-transform: translateY(-50%);
                    transform: translateY(-50%);
                    ${cell?.column?.id === 'est_received_date' && `width: -webkit-fill-available;`}
                `}
            >
                {children}
            </BodyText>
        </StickyCell>
    );
}

const isAlreadyInManifest = (item, currManifestType) =>
    item.manifests?.some((manifest) => manifest?.manifest?.type === currManifestType);

const SplitItem = ({ item, itemQuantities, setItemQuantities }) => {
    let quantityOptions = new Array(item.quantity).fill(0).map((i, idx) => item.quantity - idx);

    return (
        <Grid
            container
            direction="row"
            alignItems="center"
            css={css`
                margin-left: 10px;
            `}
        >
            <TextField
                select
                variant="outlined"
                label="Quantity"
                size="small"
                value={itemQuantities[item.item_id] || item.quantity}
                onChange={(e) => {
                    setItemQuantities((prev) => {
                        return {
                            ...prev,
                            [item.item_id]: e.target.value,
                        };
                    });
                }}
            >
                {quantityOptions.map((i) => (
                    <MenuItem key={i} value={i}>
                        {i}
                    </MenuItem>
                ))}
            </TextField>
            <Tooltip
                style={{ color: '#59B863', marginLeft: '5px' }}
                title={
                    'Optionally choose to split this item by specified quantity. The amount you choose will be added to this manifest, the remainder can be added to another future manifest.'
                }
                placement="top"
            >
                <IconButton size="small">
                    <InfoOutlined />
                </IconButton>
            </Tooltip>
        </Grid>
    );
};

export default function SubRowComponent({ row, style, callbacks, newManifest, itemQuantities, newManifestType }) {
    const item = row.original;
    const itemAlreadyInManifest = isAlreadyInManifest(item, newManifestType);

    return (
        <BodyRow
            {...row.getRowProps({
                style,
            })}
            row={row}
        >
            {itemAlreadyInManifest ? (
                <>
                    <Grid
                        item
                        css={css`
                            font-weight: 500;
                            margin-top: 7px;
                            padding-left: 40px;
                        `}
                    >
                        {`${item.description || item.item_type_details || 'NO DESCRIPTION'}${
                            item.sku ? ` / ${item.sku}` : ''
                        }`}
                        <span
                            css={css`
                                color: ${colors.greys.subtitle};
                                margin-left: 10px;
                                font-style: italic;
                            `}
                        >
                            {` Quantity: ${item.quantity || 1}`}
                        </span>
                        {['itemDamaged', 'shortage', 'overage'].includes(item.item_exception?.type) ? (
                            <WarningIcon
                                css={css`
                                    color: ${colors.oranges.primary};
                                `}
                            />
                        ) : null}
                        <span
                            css={css`
                                color: ${colors.greys.subtitle};
                                margin-left: 10px;
                            `}
                        >
                            - (Already in a manifest)
                        </span>
                    </Grid>
                    {!!newManifest?.items?.[item.item_id] &&
                        item.quantity > 1 &&
                        ['INBOUND', 'WILL_CALL', 'CROSS_DOCK'].includes(newManifest?.type) && (
                            <SplitItem
                                item={item}
                                manifestType={newManifest?.type}
                                itemQuantities={itemQuantities}
                                setItemQuantities={callbacks.setItemQuantities}
                            />
                        )}
                </>
            ) : (
                <BodyCell
                    {...row?.cells?.[0]?.getCellProps()}
                    cell={row?.cells?.[0]}
                    align={row?.cells?.[0]?.column?.align}
                    span={row?.cells?.[0]?.column?.span}
                    css={css`
                        overflow: auto;
                        height: 90px !important;
                    `}
                >
                    <Grid item>
                        {item.pallet
                            ? item.pallet.pallet_name
                                ? `Pallet ${item.pallet.pallet_name} - `
                                : `Pallet ${item.pallet.pallet_number} - `
                            : ''}
                        {`${item.description || item.item_type_details || 'NO DESCRIPTION'}${
                            item.sku ? ` / ${item.sku}` : ''
                        }`}
                        <span
                            css={css`
                                color: ${colors.greys.subtitle};
                                margin-left: 10px;
                            `}
                        >
                            {` Quantity: ${item.quantity || 1}`}
                        </span>
                        {['itemDamaged', 'shortage', 'overage'].includes(item.item_exception?.type) ? (
                            <WarningIcon
                                css={css`
                                    color: ${colors.oranges.primary};
                                `}
                            />
                        ) : null}
                    </Grid>
                    {!!newManifest?.items?.[item.item_id] &&
                        item.quantity > 1 &&
                        ['INBOUND', 'WILL_CALL', 'CROSS_DOCK'].includes(newManifest?.type) && (
                            <SplitItem
                                item={item}
                                manifestType={newManifest?.type}
                                itemQuantities={itemQuantities}
                                setItemQuantities={callbacks.setItemQuantities}
                            />
                        )}
                </BodyCell>
            )}
        </BodyRow>
    );
}
