import { gql } from '@apollo/client';
import { ROUTE_FIELDS, ROUTE_ORDER_FIELDS } from '@/graphql/queries/routes';
import { STOP_FIELDS } from '@/graphql/queries/stops';
import { ORDER_FIELDS } from '@/graphql/queries/orders';
import { CLIENT_FIELDS } from '@/graphql/queries/clients';
import { WAREHOUSE_EVENT_FIELDS } from '@/graphql/queries/order_wh_events';

export const ASSIGN_ORDERS_UPSERT = gql`
    ${ORDER_FIELDS}
    ${ROUTE_FIELDS}
    ${STOP_FIELDS}
    ${WAREHOUSE_EVENT_FIELDS}
    mutation AssignOrders(
        $route_ids: [uuid!]!
        $events: [order_wh_events_insert_input!]!
        $order_updates: [orders_updates!]!
        $routes: [routes_insert_input!]!
        $stops: [stops_insert_input!]!
    ) {
        delete_stops(where: { route_id: { _in: $route_ids } }) {
            affected_rows
        }
        insert_routes(objects: $routes, on_conflict: { constraint: routes_pkey, update_columns: [need_to_optimize] }) {
            returning {
                ...RouteFields
                stopsByRouteId {
                    ...StopFields
                }
            }
        }
        insert_stops(objects: $stops) {
            affected_rows
            returning {
                ...StopFields
            }
        }
        update_orders_many(updates: $order_updates) {
            affected_rows
            returning {
                ...OrderFields
            }
        }
        insert_order_wh_events(objects: $events) {
            affected_rows
            returning {
                ...WarehouseEvents
            }
        }
    }
`;

export const UPDATE_ORDERS = gql`
    ${ORDER_FIELDS}

    mutation UpdateOrders($order_ids: [uuid!]!, $update: orders_set_input!) {
        update_orders_many(updates: { where: { order_id: { _in: $order_ids } }, _set: $update }) {
            affected_rows
            returning {
                ...OrderFields
            }
        }
    }
`;

export const UPDATE_ORDERS_MANY = gql`
    ${ORDER_FIELDS}

    mutation UpdateOrdersMany($updates: [orders_updates!] = []) {
        update_orders_many(updates: $updates) {
            affected_rows
            returning {
                ...OrderFields
            }
        }
    }
`;

export const UPDATE_ROUTE_STOPS = gql`
    ${STOP_FIELDS}
    ${ROUTE_FIELDS}
    ${ORDER_FIELDS}

    mutation UpdateStops(
        $route_id: uuid!
        $route_update: routes_set_input!
        $order_updates: [orders_updates!] = []
        $updates: [stops_updates!] = []
        $inserts: [stops_insert_input!] = []
        $deletes: [uuid!] = []
        $events: [order_wh_events_insert_input!] = []
    ) {
        insert_order_wh_events(objects: $events) {
            affected_rows
            returning {
                ...WarehouseEvents
            }
        }

        update_orders_many(updates: $order_updates) {
            affected_rows
            returning {
                ...OrderFields
            }
        }

        update_stops_many(updates: $updates) {
            affected_rows
            returning {
                ...StopFields
            }
        }

        insert_stops(objects: $inserts) {
            affected_rows
            returning {
                ...StopFields
            }
        }

        delete_stops(where: { stop_id: { _in: $deletes } }) {
            affected_rows
            returning {
                ...StopFields
            }
        }

        update_routes_by_pk(pk_columns: { route_id: $route_id }, _set: $route_update) {
            ...RouteFields
        }
    }
`;

export const UPDATE_ROUTE = gql`
    ${ROUTE_FIELDS}

    mutation UpdateRoute($route_id: uuid!, $update: routes_set_input) {
        update_routes_by_pk(pk_columns: { route_id: $route_id }, _set: $update) {
            ...RouteFields
        }
    }
`;

export const UPSERT_ROUTE = gql`
    ${ROUTE_FIELDS}

    mutation UpdateRoute($route: routes_insert_input!, $route_update_cols: [routes_update_column!]! = []) {
        insert_routes_one(
            object: $route
            on_conflict: { constraint: routes_pkey, update_columns: $route_update_cols }
        ) {
            ...RouteFields
        }
    }
`;

export const RESCHEDULE_ROUTE = gql`
    mutation RescheduleRoute(
        $route_id: uuid!
        $pickup_ids: [uuid!] = []
        $dropoff_ids: [uuid!] = []
        $delivery_date: timestamptz
    ) {
        update_routes_by_pk(
            pk_columns: { route_id: $route_id }
            _set: { scheduled_delivery: $delivery_date, route_color: null, need_to_optimize: true, start_time: null }
        ) {
            route_id
            route_color
            scheduled_delivery
            start_time
            need_to_optimize
            route_number
        }

        update_stops(
            where: { route_id: { _eq: $route_id } }
            _set: {
                del_window_start: null
                del_window_end: null
                original_del_window_start: null
                original_del_window_end: null
                stop_start_time: null
                stop_end_time: null
                driving_time: null
                driving_distance: null
            }
        ) {
            returning {
                stop_id
                del_window_start
                del_window_end
                original_del_window_start
                original_del_window_end
                stop_start_time
                stop_end_time
                driving_time
                driving_distance
            }
        }

        pickups: update_orders(where: { order_id: { _in: $pickup_ids } }, _set: { pickup_date: $delivery_date }) {
            returning {
                order_id
                order_number
                pickup_date
            }
        }

        dropoffs: update_orders(
            where: { order_id: { _in: $dropoff_ids } }
            _set: {
                delivery_date: $delivery_date
                del_window_start: null
                del_window_end: null
                original_del_window_start: null
                original_del_window_end: null
                delivery_time_confirmed: null
            }
        ) {
            returning {
                order_id
                order_number
                delivery_date
                del_window_start
                del_window_end
                original_del_window_start
                original_del_window_end
                delivery_time_confirmed
            }
        }
    }
`;

export const UNLOCK_ROUTE = gql`
    mutation UnlockRoute($route_id: uuid!) {
        update_routes_by_pk(pk_columns: { route_id: $route_id }, _set: { status: "planning" }) {
            route_id
            route_number
            status
        }
    }
`;

export const REMOVE_ROUTE = gql`
    ${ORDER_FIELDS}
    ${ROUTE_FIELDS}
    mutation RemoveRoute(
        $route_id: uuid!
        $order_updates: [orders_updates!]!
        $events: [order_wh_events_insert_input!]!
        $route: routes_insert_input!
    ) {
        insert_routes_one(object: $route, on_conflict: { constraint: routes_pkey, update_columns: [status] }) {
            ...RouteFields
        }
        insert_order_wh_events(objects: $events) {
            affected_rows
            returning {
                ...WarehouseEvents
            }
        }
        update_orders_many(updates: $order_updates) {
            affected_rows
            returning {
                ...OrderFields
            }
        }
        delete_stops(where: { route_id: { _eq: $route_id } }) {
            returning {
                stop_id
            }
        }
    }
`;

export const INSERT_ROUTE = gql`
    ${ROUTE_FIELDS}
    ${STOP_FIELDS}
    ${ORDER_FIELDS}

    mutation InsertRoute(
        $shipper_id: uuid!
        $delivery_date: timestamptz
        $route_color: String
        $finish_returns_next_day: Boolean
    ) {
        insert_routes_one(
            object: {
                shipper_id: $shipper_id
                scheduled_delivery: $delivery_date
                route_color: $route_color
                status: "planning"
                source_form: "PLANNING"
                need_to_optimize: true
                planning: true
                finish_returns_next_day: $finish_returns_next_day
            }
        ) {
            ...RouteFields
            stopsByRouteId {
                ...StopFields
            }
        }
    }
`;

export const INSERT_MULTIPLE_ROUTES = gql`
    ${ROUTE_FIELDS}
    ${STOP_FIELDS}
    ${ORDER_FIELDS}
    ${ROUTE_ORDER_FIELDS}
    mutation InsertMultipleRoutes($routes: [routes_insert_input!]!) {
        insert_routes(objects: $routes) {
            returning {
                ...RouteFields
                ...RouteOrderFields
                stopsByRouteId {
                    ...StopFields
                }
            }
        }
    }
`;

export const NEW_ROUTE_FRAGMENT = gql`
    ${ROUTE_FIELDS}
    ${ORDER_FIELDS}
    ${STOP_FIELDS}

    fragment NewRoute on routes {
        ...RouteFields
        stopsByRouteId {
            ...StopFields
        }
    }
`;
export const CLAIM_LOADS = gql`
    ${ORDER_FIELDS}
    mutation UpdateOrders($order_ids: [uuid!]!, $user_id: uuid!, $delivery_date: timestamptz!) {
        update_orders_many(
            updates: {
                where: { order_id: { _in: $order_ids }, carrier_id: { _is_null: true } }
                _set: { carrier_id: $user_id, order_status: "claimed", delivery_date: $delivery_date, planning: true }
            }
        ) {
            affected_rows
            returning {
                ...OrderFields
            }
        }
    }
`;

export const SEND_TO_ONWARD = gql`
    ${ORDER_FIELDS}
    mutation SendToOnward($order_ids: [uuid!]!) {
        update_orders_many(
            updates: {
                where: { order_id: { _in: $order_ids } }
                _set: { carrier_id: null, order_status: "pending", oms: false, planning: false }
            }
        ) {
            affected_rows
            returning {
                ...OrderFields
            }
        }
    }
`;

export const UPDATE_SETTINGS = gql`
    ${CLIENT_FIELDS}
    mutation UpdateClientTimeframeSettings($client_id: uuid!, $update: clients_set_input) {
        update_clients_by_pk(pk_columns: { client_id: $client_id }, _set: $update) {
            ...ClientFields
        }
    }
`;
