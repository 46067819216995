import React, { useMemo } from 'react';
import { css } from '@emotion/react';
import { Grid, StylesProvider } from '@material-ui/core';
import { calcDefaultServiceTime } from '@/utilities/calculateTimeframes';
import { poundFormatter, integerFormatter, minuteString } from '@/constants/formats';
import { Body1, Body2 } from '../blocks';
import {
    useItemQuantity,
    useTotalWeight,
    useTotalCubes,
    useOrderShipperPricing,
    useOrderCarrierPricing,
    useTotalRevenue,
} from '@/hooks';
import { LOCATION_TYPES } from '@/constants/locationTypes';
import deliveryStrings from '@/constants/deliveryStrings';
import { capitalize, startCase } from 'lodash';
import dateFns from '@/utilities/dateFns';
import { genAttributes } from '@onward-delivery/core';
import { toNational } from '@/utilities/formatPhoneNumber';
import { colors } from '@/styles';

const Metric = ({ label, value }) => {
    const render = [...(label ? [label] : []), value];
    return (
        <Grid item>
            <Body2>{render.join(': ')}</Body2>
        </Grid>
    );
};

const BoldMetric = ({ label, value }) => {
    const render = [...(label ? [label] : []), value];
    return (
        <Grid item>
            <Body1>{render.join(': ')}</Body1>
        </Grid>
    );
};

const Badge = ({ label, backgroundColor, textColor }) => {
    return (
        <Grid
            item
            style={{
                padding: '0 4px',
                marginRight: '4px',
                backgroundColor,
                color: textColor,
            }}
        >
            <Body1>{label}</Body1>
        </Grid>
    );
};

const OrderTooltip = ({ order, stop, user_id, timeframeOptions, invoiceAccess }) => {
    const totalItems = useItemQuantity([order]);
    const totalWeight = useTotalWeight([order]);
    const totalVolume = useTotalCubes([order]);
    const isShipper = user_id === order.shipper_id;
    const rate = order.oms ? order.order_revenue : isShipper ? order.shipper_rate : order.carrier_rate;

    const serviceTime = useMemo(() => {
        if (!stop) {
            return null;
        }

        if (stop.service_time === 0 || stop.service_time) {
            return stop.service_time / 1000 / 60;
        }

        const { hours, minutes } = calcDefaultServiceTime(stop, { [order.order_id]: order }, timeframeOptions);
        return (hours || 0) * 60 + (minutes || 0);
    }, [stop, order]);

    const badges = useMemo(() => {
        let badges = [];
        if (!order.oms) {
            badges.push({
                key: 'marketplace',
                label: 'Marketplace Order',
                backgroundColor: colors.greens.primary,
                textColor: 'white',
            });
        }
        if (!order.delivery_date) {
            badges.push({
                key: 'unscheduled',
                label: 'Unscheduled Order',
                backgroundColor: colors.reds.primary,
                textColor: 'white',
            });
        }
        return badges;
    }, [order]);

    const { street, unit, city, state, zip, location_type } = genAttributes(order);

    const items = useMemo(() => {
        return (order.itemsByOrderId || []).reduce((acc, item) => {
            let lines = [];
            const qty = item.quantity || 1;
            for (let i = 0; i < qty; i++) {
                lines.push(item);
            }
            return [...acc, ...lines];
        }, []);
    }, [order]);

    const surveys = useMemo(() => {
        return (order.survey_responses || []).filter((response) =>
            ['PREDELIVERY', 'PREDELIVERY_DEFAULTS'].includes(response.survey_question.survey_type)
        );
    }, [order]);

    const getResponse = (response, question) => {
        switch (question.response_type) {
            case 'string':
                return response.response_string;
            case 'boolean':
                return response.response_boolean ? 'Yes' : 'No';
            case 'number':
                return `${(response.response_number || 0).toFixed(1)}`;
        }
        return null;
    };

    const tags = useMemo(() => {
        return (order.tags || []).filter(({ tag }) => tag.route_tag).map(({ tag }) => tag.tag);
    }, [order]);

    return (
        <>
            {badges.map((badge) => (
                <Badge {...badge} />
            ))}
            <BoldMetric value={`${order.dropoff_name} - ${order.po_number}`} />
            {order.reference_id && <BoldMetric value={order.reference_id} />}
            {invoiceAccess && rate ? <Metric label={'Revenue'} value={`$${(rate || 0).toFixed(2)}`} /> : null}
            <Metric value={[order[street], order[unit]].filter((x) => x).join(', ')} />
            <Metric value={[order[city], order[state], order[zip]].filter((x) => x).join(', ')} />
            <Metric label="Phone" value={toNational(order.dropoff_phone)} />
            <Metric label="Order Type" value={startCase(order.order_type)} />
            <Metric
                label="Service Type"
                value={order.service_level?.service_level || LOCATION_TYPES[order[location_type]] || '-'}
            />
            <Metric label="Freight Type" value={startCase(order.freight_type)} />
            {serviceTime && <Metric label="Service Time" value={`${serviceTime} min`} />}
            {order.manufacturer && <Metric label="Manufacturer" value={order.manufacturer} />}
            <Metric
                value={[
                    `${integerFormatter.format(totalItems)} items`,
                    `${integerFormatter.format(totalVolume)} cu ft.`,
                    `${integerFormatter.format(totalWeight)} lbs`,
                ].join(' / ')}
            />
            {items.map((item, idx) => (
                <Metric
                    key={`${item.item_id}-${idx}`}
                    value={[item.sku, item.description].filter((x) => !!x).join(' - ')}
                />
            ))}
            {tags.length > 0 ? <Metric label="Tags" value={tags.join(', ')} /> : null}
            {/* {surveys.map((response, idx) => (
                <Metric
                    key={response.survey_id}
                    label={`${idx + 1}) ${response.survey_question.question}`}
                    value={getResponse(response, response.survey_question)}
                />
            ))} */}
            {surveys.length > 0 ? (
                <Metric
                    label="Survey Responses"
                    value={surveys
                        .map((response, idx) =>
                            [
                                `${idx + 1}) ${response.survey_question.question}`,
                                getResponse(response, response.survey_question),
                            ].join(': ')
                        )
                        .join(' ')}
                />
            ) : null}
        </>
    );
};

const MapStopTooltip = ({ orders, stop, user_id, timeframeOptions, invoiceAccess }) => {
    if (!orders?.length) return <Body1>Error! Missing Order Information</Body1>;

    return (
        <StylesProvider injectFirst>
            <Grid container direction="column">
                {orders
                    .filter((o) => !!o)
                    .map((order) => (
                        <OrderTooltip
                            order={order}
                            stop={stop}
                            user_id={user_id}
                            timeframeOptions={timeframeOptions}
                            invoiceAccess={invoiceAccess}
                        />
                    ))}
            </Grid>
        </StylesProvider>
    );
};

export default MapStopTooltip;
