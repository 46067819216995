import { useClientUser } from '@/hooks';
import React, { useMemo, useState } from 'react';
import { TabCard } from '../blocks';
import { css } from '@emotion/react';
import { Autocomplete } from '@material-ui/lab';
import { Checkbox, Grid, IconButton, TextField } from '@material-ui/core';
import { useMutation } from '@apollo/client';
import { DELETE_TAG, UPSERT_TAGS } from './graphql';
import { captureException } from '@sentry/react';
import { CLIENT_BY_ID } from '@/graphql/queries/users';
import { TAG_TYPES } from './constants';
import { DeleteForeverOutlined } from '@material-ui/icons';
import Snackbar from '@/components/Snackbar';
import NavResponsiveModal, {
    ModalActions,
    ModalContent,
    ModalHeader,
} from '@/components/Navigation/NavResponsiveModal';
import { Body1 } from '../blocks';
import { PrimaryButton, SecondaryButton } from '@/styles/blocks';

const CustomTags = () => {
    const { tags, user_id } = useClientUser();
    const [notification, setNotification] = useState({});
    const [updates, setUpdates] = useState({});
    const [deleteModal, setDeleteModal] = useState(false);

    const updated = useMemo(() => {
        const savedByKey = Object.fromEntries(tags.map((t) => [t.tag_id, t]));
        return Object.values({
            ...savedByKey,
            ...updates,
        });
    }, [tags, updates]);

    const [upsertTags, { loading: upsertLoading }] = useMutation(UPSERT_TAGS, {
        refetchQueries: [CLIENT_BY_ID],
        awaitRefetchQueries: true,
        onError: (e) => {
            console.error(e);
            captureException(e);
            setNotification({
                severity: 'error',
                message: 'Error updated tags',
            });
        },
    });

    const [deleteTag, { loading: deleteLoading }] = useMutation(DELETE_TAG, {
        onError: (e) => {
            console.error(e);
            captureException(e);
            setNotification({
                severity: 'error',
                message: 'Error deleting tag. Please try again.',
            });
        },
        update: (cache, { data: { delete_tags_by_pk } }) => {
            cache.evict(cache.identify(delete_tags_by_pk));
        },
    });

    const closeDeleteModal = () => {
        setDeleteModal(false);
    };

    const handleModalDelete = () => {
        deleteTag({ variables: { tag_id: deleteModal.tag_id } });
        closeDeleteModal();
    };

    const loading = upsertLoading || deleteLoading;

    return (
        <>
            <NavResponsiveModal open={!!deleteModal} onClose={closeDeleteModal}>
                <ModalHeader title="Delete Tag?" onClose={closeDeleteModal} />
                <ModalContent>
                    <Body1>Are you sure you want to delete this tag?</Body1>
                </ModalContent>
                <ModalActions>
                    <SecondaryButton onClick={closeDeleteModal}>Cancel</SecondaryButton>
                    <PrimaryButton onClick={handleModalDelete}>Delete</PrimaryButton>
                </ModalActions>
            </NavResponsiveModal>

            <TabCard
                css={css`
                    padding: 2rem;
                `}
            >
                <Snackbar open={!!notification.message} handleClose={() => setNotification({})} {...notification} />
                <Grid
                    direction="column"
                    css={css`
                        flex-wrap: nowrap;
                    `}
                >
                    <Grid
                        container
                        css={css`
                            flex-wrap: nowrap;
                            align-items: center;
                        `}
                    >
                        <PrimaryButton
                            disabled={loading}
                            onClick={() =>
                                upsertTags({
                                    variables: {
                                        tags: [
                                            {
                                                client_id: user_id,
                                                tag: `New Tag ${tags.length}`,
                                                route_tag: false,
                                                accessorial_tag: false,
                                            },
                                        ],
                                    },
                                })
                            }
                            css={css`
                                margin-right: 1rem;
                            `}
                        >
                            Add New Tag
                        </PrimaryButton>
                        <PrimaryButton
                            disabled={loading}
                            onClick={() =>
                                upsertTags({
                                    variables: {
                                        tags: updated.map((tag) => {
                                            const { __typename, client, ...rest } = tag;
                                            return rest;
                                        }),
                                    },
                                })
                            }
                        >
                            Save Tags
                        </PrimaryButton>
                    </Grid>
                    {updated.map((tag, idx) => (
                        <Grid
                            container
                            css={css`
                                flex-wrap: nowrap;
                                align-items: center;
                                margin-top: 2rem;
                                margin-top: 2rem;
                            `}
                            key={tag.tag_id}
                        >
                            <Grid
                                item
                                css={css`
                                    width: 300px;
                                    margin-right: 1rem;
                                `}
                            >
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    label="Tag"
                                    value={tag.tag || ''}
                                    onChange={(e) =>
                                        setUpdates((prev) => ({
                                            ...prev,
                                            [tag.tag_id]: { ...tag, tag: e.target.value },
                                        }))
                                    }
                                />
                            </Grid>
                            <Grid
                                item
                                css={css`
                                    width: 300px;
                                    margin-right: 1rem;
                                `}
                            >
                                <Autocomplete
                                    multiple
                                    disableCloseOnSelect
                                    value={Object.keys(TAG_TYPES)
                                        .filter((type) => tag[type])
                                        .map((type) => ({ value: type, label: TAG_TYPES[type] }))}
                                    onChange={(e, options) => {
                                        const selected = Object.fromEntries(
                                            options.map((option) => [option.value, true])
                                        );
                                        setUpdates((prev) => ({
                                            ...prev,
                                            [tag.tag_id]: {
                                                ...tag,
                                                ...Object.fromEntries(
                                                    Object.keys(TAG_TYPES).map((type) => [type, selected[type]])
                                                ),
                                            },
                                        }));
                                    }}
                                    options={Object.entries(TAG_TYPES).map(([value, label]) => ({
                                        value,
                                        label,
                                    }))}
                                    getOptionSelected={(option, value) => {
                                        return option.value === value.value;
                                    }}
                                    getOptionLabel={(option) => option.label}
                                    renderTags={(options) => {
                                        return <span>{options.length} Type(s) selected</span>;
                                    }}
                                    renderOption={(option, { selected }) => {
                                        return (
                                            <li key={option.value}>
                                                <Checkbox color="primary" checked={selected} />
                                                {option.label}
                                            </li>
                                        );
                                    }}
                                    renderInput={(params) => <TextField variant="outlined" {...params} label="Types" />}
                                />
                            </Grid>
                            <Grid item>
                                <IconButton disabled={loading} onClick={() => setDeleteModal(tag)}>
                                    <DeleteForeverOutlined fontSize="large" color={loading ? 'disabled' : 'error'} />
                                </IconButton>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            </TabCard>
        </>
    );
};

export default CustomTags;
